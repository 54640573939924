import React, { useState, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MenuPrimary as Menu } from '@singita/components';
import MenuLodge from '../components/Menus/MenuLodge';
import MenuLayout from '../components/Menus/MenuLayout';
import { formatLodgeMenus } from '../components/Menus/format';
import { MENU_VARIANTS } from '../components/Menus/constants';
import { useLanguage } from '../context/LanguageContext';

const MenuWrapper = ({ isOpen, setIsOpen, currentPath, menuBg, menuType }) => {
  let lang = useLanguage() ?? 'en-US';

  const [menu, setMenu] = useState(MENU_VARIANTS.MAIN);

  const [bg, setBg] = useState('brandBrown');

  const data = useStaticQuery(MENU_QUERY);

  const mainMenu = useMemo(() => {
    let menu = {
      contact: data.menu.edges.find(
        ({ node }) =>
          node.location.includes('contact') && node.node_locale === lang,
      ),
      feature: data.menu.edges.find(
        ({ node }) =>
          node.location.includes('features') && node.node_locale === lang,
      ),
      secondary: data.menu.edges.find(
        ({ node }) =>
          node.location.includes('secondary') && node.node_locale === lang,
      ),
      lodges: formatLodgeMenus(data.menuLodges.menuItems),
    };

    return menu;
  }, [data]);

  const childMenu = useMemo(() => {
    switch (menu) {
      case MENU_VARIANTS.LODGE:
        return (
          <MenuLodge
            setBg={setBg}
            lodges={mainMenu.lodges}
            setMenu={setMenu}
            setIsOpen={setIsOpen}
            currentPath={currentPath}
          />
        );

      default:
        return (
          <MenuLayout
            menuType={menuType}
            setMenu={setMenu}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            currentPath={currentPath}
            menus={mainMenu}
          />
        );
    }
  }, [mainMenu, isOpen, menu, currentPath, setIsOpen]);

  return <Menu bg={menuBg ? menuBg : bg}>{childMenu}</Menu>;
};

export default MenuWrapper;

const MENU_QUERY = graphql`
  query {
    menuLodges: contentfulMenu(headline: { eq: "Our Collection" }) {
      menuItems {
        ... on Node {
          ... on ContentfulLodge {
            id
            name
            slug
            region {
              name
              country
            }
            color
          }
        }
      }
    }
    menu: allContentfulMenu(
      filter: { location: { in: ["contact", "features", "secondary"] } }
    ) {
      edges {
        node {
          node_locale
          headline
          location
          menuItems {
            __typename
            ... on Node {
              ... on ContentfulPages {
                headline: name
                slug
              }
              ... on ContentfulMenu {
                headline
                links: menuItems {
                  ... on ContentfulPages {
                    headline: name
                    slug
                  }
                  ... on ContentfulLodge {
                    headline: fullTitle
                    slug
                  }
                }
              }
              ...LinkFields
            }
          }
        }
      }
    }
  }
`;
