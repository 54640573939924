import React, { useCallback, useContext, useReducer, useRef } from 'react';
import { Box, Flex } from '@singita/components';
import styled from '@emotion/styled';
import { availabilityReducer, initialValue } from '../common/reducer';
import {
  ActionBar,
  Text,
  Button,
  DatePicker,
  Popover,
  theme,
} from '@singita/components';
import { client } from '../../../apollo/availability-client';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { ACTIONS, STEPS } from '../common/constants';
import { QUERY_ALL_REGIONS_AND_LODGES } from '../graphql/queries';
import { useQuery } from '@apollo/client';
import { getStepComponent } from '../utils/helpers';
import { graphql } from 'gatsby';
import useActionBar from '../../../hooks/useActionBar';
import AvailabilityContext from '../../../context/AvailabilityContext';

const FieldContainer = styled(Box)`
  position: relative;
  border: 1px solid;
  border-color: ${theme.colors.transparent} ${theme.colors.transparent}
    ${theme.colors.transparent} ${theme.colors.borderDark};
  height: 100%;
  min-width: 250px;
  max-width: 325px;

  ${({ focused }) =>
    focused
      ? `border-color: ${theme.colors.borderDark}; border-radius: 4px;`
      : ''}

  ${({ error }) =>
    error
      ? `border-color: ${theme.colors.statusError}; border-radius: 4px;`
      : ''}
`;

const PopoverContainer = styled(Box)`
  left: 0;
  width: 100%;
  bottom: calc(100% + 8px);
  padding-top: 5px;
  position: absolute;
`;

const FieldBox = ({
  isOpen,
  label,
  onClick,
  renderPopover,
  closePopover,
  children,
  error,
}) => {
  const ref = useRef();
  useOutsideClick(ref, isOpen, () => closePopover());

  return (
    <FieldContainer focused={isOpen} error={error} mr={[1]}>
      <Flex
        px={[2]}
        pt={[1]}
        onClick={onClick}
        flexDirection="column"
        height="100%"
        sx={{ cursor: renderPopover ? 'pointer' : 'default' }}
      >
        <Text
          size="extraSmall"
          fontWeight="bold"
          sx={{ textTransform: 'uppercase', mb: '4px' }}
        >
          {label}
        </Text>
        {children}
      </Flex>
      {renderPopover && isOpen && (
        <PopoverContainer>
          <Popover ref={ref} sx={{ p: [2] }}>
            {renderPopover}
          </Popover>
        </PopoverContainer>
      )}
    </FieldContainer>
  );
};

function getPropsForStep(step, currentStep, form, regions, summary, dispatch) {
  switch (step) {
    case STEPS.REGIONS:
      return {
        children: (
          <Text size="tiny" color="textLight">
            {summary.regions.length > 0
              ? summary.regions.join(', ')
              : 'All Regions'}
          </Text>
        ),
        label: 'Regions',
        renderPopover: getStepComponent(step, regions, form, summary, dispatch),
      };
    case STEPS.LODGES:
      return {
        children: (
          <Text size="tiny" color="textLight">
            {summary.lodges.length > 0
              ? summary.lodges.join(', ')
              : 'All Lodges'}
          </Text>
        ),
        label: 'Lodges',
        renderPopover: getStepComponent(step, regions, form, summary, dispatch),
      };
    case STEPS.DATES:
      return {
        children: (
          <DatePicker
            startDate={form.startDate}
            endDate={form.endDate}
            setDates={(dates) =>
              dispatch({ type: ACTIONS.UPDATE_DATES, payload: { dates } })
            }
            openDirection="up"
            variant="minimal"
            isOpen={step === currentStep}
          />
        ),
        label: 'Dates',
      };
    case STEPS.GUESTS:
      return {
        children: (
          <Text size="tiny" color="textLight">
            {form.guests.adults} Guests
          </Text>
        ),
        label: 'Guests',
        renderPopover: getStepComponent(step, regions, form, summary, dispatch),
      };
    // no default
  }
}

const AvailabilityActionBar = ({ headline, description }) => {
  const [state, dispatch] = useReducer(availabilityReducer, {
    ...initialValue,
    currentStep: null,
  });
  const { form, summary, currentStep, errors } = state;

  const { data } = useQuery(QUERY_ALL_REGIONS_AND_LODGES, {
    client,
  });

  const closePopover = useCallback(
    () => dispatch({ type: ACTIONS.STEP, payload: { step: null } }),
    [dispatch],
  );
  const setStep = useCallback(
    (step) => {
      if (currentStep && step === currentStep) {
        dispatch({ type: ACTIONS.STEP, payload: { step: null } });
      } else {
        dispatch({ type: ACTIONS.STEP, payload: { step } });
      }
    },
    [currentStep, dispatch],
  );
  const [ref, isMobile] = useActionBar('xl');
  const { setShowAvailability } = useContext(AvailabilityContext);

  const steps = Object.values(STEPS);
  const lastStep = steps.indexOf(STEPS.GUESTS) + 1;

  return (
    <ActionBar
      ref={ref}
      headline={isMobile ? headline || 'Plan your trip' : null}
      description={isMobile && description ? description.description : null}
      isSticky={true}
    >
      {isMobile ? (
        <Button
          variant="primary"
          colorScheme="brandBrown"
          onClick={() => setShowAvailability(true)}
        >
          Plan your trip
        </Button>
      ) : (
        <Flex width="100%" justifyContent="center" alignItems="center">
          {steps.slice(0, lastStep).map((step) => {
            const stepProps = getPropsForStep(
              step,
              currentStep,
              form,
              data ? data.findRegions : null,
              summary,
              dispatch,
            );

            const hasErrors =
              step === STEPS.DATES && (errors.startDate || errors.endDate);

            return (
              <FieldBox
                isOpen={currentStep === step}
                onClick={() => setStep(step)}
                closePopover={closePopover}
                error={hasErrors}
                {...stepProps}
              />
            );
          })}
          <Box ml={[2]}>
            <Button
              variant="primary"
              colorScheme="brandBrown"
              onClick={() => {
                if (Object.keys(errors).length === 0) {
                  dispatch({
                    type: ACTIONS.STEP,
                    payload: { step: STEPS.CHECK_AVAILABILITY },
                  });
                }
              }}
            >
              Check availability
            </Button>
          </Box>
        </Flex>
      )}
    </ActionBar>
  );
};

export default AvailabilityActionBar;

export const actionbarBookingFields = graphql`
  fragment ActionbarBookingFields on ContentfulActionbarBookingForm {
    contentful_id
    name
    headline
    description {
      description
    }
  }
`;
