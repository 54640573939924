import { ApolloClient, InMemoryCache } from "@apollo/client"
import fetch from "isomorphic-fetch"

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
  },
  query: {
    fetchPolicy: "no-cache",
  },
}

export const client = new ApolloClient({
  uri: process.env.GATSBY_SINGITA_API
    ? `${process.env.GATSBY_SINGITA_API}/graphql`
    : `http://localhost:4000/graphql`,
  fetch,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
})
