import React from 'react';
import { Icon, Box, Flex } from '@singita/components';
import { Media } from '../../Media';
import Link from '../Link';

const MenuInline = ({ menu }) => {
  return menu.node.menuItems.map((link, idx) => (
    <Flex key={`legal-${idx}`} alignItems="center">
      <Box mb={[1, 1, 1, 1, 0]}>
        <Link
          containerSx={{ display: 'inline-block', py: '12px' }}
          variant={'text'}
          size={'small'}
          colorScheme={'textDark'}
          {...link}
          label={link.headline ? link.headline : link.label}
        />
      </Box>
      <Media greaterThanOrEqual="xl">
        {idx !== menu.node.menuItems.length - 1 && (
          <Box mx={[2]}>
            <Icon name="dot" size={6} color={'textDark'} />
          </Box>
        )}
      </Media>
    </Flex>
  ));
};

export default MenuInline;
