import React from "react"

export const Winnie = ({ onChange }) => {
  return (
    <input
      data-testid="a_password"
      name="a_password"
      type="text"
      onChange={onChange}
      tabIndex="-1"
      autoComplete="off"
      style={{ display: "none" }}
    />
  )
}
