import useMobile from "gatsby-singita-theme/src/hooks/useMobile"
import { useEffect, useState } from "react"
import { ALERT_TYPES } from "../common/constants"

const GlobalAlert = ({ offset, children, type }) => {
  const [shouldShow, setShouldShow] = useState(offset ? false : true)
  const isMobile = useMobile("md")

  useEffect(() => {
    if (isMobile && type !== ALERT_TYPES.BANNER) {
      if (!shouldShow) {
        setShouldShow(true)
      }
    } else {
      if (offset && typeof window !== "undefined") {
        const checkScrollProgress = () => {
          const scrollProgress = window.pageYOffset
          const pageHeight =
            document.documentElement.offsetHeight - window.innerHeight
          const percentage = (scrollProgress / pageHeight) * 100
          if (percentage > offset) {
            setShouldShow(true)
          }
        }
        window.addEventListener("scroll", checkScrollProgress)
        return () => window.removeEventListener("scroll", checkScrollProgress)
      }
    }
  }, [offset, isMobile])

  return shouldShow ? children : null
}

export default GlobalAlert
