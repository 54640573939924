import React, { useContext, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { AlertsContext } from "gatsby-singita-theme/src/context/AlertsContext"
import useMobile from "gatsby-singita-theme/src/hooks/useMobile"
import { useInView } from "react-intersection-observer"
import { ALERT_TYPES } from "../common/constants"

const AlertWrapper = props => {
  const { type } = props
  const { ref, inView } = useInView({ threshold: 0 })
  const { addAlert } = useContext(AlertsContext)
  const isMobile = useMobile("md")
  const hasAlertBeenAdded = useRef(false)

  const localAddAlert = () => {
    addAlert(props)
    hasAlertBeenAdded.current = true
  }

  useEffect(() => {
    if (!hasAlertBeenAdded.current) {
      if (isMobile && type !== ALERT_TYPES.BANNER) {
        localAddAlert()
      } else if (inView) {
        localAddAlert()
      }
    }
  }, [inView, addAlert, isMobile])

  return <div ref={ref} />
}

export default React.memo(AlertWrapper)

export const alertFields = graphql`
  fragment AlertFields on ContentfulAlert {
    id
    name
    headline
    subtitle
    image {
      fluid(quality: 80, maxWidth: 300) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    content {
      raw
      references {
        ...RichTextReferences
      }
    }
    isActive
    isDismissible
    isGlobal
    type
    meta {
      offset
    }
  }
`
