import React from 'react';
import { Box, Number, Text } from '@singita/components';
import { ENQUIRY_COPY } from '../common/constants';

const Guests = ({ form, setGuests, colorScheme }) => {
  return (
    <Box>
      <Box sx={{ pb: [1] }}>
        <Number
          key="adults"
          value={form.guests['adults']}
          label="Adults"
          description="Ages 17+"
          onIncrement={() => setGuests(1, 'adults')}
          onDecrement={() => setGuests(-1, 'adults')}
          orientation="right"
          min={1}
          color={colorScheme}
        />
      </Box>

      <Text size="tiny" fontWeight="bold" mb={[2]}>
        Children
      </Text>

      {['children', 'toddler', 'baby'].map((key) => {
        return (
          <Box sx={{ mb: [2] }}>
            <Number
              key={key}
              value={form.guests[key]}
              label={ENQUIRY_COPY.guestLabels[key]}
              description=""
              onIncrement={() => setGuests(1, key)}
              onDecrement={() => setGuests(-1, 'key')}
              orientation="right"
              min={0}
              color={colorScheme}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default Guests;
