import React, { useContext, useEffect } from "react"
import { mapperWithFunction } from "../common/utils"
import GlobalAlert from "../components/GlobalAlert"
import RichTextRenderer from "../components/RichTextRenderer"
import {
  ALERT_STATE_KEY_PREFIX,
  ALERT_TYPES,
  isBrowser,
} from "../common/constants"
import { Alert, Box, motion } from "@singita/components"
import ImageWrapper from "./ImageWrapper"
import { AlertsContext } from "gatsby-singita-theme/src/context/AlertsContext"
import { getFluidGatsbyImage } from "gatsby-singita-theme/src/common/image"
import { useLanguage } from "gatsby-singita-theme/src/context/LanguageContext"

const ALERT_VARIANTS = {
  [ALERT_TYPES.POPUP]: "standard",
  [ALERT_TYPES.BANNER]: "banner",
}

const ALERT_MAP = {
  id: props => {
    if (props.id) return props.id
    else if (props.sys?.id) return props.sys.id
  },
  type: ["type"],
  variant: ({ type }) => ALERT_VARIANTS[type],
  name: ["name"],
  headline: ["headline"],
  subtitle: ["subtitle"],
  renderImage: ({ image, headline }) => {
    if (image) {
      return () => {
        if (image.fluid) {
          return <ImageWrapper image={image} />
        } else if (image.url) {
          return (
            <ImageWrapper
              altText={headline || ""}
              image={{
                fluid: getFluidGatsbyImage(image, {
                  maxWidth: 300,
                  quality: 80,
                }),
              }}
            />
          )
        }
      }
    }
    return null
  },
  children: ({ content, type }) => {
    if (!content) {
      return null
    }
    return (
      <RichTextRenderer
        content={content}
        color={type === ALERT_TYPES.POPUP ? "textDark" : "textWhite"}
      />
    )
  },
  isActive: ["isActive"],
  isDismissible: ["isDismissible"],
  isGlobal: ["isGlobal"],
  offset: ["meta", "offset"],
}

const AnimatedAlert = motion.custom(Box)

const AlertWrapper = props => {
  const { updateKey } = useContext(AlertsContext)

  const alertProps = mapperWithFunction(props, ALERT_MAP)

  const STATE_KEY = ALERT_STATE_KEY_PREFIX.concat(alertProps.id)

  let storage = null
  if (isBrowser) {
    try {
      storage = JSON.parse(window.localStorage.getItem(STATE_KEY))
    } catch (e) {
      console.error(`Failed to get key ${STATE_KEY} from local storage`, e)
    }
  }

  if (storage === null) {
    storage = alertProps.isActive
  }

  const [isVisible, setIsVisible] = React.useState(storage)

  useEffect(() => {
    try {
      window.localStorage.setItem(STATE_KEY, isVisible)
    } catch (e) {
      console.error(`Failed to set storage for key ${STATE_KEY}`, e)
    }
    updateKey()
  }, [isVisible])

  const AlertComponent = alertProps.isGlobal ? GlobalAlert : React.Fragment
  const isStandard = alertProps.type === ALERT_TYPES.POPUP

  // If the page is translated, do not display the Alert
  const lang = useLanguage()
  if (lang !== "en-US") return null

  return (
    <AlertComponent type={alertProps.type} offset={alertProps.offset}>
      {isVisible ? (
        <AnimatedAlert
          key={props.id}
          mb={[isStandard ? 2 : 0]}
          layout
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <Alert {...alertProps} onDismiss={() => setIsVisible(!isVisible)} />
        </AnimatedAlert>
      ) : null}
    </AlertComponent>
  )
}

export default React.memo(AlertWrapper)
