/* Used for general rendering of children in a modal */
import React, { createContext, useCallback, useState } from 'react';

const ModalContext = createContext({
  isOpen: false,
  children: null,
});

const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    modalChildren: null,
    title: null,
  });
  const { isOpen, modalChildren, title } = modalState;

  const toggleModal = useCallback(
    (children, title) => {
      setModalState((state) => ({
        isOpen: !state.isOpen,
        modalChildren: children,
        title,
      }));
    },
    [setModalState],
  );

  return (
    <ModalContext.Provider
      value={{ isOpen, modalChildren, toggleModal, title }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
