import React from "react"
import { File } from "@singita/components"

export const Upload = ({ label, name, setForm, form, error, disabled }) => {
  return (
    <File
      name={name}
      label={label}
      buttonLabel="Choose file"
      error={error}
      bg="bgLight"
      value={form[name]}
      onChange={file => {
        setForm({ ...form, [name]: file })
      }}
      disabled={disabled}
    />
  )
}
