import React from "react"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import GatsbyBackgroundImage from "gatsby-background-image"
import { RebassImage, Box, Flex, Text } from "@singita/components"
import { mapperWithFunction, processFluid } from "../common/utils"

const IMAGE_MAP = {
  Component: value =>
    value.focalPoint && value.focalPoint.focalPoint
      ? GatsbyBackgroundImage
      : GatsbyImage,
  style: value => {
    const { focalPoint: fp } = value
    const { focalPoint } = fp || {}
    const baseStyle = { height: "100%", width: "100%", ...(value.style || {}) }
    if (focalPoint) {
      return {
        ...baseStyle,
        ...(focalPoint.xp && focalPoint.yp
          ? {
              backgroundPosition: `${focalPoint.xp}% ${focalPoint.yp}%`,
            }
          : {}),
        position: "absolute",
      }
    }
    return baseStyle
  },
  alt: ({ altText, caption }) => {
    return altText || caption || ""
  },
  fluid: value => {
    const fluid = value.image ? value.image.fluid : value.fluid
    const processedFluid = processFluid(fluid)
    return processedFluid
    // return value.image ? value.image.fluid : value.fluid
  },
  imgStyle: value => {
    const baseStyle = {
      objectFit: "cover",
      ...value.imgStyle,
    }
    return baseStyle
  },
  containerSx: value => {
    return {
      ...value.containerSx,
      ...(value.focalPoint && value.focalPoint.focalPoint
        ? {
            paddingBottom: `${100 / value.image.fluid.aspectRatio}%`,
            position: "relative",
          }
        : {}),
    }
  },
}

const ImageWithCaption = ({ children, style, caption, onClick }) => {
  return (
    <Flex
      height="100%"
      width="100%"
      flexDirection="column"
      sx={style}
      onClick={onClick}
    >
      {children}
      {caption && (
        <Box mt={[1]} textAlign="center">
          <Text size="tiny">{caption}</Text>
        </Box>
      )}
    </Flex>
  )
}

const ImageWrapper = props => {
  const { url, style, imgStyle, caption, image, onClick, ...rest } = props
  const imageUrl = url || image?.url
  if (imageUrl) {
    return (
      <ImageWithCaption
        style={{ ...style, ...props.containerSx }}
        caption={caption}
        onClick={onClick}
      >
        <RebassImage
          src={imageUrl}
          sx={{ ...imgStyle, height: "100%", width: "100%" }}
        />
      </ImageWithCaption>
    )
  }
  const { Component, containerSx, ...imageProps } = mapperWithFunction(
    props,
    IMAGE_MAP
  )
  return imageProps.fluid ? (
    <ImageWithCaption style={containerSx} caption={caption} onClick={onClick}>
      <Component {...imageProps} {...imageProps.imgStyle} {...rest} />
    </ImageWithCaption>
  ) : null
}

export default ImageWrapper

export const ImageFields = graphql`
  fragment ImageFields on ContentfulImage {
    id
    contentful_id
    name
    caption
    altText
    focalPoint {
      focalPoint {
        x
        y
        xp
        yp
      }
    }
  }
`

export const ArticleImageFields = graphql`
  fragment ArticleImageFields on ContentfulImage {
    id
    contentful_id
    name
    caption
    altText
    image {
      fluid(quality: 95, maxWidth: 1000) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`

export const GalleryLayoutFields = graphql`
  fragment GalleryLayoutFields on ContentfulImage {
    id
    contentful_id
    name
    caption
    altText
    image {
      fluid(quality: 95, maxWidth: 1200) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`
