import React from "react"
import { Loader } from "@singita/components"
import { Translation } from "react-i18next"

const FormLoading = () => {
  return (
    <Loader isActive color="brandBrown" size={300}>
      <Translation>{t => t("form.loading")}</Translation>
    </Loader>
  )
}

export default FormLoading
