export const getIpAddress = async () => {
  return fetch("https://api.ipify.org?format=jsonp?callback=?", {
    method: "GET",
    headers: {},
  })
    .then(res => {
      return res.text()
    })
    .then(ip => {
      return ip
    })
    .catch(err => {
      console.log("fetch ip address error: ", err)
    })
}
