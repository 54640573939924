import React, { useCallback, useState } from "react"
import { graphql } from "gatsby"
import { Box } from "@singita/components"
import {
  GridContainer,
  Section,
  Container,
  Card,
  Gallery,
} from "@singita/components"
import FilterWrapper, { FILTER_TYPES } from "../modules/FilterWrapper"
import { Link } from "gatsby"
import GridItem from "../components/GridItem"
import { Media } from "gatsby-singita-theme/src/Media"
import ImageWrapper from "./ImageWrapper"

const cardDescriptionMap = {
  [FILTER_TYPES.COUNTRY]: project =>
    project.regions
      ? `${project.regions[0].name}, ${project.regions[0].country}`
      : null,
  [FILTER_TYPES.AREA]: project => project.area.name,
}

const ProjectsGridWrapper = ({ modules, headline, description, filter }) => {
  const allProjects = {}
  modules.forEach(({ projects }) => {
    if (projects) {
      projects.forEach(project => {
        allProjects[project.id] = project
      })
    }
  })
  const [filteredProjects, setFilteredProjects] = useState(
    Object.values(allProjects)
  )

  const generateCardDescription = cardDescriptionMap[filter]

  const onSetFilter = useCallback(payload => setFilteredProjects(payload), [
    setFilteredProjects,
  ])
  return (
    <Container>
      <Section
        headline={headline}
        description={description && description.description}
      >
        {filter && (
          <Box width={["100%", "100%", "100%", "auto"]} mb={[5]}>
            <FilterWrapper
              items={Object.values(allProjects)}
              filter={filter}
              onSetFilter={onSetFilter}
              isVertical={false}
            />
          </Box>
        )}
        <Media greaterThanOrEqual="md">
          <GridContainer
            sx={{
              gridTemplateColumns: [
                "1fr",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
                "repeat(4, 1fr)",
              ],
              gridGap: [4],
            }}
          >
            {filteredProjects.map((project, index) => (
              <GridItem index={index} columns={[1, 2, 3, 4]}>
                <Link to={`/conservation/projects/${project.slug}/`}>
                  <Card
                    variant="small"
                    isLink={true}
                    headline={project.name}
                    description={generateCardDescription(project)}
                    image={<ImageWrapper image={project?.visual?.image} />}
                  />
                </Link>
              </GridItem>
            ))}
          </GridContainer>
        </Media>
        <Media lessThan="md">
          <Gallery variant="carousel" overflow="visible">
            {filteredProjects.map(project => (
              <Link to={`/conservation/projects/${project.slug}/`}>
                <Card
                  variant="small"
                  isLink={true}
                  headline={project.name}
                  description={generateCardDescription(project)}
                  image={<ImageWrapper image={project?.visual?.image} />}
                />
              </Link>
            ))}
          </Gallery>
        </Media>
      </Section>
    </Container>
  )
}

export default ProjectsGridWrapper

export const ProjectFields = graphql`
  fragment ProjectFields on ContentfulProjects {
    id
    name
    slug
    visual {
      __typename
      ... on ContentfulImage {
        image {
          fluid(quality: 90, maxWidth: 400, maxHeight: 260) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    regions {
      name
      country
    }
    area {
      name
    }
  }
`

export const ProjectsGridFields = graphql`
  fragment ProjectsGridFields on ContentfulLayoutProjectsGrid {
    name
    headline
    description {
      description
    }
    modules {
      ... on ContentfulConservationArea {
        id
        name
        projects {
          ...ProjectFields
        }
      }
      ... on ContentfulRegion {
        id
        name
        projects {
          ...ProjectFields
        }
      }
    }
    filter
  }
`
