import { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { ResourceSetContext } from '../context/ResourceSetContext';
import { mapperWithFunction } from '../common/utils';

const STRUCTURED_DATA_MAP = {
  title: ({ menuLabel, headline }) => {
    return headline || menuLabel;
  },
  menuLabel: ({ menuLabel, headline }) => {
    return menuLabel || headline;
  },
  description: ['description', 'description'],
  image: ['image', 'fluid', 'src'],
  altText: ['altText'],
  meta: ({ meta }) => {
    return meta ? JSON.parse(meta.internal.content) : {};
  },
  noIndex: ['noIndex'],
  noFollow: ['noFollow'],
};

const useSeo = (data, FALLBACK_DATA_MAP) => {
  const context = useContext(ResourceSetContext);
  const globalRS = context.getResourcesForComponent('global');
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  let structuredData = {};
  const fallbackData = mapperWithFunction(data, FALLBACK_DATA_MAP);

  if (data?.structuredData) {
    structuredData = mapperWithFunction(
      data.structuredData,
      STRUCTURED_DATA_MAP,
    );
  }

  const image = structuredData.image || fallbackData.image;
  let url = `${site.siteMetadata.siteUrl}${pathname}` || globalRS.websiteURL;
  if (url && !url.endsWith('/')) url += '/';

  let title =
    structuredData.title || fallbackData.title || fallbackData.headline;
  if (title && !title.endsWith('Singita'))
    title += ` | ${globalRS.websiteName}`;

  return {
    ...fallbackData,
    ...structuredData,
    url: url,
    origin: site.siteMetadata.siteUrl || globalRS.websiteURL,
    sdTitle: structuredData.title,
    title: title,
    menuLabel:
      structuredData.menuLabel || fallbackData.title || fallbackData.headline,
    description:
      structuredData.description ||
      fallbackData.description,
    image: image ? `${image.replace('./img', '/img')}` : globalRS.image,
    altText: structuredData.altText || fallbackData.altText || globalRS.altText,
    breadcrumb: fallbackData.breadcrumb || pathname.split('/')[1],
  };
};

export default useSeo;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
