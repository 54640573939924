import React from 'react';
import { Box, Flex } from '@singita/components';
import MenuPrimary from './MenuPrimary';
import MenuSecondary from './MenuSecondary';

const MenuLayout = ({
  setMenu,
  isOpen,
  setIsOpen,
  currentPath,
  menus,
  renderAfterPrimary,
  renderAfterSecondary,
  menuType,
  styles = {},
}) => {
  return (
    <Flex
      id="mainMenu"
      flexDirection={['column', 'column', 'column', 'row']}
      justifyContent={['flex-start', 'space-between']}
      sx={{ height: ['auto', '100%'] }}
    >
      <Box mb={[4, 0]}>
        <MenuPrimary
          menuType={menuType}
          menu={menus.main}
          setMenu={setMenu}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          currentPath={currentPath}
          renderAfterPrimary={renderAfterPrimary}
          {...(styles.primary || {})}
        />
      </Box>
      <Box width={[1, 1, 1, 5 / 12]}>
        <MenuSecondary
          menus={menus}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          currentPath={currentPath}
          renderAfterSecondary={renderAfterSecondary}
          {...(styles.secondary || {})}
        />
      </Box>
    </Flex>
  );
};

export default MenuLayout;
