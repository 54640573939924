import React from "react"
import { graphql } from "gatsby"
import { Box } from "@singita/components"
import { Button } from "@singita/components"
import ImageWrapper from "./ImageWrapper"

const TYPES = {
  PDF: "pdf",
  IMAGE: "image",
}

const COMPONENT_MAP = {
  [TYPES.PDF]: Button,
  [TYPES.IMAGE]: ImageWrapper,
}

function getProps(props, type) {
  switch (type) {
    case TYPES.PDF:
      return {
        icon: "pdf",
        iconPosition: "left",
        children: props.title,
        as: "a",
        href: props.file.url,
        target: "_blank",
        variant: "text",
      }
    case TYPES.IMAGE:
      return {
        image: { fluid: props.fluid },
      }
    // no default
  }
}

const AssetWrapper = props => {
  const { containerSx = {} } = props
  let type
  if (props.file.contentType.includes("image")) {
    type = TYPES.IMAGE
  } else if (props.file.contentType.includes("pdf")) {
    type = TYPES.PDF
  } else {
    return null
  }
  const Component = COMPONENT_MAP[type]
  const componentProps = getProps(props, type)

  return (
    <Box sx={{ my: [2], ...containerSx }}>
      <Component {...componentProps} />
    </Box>
  )
}

export default AssetWrapper

export const assetFields = graphql`
  fragment AssetFields on ContentfulAsset {
    contentful_id
    title
    fluid(maxWidth: 1000, quality: 95) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }
    file {
      url
      contentType
    }
  }
`
