import React, { useState, useMemo } from "react"
import { graphql } from "gatsby"
import SectionGrid from "../components/SectionGrid"

const SectionGridWrapper = ({
  headline,
  description,
  modules,
  filter,
  styles = {},
  slug,
  isSearchable,
}) => {
  /* If the grid's modules have connected entries with slugs, filter out those that
     are the same as the current page. Use these items in the filteredItems state,
     and as the items passed to the FilterWrapper.
  */
  let items = useMemo(() => {
    return modules
      ? modules.filter(module => {
          if (!module.connectedEntry) {
            return true
          } else {
            if (module.connectedEntry.slug) {
              return module.connectedEntry.slug !== slug
            } else {
              return true
            }
          }
        })
      : []
  }, [modules, slug])
  const [filteredItems, setFilteredItems] = useState(items)

  return (
    <SectionGrid
      headline={headline}
      description={description ? description.description : null}
      direction={styles ? styles.direction || "column" : "column"}
      filter={filter}
      isSearchable={isSearchable}
      allItems={items}
      filteredItems={filteredItems}
      setFilteredItems={setFilteredItems}
      styles={styles}
    />
  )
}

export default SectionGridWrapper

export const SectionGridFields = graphql`
  fragment SectionGridFields on ContentfulLayoutSectionGrid {
    headline
    contentful_id
    description {
      description
    }
    modules {
      __typename
      ... on Node {
        ...LodgeCardFields
        ...ConservationAreaFields
        ...PromotionFields
        ...GalleryCardFields
        ...CardTextFields
        ... on ContentfulPerson {
          name
          role
          # role should be a fallback for personRole
          personRole {
            name
          }
          category
          # category should be a fallback for personCategory
          personCategory {
            name
          }
          description {
            raw
          }
          image {
            fluid(quality: 90, maxWidth: 300, maxHeight: 375) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    filter
    styles {
      columns
      gridTemplateColumns
      direction
    }
    isSearchable
  }
`
