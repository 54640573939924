import { useStaticQuery, graphql } from "gatsby"

export const useNewsletterQuery = lang => {
  const {
    allContentfulForm: { edges },
  } = useStaticQuery(ALL_NEWSLETTER_FORMS)

  return edges.find(edge => edge.node.node_locale === lang)
}

const ALL_NEWSLETTER_FORMS = graphql`
  query {
    allContentfulForm(filter: { name: { eq: "Form > Newsletter" } }) {
      edges {
        node {
          ...FormFields
        }
      }
    }
  }
`
