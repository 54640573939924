import React from 'react';
import { Box } from '@singita/components';
import { motion } from 'framer-motion';
import { cloneDeep, set } from 'lodash';

const AnimatedBox = motion.custom(Box);

const VARIANTS = {
  staggerItem: {
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 50,
        damping: 15,
      },
    },
    initial: {
      opacity: 0,
      x: -100,
    },
  },

  staggerContainer: {
    animate: {
      transition: { staggerChildren: 0.2, delayChildren: 0.3 },
    },
  },

  fadeIn: {
    animate: {
      opacity: 1,
      transition: {
        delay: 0,
      },
    },
    initial: {
      opacity: 0,
    },
  },
};

function configureVariant(name, path, value) {
  const variants = cloneDeep(VARIANTS[name]);
  set(variants, path, value);
  return variants;
}

export const FadeIn = ({ children, shouldAnimate = true, delay, ...rest }) => {
  return (
    <AnimatedBox
      variants={
        delay >= 0
          ? configureVariant(
              'fadeIn',
              ['animate', 'transition', 'delay'],
              delay,
            )
          : VARIANTS.fadeIn
      }
      animate={shouldAnimate ? 'animate' : 'initial'}
      initial="initial"
      {...rest}
    >
      {children}
    </AnimatedBox>
  );
};

export const StaggerContainer = ({
  children,
  shouldAnimate = true,
  delay,
  ...rest
}) => {
  return (
    <AnimatedBox
      variants={
        delay >= 0
          ? configureVariant(
              'staggerContainer',
              ['animate', 'transition', 'delayChildren'],
              delay,
            )
          : VARIANTS.staggerContainer
      }
      animate={shouldAnimate ? 'animate' : 'initial'}
      initial="initial"
      {...rest}
    >
      {children}
    </AnimatedBox>
  );
};

export const StaggerItem = ({ children, shouldAnimate = true, ...rest }) => {
  return (
    <AnimatedBox variants={VARIANTS.staggerItem} {...rest}>
      {children}
    </AnimatedBox>
  );
};
