import React from 'react';
import { Box, Flex } from '@singita/components';
import { Icon, theme } from '@singita/components';
import { CONSTANTS } from '../../common/constants';

const MenuSocial = ({
  resources,
  linkPrefix = 'Footer',
  color = theme.colors.baseGrayDark,
  opacity = theme.opacity.medium,
}) => {
  const linkProps = {
    as: 'a',
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  const socialLinks = resources || CONSTANTS.social;

  return (
    <Flex>
      {Object.keys(socialLinks)
        .filter((key) => socialLinks[key].shouldDisplay)
        .map((key, i) => (
          <Box
            key={key}
            data-testid={`MenuSocial_Box_${linkPrefix}_${key}`}
            ml={i === 0 ? [0] : [3, 3, 3, 2]}
            {...linkProps}
            href={socialLinks[key].url}
            sx={{ flex: '1 0 auto' }}
          >
            <Icon
              sx={{
                color: color,
                opacity: opacity,
              }}
              size={18}
              name={theme.icons[key]}
            />
          </Box>
        ))}
    </Flex>
  );
};

export default MenuSocial;
