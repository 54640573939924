import React from "react"
import { Box } from "@singita/components"

import { Heading, Text, Button, Border } from "@singita/components"
import { CTA_COPY, CTA_SECTIONS } from "../common/constants"

const CTA = ({ onEnquire, onCheckAvailability }) => {
  const callbacks = {
    [CTA_SECTIONS.AVAILABILITY]: onCheckAvailability,
    [CTA_SECTIONS.ENQUIRY]: onEnquire,
  }

  const sections = Object.values(CTA_SECTIONS)
  return (
    <Box>
      {sections.map((key, idx) => {
        const { heading, subtitle, button } = CTA_COPY[key]
        return (
          <Box key={key}>
            <Box mb={[1]}>
              <Heading size="h3" fontWeight="normal">
                {heading}
              </Heading>
            </Box>
            <Box mb={[3]}>
              <Text size="tiny" color="textLight">
                {subtitle}
              </Text>
            </Box>
            <Button
              variant="primary"
              icon="chevron-right"
              colorScheme="brandBrown"
              onClick={() => callbacks[key]()}
            >
              {button}
            </Button>
            {idx !== sections.length - 1 && (
              <Border my={[4]} isContainerised={false} />
            )}
          </Box>
        )
      })}
    </Box>
  )
}

export default CTA
