import React, { useMemo } from "react"
import { Gallery, Container, theme } from "@singita/components"
import { Box } from "@singita/components"
import Link from "../components/Link"
import { mapperWithFunction } from "../common/utils"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { CONTENT_TYPES } from "../common/constants"
import { useSetNavbarStylesOnPresence } from "gatsby-singita-theme/src/hooks"
import { formatImages } from "../common/format"
import FullPageGallery from "../components/FullPageGallery"
import RichTextRenderer from "../components/RichTextRenderer"
import { NavbarContext } from "gatsby-singita-theme/src/context/NavbarContext"

const GALLERY_TYPES = {
  CAROUSEL: "Carousel",
  TRIPTIC: "Triptic",
  BANNER: "Banner",
  FULL: "Full Page",
}

const GALLERY_VARIANTS = {
  [GALLERY_TYPES.CAROUSEL]: "carousel",
  [GALLERY_TYPES.TRIPTIC]: "triptic",
  [GALLERY_TYPES.BANNER]: "banner",
}

const MODULE_MAP = {
  [CONTENT_TYPES.IMAGE]: {
    style: () => ({
      width: "100%",
      height: "100%",
      userSelect: "none",
    }),
    imgStyle: () => ({
      objectFit: "cover",
    }),
    caption: () => null,
  },
  [CONTENT_TYPES.VIDEO]: {
    minHeight: () => "0px",
  },
  [CONTENT_TYPES.CARD_POSTER]: {
    visual: ({ visual: { caption, ...rest } }) => ({
      ...rest,
    }),
  },
  [CONTENT_TYPES.BANNER]: {
    sx: () => ({
      height: "100%",
      width: "100%",
      userSelect: "none",
      overflow: "hidden",
      backgroundColor: theme.colors.transparent,
    }),
    changeInView: () => false,
  },
  [CONTENT_TYPES.CONTENT_GALLERY]: {},
}

const GALLERY_MAP = {
  headline: ["headline"],
  variant: value => GALLERY_VARIANTS[value.type],
  items: value =>
    value.modules
      ? value.modules.map(item => {
          switch (item.__typename) {
            case CONTENT_TYPES.CARD_POSTER:
              return item?.visual?.caption
                ? {
                    desc: item.visual.caption,
                  }
                : {}
            default:
              return item?.caption
                ? {
                    desc: item.caption,
                  }
                : {}
          }
        })
      : [],
  renderCta: value => () => {
    return value.link ? <Link {...value.link} size="small" /> : null
  },
  styles: ["styles"],
  description: value => {
    return value.description ? (
      <RichTextRenderer content={value.description} />
    ) : null
  },
  delay: ["config", "speed"],
}

const StandardGallery = props => {
  const { modules } = props
  const { styles, ...galleryProps } = mapperWithFunction(props, GALLERY_MAP)

  const galleryRef = useSetNavbarStylesOnPresence(
    0,
    "0px 0px -100% 0px",
    {
      logoColor: theme.colors.textWhite,
      secondaryButtonColor: theme.colors.textWhite,
    },
    galleryProps.variant === GALLERY_VARIANTS[GALLERY_TYPES.BANNER]
  )

  const children = useMemo(() => {
    return (modules
      ? modules.reduce((result, { caption, ...item }) => {
          if (
            [
              CONTENT_TYPES.CONTENT_ASSETS,
              CONTENT_TYPES.CONTENT_GALLERY,
            ].includes(item.__typename)
          ) {
            const [gallery] = formatImages([item])

            if (!gallery) return result

            gallery.assets.forEach(asset => {
              result.push(asset)
            })
          } else {
            result.push(item)
          }
          return result
        }, [])
      : []
    ).map(item => {
      const layoutProps = mapperWithFunction(
        item,
        MODULE_MAP[item.__typename] || {}
      )

      return <Layout layout={[{ ...item, ...layoutProps }]} />
    })
  }, [modules])

  return children.length > 0 ? (
    <Box pt={[2, 3]} pb={[2, 3]} ref={galleryRef} {...styles}>
      {modules && (
        <Gallery {...galleryProps} hasPagination>
          {children}
        </Gallery>
      )}
    </Box>
  ) : null
}

const GalleryWrapper = props => {
  const { type, modules, headline } = props
  const { navbarHeight, setNavbarStyles } = React.useContext(NavbarContext)

  React.useEffect(() => {
    setNavbarStyles({ bg: theme.colors.bgLighter })
  }, [])

  const formatModules = modules => {
    return modules.map(mod => {
      if (mod.lodge && mod.lodge.length) {
        return {
          ...mod,
          headline: mod.lodge[0].shortTitle,
        }
      }

      return mod
    })
  }

  return type === GALLERY_TYPES.FULL ? (
    <Container>
      <FullPageGallery
        galleries={formatModules(modules, props)}
        title={headline}
        filterOffset={navbarHeight}
      />
    </Container>
  ) : (
    <StandardGallery {...props} />
  )
}

export default GalleryWrapper

export const GalleryFields = graphql`
  fragment GalleryFields on ContentfulLayoutGallery {
    id
    contentful_id
    name
    type
    headline
    description {
      raw
    }
    modules {
      __typename
      ... on Node {
        ...VideoFields
        ...BannerFields
        ...CardPosterFields
        ...ContentContentAssetFields
        ...GalleryLayoutFields
      }
    }
    styles {
      pt
      pb
    }
    config {
      speed
    }
  }
`
