import { set } from 'lodash';

export const formatLodgeMenus = (items) => {
  const lodgesMap = {};

  items.forEach((item) => {
    if (
      !(item.region.country in lodgesMap) ||
      !(item.region.name in lodgesMap[item.region.country])
    ) {
      set(
        lodgesMap,
        [item.region.country, item.region.name],
        [
          {
            label: item.name,
            to: item.slug,
            id: item.id,
            color: item.color,
          },
        ],
      );
    } else {
      lodgesMap[item.region.country][item.region.name].push({
        label: item.name,
        to: item.slug,
        id: item.id,
        color: item.color,
      });
    }
  });

  return lodgesMap;
};
