import React from "react"
import { Box } from "@singita/components"

import {
  Input,
  Heading,
  Text,
  Border,
  Choice,
  Button,
} from "@singita/components"
import { ENQUIRY_COPY } from "../common/constants"

const DATE_FORMAT = "dddd, DD MMMM YYYY"

const EnquiryForm = ({ summary, form }) => {
  const { intro, note, sendButton } = ENQUIRY_COPY
  const { lodges, regions } = summary

  return (
    <Box>
      <Box mb={[3]}>
        <Text size="tiny" color="textLight">
          {intro}
        </Text>
      </Box>
      <Box mb={[2]}>
        <Heading size="h4" fontWeight="normal">
          Summary
        </Heading>
        <Border isContainerised={false} my={[2]} />
        <Box mb={[1]}>
          <Text size="tiny" fontWeight="bold">
            Regions:
          </Text>
          <br />
          <Text size="small" color="textLight">
            {regions.length > 0 ? regions.join(", ") : "All Regions"}
          </Text>
        </Box>
        <Box mb={[1]}>
          <Text size="tiny" fontWeight="bold">
            Lodges:
          </Text>
          <br />
          <Text size="small" color="textLight">
            {lodges.length > 0 ? lodges.join(", ") : "All Lodges"}
          </Text>
        </Box>
        <Box mb={[1]}>
          <Text size="tiny" fontWeight="bold">
            Dates:
          </Text>
          <br />
          <Text size="small" color="textLight">
            {form.startDate.format(DATE_FORMAT)} -{" "}
            {form.endDate.format(DATE_FORMAT)}
          </Text>
        </Box>
        <Box mb={[1]}>
          <Text size="tiny" fontWeight="bold">
            Guests:
          </Text>
          <br />
          <Text size="small" color="textLight">
            {form.guests.adults} Adults
          </Text>
        </Box>
      </Box>
      <Box as="form" mb={[4]}>
        <Input type="input" label="Title" bg="bgLighter" />
        <Input type="input" label="First Name" bg="bgLighter" />
        <Input type="input" label="Last Name" bg="bgLighter" />
        <Input type="input" label="Contact Number" bg="bgLighter" />
        <Input type="input" label="Country" bg="bgLighter" />
        <Input type="input" label="Email" bg="bgLighter" />
        <Input type="input" label="I am a:" bg="bgLighter" />
        <Input
          type="textarea"
          label="Is there anything else you'd like to let us know?"
          bg="bgLighter"
        />
        <Box mb={[1]} mt={[2]}>
          <Choice
            type="checkbox"
            isSpaced={false}
            label="Sign up to receive news and blog posts from Singita"
          />
        </Box>
        <Box>
          <Choice
            type="checkbox"
            isSpaced={false}
            label="I consent to my submitted data being collected and stored"
          />
        </Box>
      </Box>
      <Box mb={[3]}>
        <Text size="tiny" fontWeight="bold">
          {note}
        </Text>
      </Box>
      <Button variant="primary" colorScheme="brandBrown">
        {sendButton}
      </Button>
    </Box>
  )
}

export default EnquiryForm
