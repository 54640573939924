import React, { useMemo } from "react"

import ContentWrapper from "./ContentWrapper"
import LayoutContentWrapper from "./LayoutContentWrapper"
import HeroWrapper from "./HeroWrapper"
import GalleryWrapper from "./GalleryWrapper"
import SectionWrapper from "./SectionWrapper"
import BannerWrapper from "./BannerWrapper"
import ActionBarWrapper from "./ActionBarWrapper"
import ProjectsGridWrapper from "./ProjectsGridWrapper"
import ImageWrapper from "./ImageWrapper"
import VideoWrapper from "./VideoWrapper"
import SectionLeftRightWrapper from "./SectionLeftRightWrapper"
import SectionGridWrapper from "./SectionGridWrapper"
import CardHeroWrapper from "./CardHeroWrapper"
import CardTextWrapper from "./CardTextWrapper"
import CardPosterWrapper from "./CardPosterWrapper"
import ArchiveWrapper from "./ArchiveWrapper"
import SectionGridLodgesWrapper from "./SectionGridLodgesWrapper"
import SectionGridLodgesPrivateVillasWrapper from "./SectionGridLodgesPrivateVillasWrapper"
import SectionGridLayoutLodgesRates from "./SectionGridLayoutLodgesRates"
import ListWrapper from "./ListWrapper"
import AlertWrapper from "./LayoutAlertWrapper"
import HeroVideoWrapper from "./HeroVideoWrapper"
import EmbedWrapper from "./EmbedWrapper"
import TabsWrapper from "./TabsWrapper"
import BorderWrapper from "./BorderWrapper"
import FormWrapper from "./Forms/FormWrapper"
import TableWrapper from "./TableWrapper"
import { AvailabilityActionBar, AvailabilityWizard } from "./Availability"
import Link from "../components/Link"
import TwoUpWrapper from "./TwoUpWrapper"
import LinkImageWrapper from "./LinkImageWrapper"

import { CONTENT_TYPES } from "../common/constants"
import AssetWrapper from "./AssetWrapper"
import FeaturedArticlesWrapper from "./FeaturedArticlesWrapper"

export const LAYOUT_MAPPING = {
  [CONTENT_TYPES.CONTENT]: ContentWrapper,
  [CONTENT_TYPES.LAYOUT_CONTENT]: LayoutContentWrapper,
  [CONTENT_TYPES.GALLERY]: GalleryWrapper,
  [CONTENT_TYPES.HERO]: HeroWrapper,
  [CONTENT_TYPES.SECTION]: SectionWrapper,
  [CONTENT_TYPES.BANNER]: BannerWrapper,
  [CONTENT_TYPES.ACTIONBAR]: ActionBarWrapper,
  [CONTENT_TYPES.CARD_HERO]: CardHeroWrapper,
  [CONTENT_TYPES.CARD_TEXT]: CardTextWrapper,
  [CONTENT_TYPES.CARD_POSTER]: CardPosterWrapper,
  [CONTENT_TYPES.PROJECTS_GRID]: ProjectsGridWrapper,
  [CONTENT_TYPES.IMAGE]: ImageWrapper,
  [CONTENT_TYPES.VIDEO]: VideoWrapper,
  [CONTENT_TYPES.SECTION_LEFT_RIGHT]: SectionLeftRightWrapper,
  [CONTENT_TYPES.SECTION_GRID]: SectionGridWrapper,
  [CONTENT_TYPES.ARCHIVE]: ArchiveWrapper,
  [CONTENT_TYPES.FEATURED_ARTICLES]: FeaturedArticlesWrapper,
  [CONTENT_TYPES.SECTION_GRID_LODGES]: SectionGridLodgesWrapper,
  [CONTENT_TYPES.SECTION_GRID_LODGES_PRIVATE_VILLAS]: SectionGridLodgesPrivateVillasWrapper,
  [CONTENT_TYPES.SECTION_GRID_LODGES_RATES]: SectionGridLayoutLodgesRates,
  [CONTENT_TYPES.LIST]: ListWrapper,
  [CONTENT_TYPES.ALERT]: AlertWrapper,
  [CONTENT_TYPES.HERO_VIDEO]: HeroVideoWrapper,
  [CONTENT_TYPES.EMBED]: EmbedWrapper,
  [CONTENT_TYPES.TABS]: TabsWrapper,
  [CONTENT_TYPES.BORDER]: BorderWrapper,
  [CONTENT_TYPES.LINK]: Link,
  [CONTENT_TYPES.ASSET]: AssetWrapper,
  [CONTENT_TYPES.ACTIONBAR_BOOKING]: AvailabilityActionBar,
  [CONTENT_TYPES.FORM]: FormWrapper,
  [CONTENT_TYPES.PLAN_YOUR_TRIP]: AvailabilityWizard,
  [CONTENT_TYPES.TABLE]: TableWrapper,
  [CONTENT_TYPES.TWO_UP]: TwoUpWrapper,
  [CONTENT_TYPES.LINK_IMAGE]: LinkImageWrapper,
}

const Layout = ({ layout, indexInArray, codes, ...rest }) => {
  const modules = useMemo(() => {
    if (!layout) {
      return <h1>No layout</h1>
    }
    return layout.map((l, index) => {
      if (!l) return null
      const { __typename } = l
      const Component = LAYOUT_MAPPING[__typename]
      if (!Component) return null

      return (
        <Component
          {...l}
          key={l.id}
          cIndex={index}
          indexInArray={indexInArray}
          codes={codes}
          {...rest}
        />
      )
    })
  }, [layout, codes, indexInArray, rest])

  return modules
}

export default Layout
