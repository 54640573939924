import { useState } from "react"
import { useMutation } from "@apollo/client"
import { client } from "../../../apollo/availability-client"
import { SUBMIT_FORM_MUTATION } from "../graphql/mutations"
import { FORM_STATUS } from "../common/constants"

export const useSubmitForm = formId => {
  const [status, setStatus] = useState(FORM_STATUS.PENDING)
  const [errMessages, setErrMessages] = useState([])
  const changeStatus = newStatus => {
    setStatus(newStatus)
  }

  const [submitForm, { loading: apiLoading }] = useMutation(
    SUBMIT_FORM_MUTATION,
    {
      client,
      onCompleted({ submitTransactionWebsiteForm }) {
        if (submitTransactionWebsiteForm) {
          window.dataLayer = window.dataLayer || []

          window.dataLayer.push({
            event: "formSubmission",
            category: "Form",
            action: "Success",
            label: `plan_your_trip_${formId}`,
            form: submitTransactionWebsiteForm,
          })

          setStatus(FORM_STATUS.SUCCESS)
        }
      },
      onError(err) {
        if (err) {
          const errors =
            err && err.graphQLErrors.length
              ? err.graphQLErrors.map(e => e.message)
              : ["Error submitting form"]
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "formSubmission",
            category: "Form",
            action: "Fail",
            label: `form_${formId}`,
            value: errors,
          })
          setErrMessages(errors)
          setStatus(FORM_STATUS.FAILED)
        }
      },
    }
  )

  return [submitForm, apiLoading, status, changeStatus, errMessages]
}
