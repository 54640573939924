import React from "react"
import { graphql } from "gatsby"
import { Card } from "@singita/components"
import ImageWrapper from "./ImageWrapper"
import { LocaleLink } from "gatsby-singita-theme/src/components/LocaleLink"

const PromotionCardWrapper = ({ section, slug }) => {
  const { id, headline, featuredImage, highlight, regions } = section

  return (
    <LocaleLink
      to={slug}
      state={{
        isModal: true,
      }}
    >
      <Card
        key={id}
        variant="standard"
        isLink={Boolean(slug)}
        headline={headline}
        image={featuredImage && <ImageWrapper image={featuredImage} />}
        meta={
          regions && regions.length
            ? [{ text: regions[0].country, color: "brandBrown" }]
            : []
        }
        highlight={highlight}
      />
    </LocaleLink>
  )
}

export default PromotionCardWrapper

export const promotionFields = graphql`
  fragment PromotionFields on ContentfulPromotions {
    id
    name
    headline
    slug
    tag
    featuredImage {
      fluid(quality: 90, maxWidth: 600, maxHeight: 390) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    highlight
    regions: region {
      headline
      country
    }
  }
`
