import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { breakpoints } from '@singita/components';
import { isBrowser } from '../../../singita-web/src/common/constants';

const useActionBar = (breakpoint = 'md') => {
  const ref = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  const checkWidth = useCallback(() => {
    if (isBrowser && ref?.current) {
      const { width } = ref.current.getBoundingClientRect();
      if (width < breakpoints[breakpoint]) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, [ref, breakpoint, setIsMobile]);

  useEffect(() => {
    window.addEventListener('resize', checkWidth);
    return () => window.removeEventListener('resize', checkWidth);
  });

  useLayoutEffect(() => {
    checkWidth();
  }, [checkWidth]);

  return [ref, isMobile];
};

export default useActionBar;
