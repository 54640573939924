import React from "react"
import moment from "moment"
import { DatePicker, Box, Text } from "@singita/components"

export const Dates = ({ label, form, setForm, name, disabled }) => {
  const startDate =
    form[name] && form[name].startDate
      ? form[name].startDate
      : moment.utc().add(10, "days")
  const endDate =
    form[name] && form[name].endDate
      ? form[name].endDate
      : moment.utc().add(15, "days")

  return (
    <>
      {label && (
        <Box>
          <Text size="small" fontWeight="bold">
            {label}
          </Text>
        </Box>
      )}
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        setDates={dates => {
          setForm({
            ...form,
            [name]: dates,
          })
        }}
        openDirection="up"
        variant="default"
        disabled={disabled}
      />
    </>
  )
}
