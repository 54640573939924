import React, { useContext, useMemo } from 'react';
import { Footer, Text, Box } from '@singita/components';
import { ResourceSetContext } from 'gatsby-singita-theme/src/context/ResourceSetContext';
import ContactWrapper from '../components/Menus/MenuContact';
import MenuSocial from '../components/Menus/MenuSocial';
import MenuListWrapper from '../components/Menus/MenuList';
//import LegalWrapper from '../components/Legal';
import { useStaticQuery, graphql } from 'gatsby';
import MenuInline from '../components/Menus/MenuInline';
import { useLanguage } from '../context/LanguageContext';

const FooterWrapper = (props) => {
  const { menuHeadlineSx, showMenu = true } = props;
  const context = useContext(ResourceSetContext);
  const { menu } = useStaticQuery(FOOTER_MENU_QUERY);
  const lang = useLanguage();

  const menus = useMemo(() => {
    return {
      footer:
        menu &&
        menu.edges &&
        menu.edges.find(
          ({ node }) =>
            node.location.includes('footer') && node.node_locale === lang,
        ),
      legal:
        menu &&
        menu.edges &&
        menu.edges.find(
          ({ node }) =>
            node.location.includes('legal') && node.node_locale === lang,
        ),
      contact:
        menu &&
        menu.edges &&
        menu.edges.find(
          ({ node }) =>
            node.location.includes('contact') && node.node_locale === lang,
        ),
    };
  }, [menu, lang]);

  return (
    <Footer
      renderContact={() => (
        <ContactWrapper
          color={'textDark'}
          resources={context.getResourcesForComponent('contactInfo', lang)}
          contactMenu={menus && menus.contact && menus.contact}
        />
      )}
      renderLegal={() => <MenuInline menu={menus.legal} />}
      renderPPM={() => (
        <Box mb={[2]} pb={[2]}>
          <Text size="tiny">
            Created by
            <Text
              as="a"
              size="tiny"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ display: 'inline', marginLeft: '0.2em' }}
              href="http://www.plusplusminus.co.za/?utm_source=Singita&utm_medium=Footer&utm_campaign=Credit"
            >
              PlusPlusMinus
            </Text>
          </Text>
        </Box>
      )}
      renderSocial={() => (
        <MenuSocial
          resources={context.getResourcesForComponent('social', lang)}
          linkPrefix="Footer"
        />
      )}
    >
      {showMenu &&
        menus &&
        menus.footer &&
        menus.footer.node.menuItems.map((menu, idx) => (
          <MenuListWrapper
            key={`footer-element-${idx}`}
            {...menu}
            headlineSx={{ ...menuHeadlineSx }}
          />
        ))}
    </Footer>
  );
};

export default FooterWrapper;

const FOOTER_MENU_QUERY = graphql`
  query {
    menu: allContentfulMenu(
      filter: {
        location: { in: ["footer", "legal", "contact"] }
        # spaceId: { eq: "g4gxyo2p7m7k" }
      }
    ) {
      edges {
        node {
          spaceId
          node_locale
          headline
          location
          menuItems {
            __typename
            ... on Node {
              ... on ContentfulPages {
                name
                slug
              }
              ...LinkFields
              ... on ContentfulMenu {
                headline
                links: menuItems {
                  ... on ContentfulPages {
                    name
                    structuredData {
                      headline
                      menuLabel
                    }
                    slug
                  }
                  ... on ContentfulLodge {
                    name: fullTitle
                    slug
                  }
                  ...LinkFields
                }
              }
            }
          }
        }
      }
    }
  }
`;
