import React from "react"
import { Number } from "@singita/components"

export const NumberField = ({
  name,
  label,
  disabled,
  min,
  max,
  orientation,
  form,
  setForm,
  initialValue,
}) => {
  const value = form[name] || initialValue

  return (
    <Number
      label={label}
      disabled={disabled}
      initialValue={initialValue}
      min={min}
      max={max}
      orientation={orientation}
      value={value}
      onDecrement={() => setForm({ ...form, [name]: value - 1 })}
      onIncrement={() => setForm({ ...form, [name]: value + 1 })}
    />
  )
}
