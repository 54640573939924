import React from "react"
import { get } from "lodash"
import { BUTTON_TYPES, CONTENT_TYPES } from "./constants"
import { Button, Popover } from "@singita/components"
import { LocaleLink as Link } from "gatsby-singita-theme/src/components/LocaleLink"
import LinkWrapper from "../components/Link"

const BUTTON_MAP = {
  [BUTTON_TYPES.PRIMARY]: "primary",
  [BUTTON_TYPES.OUTLINE]: "outline",
  [BUTTON_TYPES.DOWNLOAD]: "text",
  [BUTTON_TYPES.TEXT]: "text",
}

const fluidProperties = ["src", "srcSet", "srcWebp", "srcSetWebp"]

export function processFluid(fluid) {
  if (!fluid) return null
  const processedFluid = { ...fluid }
  fluidProperties.forEach(prop => {
    if (processedFluid[prop] && typeof processedFluid[prop] === 'string') {
      processedFluid[prop] = processedFluid[prop].replaceAll
        ? processedFluid[prop].replaceAll("downloads.ctfassets.net", "images.ctfassets.net")
        : processedFluid[prop].replace(/downloads\.ctfassets\.net/g, "images.ctfassets.net")
    }
  })
  return processedFluid
}

export function mapperWithFunction(data, map) {
  const newData = {}

  Object.keys(map).forEach(key => {
    const property = map[key]

    if (typeof property === "function") {
      newData[key] = property(data)
    } else {
      newData[key] = get(data, property, null)
    }
  })

  return newData
}

const getLabel = link => {
  const { label, structuredData, headline, name } = link

  if (label) return label

  if (structuredData) {
    return structuredData.menuLabel || structuredData.headline
  }

  return headline || name
}

const TARGETS = {
  SAME_TAB: "Same Window",
  NEW_TAB: "New Window",
}

export const getTargetProps = (target, url) => {
  switch (target) {
    case TARGETS.SAME_TAB:
      return {
        target: "_self",
      }
    case TARGETS.NEW_TAB:
      return {
        target: "_blank",
        rel: "noopener noreferrer",
      }
    default:
      return {
        target: "_blank",
        rel: "noopener noreferrer",
      }
  }
}

export function getLinkProps(link, toggleModal, depth = 1) {
  const {
    __typename,
    slug,
    type,
    sx,
    variant,
    icon,
    iconPosition,
    size,
    colorScheme = "brandBrown",
    fontWeight,
    isDownload = false,
    target: linkTarget,
    maxDepth,
    onClick,
  } = link

  const buttonProps = {
    variant,
    sx,
    colorScheme,
    icon,
    iconPosition,
    size,
    fontWeight,
  }
  switch (__typename) {
    case CONTENT_TYPES.PAGE: {
      return {
        linkProps: {
          to: `/${slug}`,
          as: Link,
          id: slug,
        },
        children: <Button {...buttonProps}>{getLabel(link)}</Button>,
      }
    }
    case CONTENT_TYPES.PROMOTION:
    case CONTENT_TYPES.PROJECT:
    case CONTENT_TYPES.LODGE: {
      return {
        linkProps: {
          to: getFullSlug(__typename, slug),
          as: Link,
        },
        children: <Button {...buttonProps}>{getLabel(link)}</Button>,
      }
    }
    case CONTENT_TYPES.ARTICLE: {
      return {
        linkProps: {
          to: generateBlogSlug(slug, link.year, link.month),
          as: Link,
        },
        children: <Button {...buttonProps}>{getLabel(link)}</Button>,
      }
    }
    case CONTENT_TYPES.LINKS: {
      const linkProps = {}
      let children

      if (Object.values(BUTTON_TYPES).includes(type)) {
        if (link.external || link.to || link.file) {
          const { target, rel } = getTargetProps(linkTarget)
          linkProps.as = "a"
          linkProps.href = link.to
            ? link.to
            : link.file
            ? link.file.file.url
            : null
          linkProps.target = target
          linkProps.rel = rel
        } else {
          if (link.destination) {
            return getLinkProps(
              {
                ...link,
                ...link.destination,
                variant: BUTTON_MAP[link.type],
                maxDepth,
              },
              toggleModal,
              depth + 1
            )
          }
          linkProps.as = Link
          linkProps.to = link.to
        }
        children = (
          <Button {...buttonProps} variant={BUTTON_MAP[link.type]}>
            {getLabel(link)}
          </Button>
        )
      }
      return { linkProps, children }
    }
    case CONTENT_TYPES.MENU: {
      const label = getLabel(link)
      const menuButtonProps = {
        ...buttonProps,
        colorScheme,
        variant: "outline",
        popoverPosition: "top",
        popoverPositionHorizontal: link.popoverPositionHorizontal || "left",
        children: label,
      }

      const children = link.menuItems.map(menuItem => (
        <LinkWrapper
          {...menuItem}
          sx={{ width: "100%" }}
          variant="simple"
          colorScheme={colorScheme}
          depth={depth + 1}
          maxDepth={maxDepth}
        />
      ))

      if (maxDepth && maxDepth >= depth) {
        return {
          children: (
            <Button
              {...menuButtonProps}
              onClick={() => onClick(children, label)}
            />
          ),
        }
      }
      return {
        children: (
          <Button
            {...menuButtonProps}
            renderPopover={colorScheme => <Popover>{children}</Popover>}
          />
        ),
      }
    }
    case CONTENT_TYPES.PERSON: {
      return {
        children: <Button {...buttonProps}>{getLabel(link)}</Button>,
        linkProps: {
          onClick: () =>
            toggleModal
              ? toggleModal({
                  headline: link.name,
                  content: link.description,
                  image: link.image,
                })
              : null,
        },
      }
    }
    case CONTENT_TYPES.CONTENT: {
      return {
        children: <Button {...buttonProps}>{getLabel(link)}</Button>,
        linkProps: {
          onClick: () => (toggleModal ? toggleModal(link) : null),
        },
      }
    }
    default: {
      const linkProps = {}
      if (link.external || link.to) {
        const { target, rel } = getTargetProps(linkTarget)
        linkProps.as = "a"
        linkProps.href = link.to
        linkProps.target = target
        linkProps.download = isDownload
        linkProps.rel = rel
      } else {
        linkProps.as = Link
        linkProps.to = link.to
      }
      return {
        linkProps,
        children: <Button {...buttonProps}>{getLabel(link)}</Button>,
      }
    }
  }
}

export function generateBlogSlug(slug, year, month) {
  return `/${year}/${month}/${slug}`
}

const prefixMap = {
  [CONTENT_TYPES.PAGES]: "/",
  [CONTENT_TYPES.PROJECT]: "/conservation/projects/",
  [CONTENT_TYPES.LODGE]: "/lodge/",
  [CONTENT_TYPES.PROMOTION]: "/promotion/",
}

export function getFullSlug(type, slug) {
  const prefix = prefixMap[type] || "/"
  return `${prefix}${slug}`
}

export function getIdForTag(tagName, tags) {
  return Object.keys(tags).find(key => tags[key] === tagName)
}

export function getContentfulTypenameFromEntry(entry) {
  if (get(entry, "sys.type") === "Asset") {
    return "ContentfulAsset"
  } else {
    const entryType = get(entry, "sys.contentType.sys.id")
    if (!entryType) return null
    return `Contentful${entryType.charAt(0).toUpperCase()}${entryType.slice(1)}`
  }
}

export function getEntryArgs(node) {
  const { __typename, sys, ...fields } = get(node, ["data", "target"])
  return {
    __typename,
    ...fields,
  }
}

export function truncateStr(str, charCount = 160) {
  if (!str) return null
  return `${str.substring(0, charCount)}${str.length >= charCount ? "..." : ""}`
}

export function createSlug(str) {
  if (!str) return null
  return str.toLowerCase().replace(/\s+/g, "-")
}

export function getMetaDescription(parsedJSON, charCount = 155) {
  if (!parsedJSON?.content) return null
  let description = ""
  for (const node of parsedJSON.content) {
    if (node.nodeType === "paragraph") {
      for (const contentNode of node.content) {
        if (contentNode.nodeType === "text") {
          description += contentNode.value
          if (description.length >= charCount) {
            break
          }
        }
      }
      if (description.length >= charCount) {
        break
      }
    }
  }

  return truncateStr(description, charCount)
}

//Needed for SEO Purposes.
//Description should be cut off at 160 characters,
//or after the first full stop (whichever comes first)
export function getDescription(parsedJSON) {
  const metaDescription = getMetaDescription(parsedJSON, 160)

  const firstFullStopIndex = metaDescription.indexOf(".")
  if (firstFullStopIndex !== -1 && firstFullStopIndex < 160) {
    return metaDescription.substring(0, firstFullStopIndex + 1)
  } else {
    return metaDescription
  }
}

export const getSeasonRate = (
  currency,
  amount,
  hasChildRates,
  description,
  suffix
) => {
  if (description) return description
  if (!amount) return description || ""
  const formattedAmount = amount
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(",", " ")

  let amountWithCurrency = [currency, formattedAmount].filter(Boolean).join(" ")

  let childAmountWithCurrency = null
  if (hasChildRates) {
    const childRate = Math.round((amount / 2) * 2) / 2

    const childFormattedAmount = childRate
      .toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .replace(",", " ")
    childAmountWithCurrency = [currency, childFormattedAmount]
      .filter(Boolean)
      .join(" ")
    amountWithCurrency = `${amountWithCurrency} per adult`
    childAmountWithCurrency = [`${childAmountWithCurrency} per child`, suffix]
      .filter(Boolean)
      .join(" ")
  }
  amountWithCurrency = [amountWithCurrency, suffix].filter(Boolean).join(" ")
  return [amountWithCurrency, childAmountWithCurrency, description]
    .filter(Boolean)
    .join("\n")
}
