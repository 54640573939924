import React, { useContext } from "react"
import { Text, Heading, Content, theme } from "@singita/components"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import Layout from "../modules/Layout"
import { isEmpty } from "lodash"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { CONTENT_TYPES } from "../common/constants"
import CardStripWrapper from "../modules/CardStripWrapper"
import { Box } from "@singita/components"
import { Link } from "gatsby"
import { getEntryArgs, getLinkProps } from "../common/utils"
import { graphql } from "gatsby"
import { LodgeContext } from "gatsby-singita-theme/src/context/LodgeContext"
import DynamicRichText from "./DynamicRichText"

const CARD_LAYOUTS = [
  CONTENT_TYPES.PROMOTION,
  CONTENT_TYPES.LODGE,
  CONTENT_TYPES.ARTICLE,
  CONTENT_TYPES.PERSON,
]

const MEDIA_TYPES = [
  CONTENT_TYPES.ASSET,
  CONTENT_TYPES.VIDEO,
  CONTENT_TYPES.IMAGE,
  CONTENT_TYPES.TWO_UP,
  CONTENT_TYPES.LINK_IMAGE,
]

function renderEmbeddedEntry(node, children, isInline, colorScheme) {
  if (isEmpty(node.data)) return null

  const entryData = {
    ...getEntryArgs(node),
    ...(colorScheme ? { colorScheme } : {}),
  }

  if (entryData) {
    if (isInline) {
      if (entryData.type === "Text") {
        entryData.containerSx = {
          display: "inline-block",
          mr: [0],
          mb: [0],
          fontSize: "18px",
        }
        entryData.size = "body"
        entryData.fontWeight = "bold"
      } else {
        entryData.containerSx = {
          ...(entryData.containerSx || {}),
          display: "inline-block",
          mr: [1],
          mb: [1],
        }
      }
    }

    if (MEDIA_TYPES.includes(entryData.__typename)) {
      entryData.containerSx = {
        ...(entryData.containerSx || {}),
        mb: [4],
      }

      if (entryData.__typename === CONTENT_TYPES.VIDEO) {
        entryData.visualRatio = entryData.visualRatio || 56.25
      }
    }

    if (CARD_LAYOUTS.includes(entryData.__typename)) {
      return (
        <Box my={[1]}>
          <CardStripWrapper {...entryData} />
        </Box>
      )
    } else {
      return <Layout layout={[entryData]} />
    }
  }

  return null
}

const RichTextRenderer = ({ content, color, colorScheme }) => {
  const {
    lodgeData: { lodgeColor },
  } = useContext(LodgeContext)

  if (!content) return null

  if (content.isDynamic) {
    return <DynamicRichText node={content} />
  }

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Content>
          <Text as="p" size="body" color={color}>
            {children}
          </Text>
        </Content>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Heading
          size="h1"
          as="h1"
          forwardedAs="h1"
          mb={"1rem"}
          fontWeight="headingLight"
          color={color}
        >
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading
          size="h2"
          as="h2"
          forwardedAs="h2"
          mb={"1rem"}
          fontWeight="headingLight"
          color={color}
        >
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading
          size="h3"
          as="h3"
          forwardedAs="h3"
          mb={"1rem"}
          fontWeight="headingLight"
        >
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Heading
          size="h4"
          as="h4"
          forwardedAs="h4"
          mb={"1rem"}
          fontWeight={theme.fontWeights.headingLight}
        >
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Heading
          size="h5"
          as="h5"
          forwardedAs="h5"
          mb={"1rem"}
          fontWeight="headingLight"
        >
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Heading
          size="h6"
          as="h6"
          mb={"1rem"}
          fontWeight="headingLight"
          fontFamily={theme.fonts.sans}
        >
          {children}
        </Heading>
      ),

      [BLOCKS.UL_LIST]: (node, children) => {
        const payload = {
          raw: JSON.stringify(node),
        }
        const Ul = renderRichText(payload, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
          },
        })

        return (
          <Content>
            <Text size="body">{Ul}</Text>
          </Content>
        )
      },

      [BLOCKS.LIST_ITEM]: (node, children) => {
        const payload = {
          raw: JSON.stringify(node),
        }
        const Li = renderRichText(payload, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.LIST_ITEM]: (node, children) => children,
          },
        })

        return (
          <Content>
            <Text size="body" as="li">
              {Li}
            </Text>
          </Content>
        )
      },
      [BLOCKS.QUOTE]: (node, children) => {
        const payload = {
          raw: JSON.stringify(node),
        }
        const quote = renderRichText(payload, {})

        return (
          <Content>
            <Heading as="h5" size="h2">
              {quote}
            </Heading>
          </Content>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) =>
        renderEmbeddedEntry(node, children, false, colorScheme || lodgeColor),
      [INLINES.EMBEDDED_ENTRY]: (node, children) =>
        renderEmbeddedEntry(node, children, true, colorScheme || lodgeColor),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) =>
        renderEmbeddedEntry(node, children, false, colorScheme || lodgeColor),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        if (isEmpty(node.data)) return null

        const entryData = getEntryArgs(node)
        const text = renderRichText({ raw: JSON.stringify(node.content[0]) })

        if (entryData) {
          const { linkProps } = getLinkProps(entryData)
          return (
            <Link {...linkProps} as="a">
              {text}
            </Link>
          )
        }
        return null
      },
    },
    renderText: text => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  return renderRichText(content, options)
}

export default RichTextRenderer

export const richTextReferences = graphql`
  fragment RichTextReferences on Node {
    __typename
    ...LinkFields
    ...ListFields
    ...EmbedFields
    ...TabsFields
    ...ImageFields
    ...VideoFields
    ...AssetFields
    ...GalleryFields
    ...BannerFields
    ...BorderFields
    ...CardTextFields
    ...FormFields
    ...ActionbarBookingFields
    ...TableFields
    ...TwoUpFields
    ...LinkImageFields
    ...ArticleImageFields

    ... on ContentfulArticles {
      contentful_id
      headline
      description {
        description
      }
      slug
      year: publishDate(formatString: "YYYY")
      month: publishDate(formatString: "MM")
      longMonth: publishDate(formatString: "MMMM")
      categories {
        headline
      }
      previewImage {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }

    ... on ContentfulPromotions {
      contentful_id
      headline
      slug
      structuredData {
        headline
        description {
          description
        }
      }
      regions: region {
        headline
        country
      }
      featuredImage {
        fluid(maxWidth: 800, quality: 95) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }

    ... on ContentfulLodge {
      contentful_id
      slug
      structuredData {
        headline
        image {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      fullTitle
      shortDescription {
        shortDescription
      }
      region {
        headline
        country
      }
      color
      image {
        fluid(maxWidth: 800, quality: 95) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }

    ... on ContentfulPerson {
      contentful_id
      name
      role
      regions {
        headline
      }
      description {
        raw
      }
      image {
        fluid(maxWidth: 800, quality: 95) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }

    ... on ContentfulLayoutFormPlanYourTrip {
      contentful_id
    }

    ... on ContentfulPages {
      __typename
      contentful_id
      ...PageLinkFields
    }

    ... on ContentfulProjects {
      contentful_id
      ...ProjectLinkFields
    }
  }
`
