import { CONTENT_TYPES } from "../common/constants"
import React, { useState, useEffect } from "react"
import ReactPlayer from "react-player"
import styled from "@emotion/styled"
import ImageWrapper from "./ImageWrapper"
import VideoWrapper from "./VideoWrapper"
import { Box, Button, Flex, Text, Heading } from "@singita/components"
import {
  getVimeoMeta,
  getIdFromVimeoURL,
} from "gatsby-singita-theme/src/common/utils"

const StyledPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
`

const InlinePlayer = ({ source, backgroundColor, onLoad, currentIndex }) => {
  const [isLoading, setLoading] = useState(true)
  const [meta, setMeta] = useState(null)

  useEffect(() => {
    const fetchMeta = async () => {
      const meta = await getVimeoMeta(getIdFromVimeoURL(source))
      setMeta(meta)
    }
    if (!meta) {
      fetchMeta()
    }
  }, [])

  const player = (
    <StyledPlayer
      url={source}
      controls={true}
      height="100%"
      width="100%"
      onReady={() => {
        setLoading(false)
        onLoad()
      }}
    />
  )

  return (
    <Flex
      flexDirection={["column", "column", "row", "row"]}
      sx={{ position: "relative" }}
      alignItems="flex-start"
    >
      {backgroundColor && isLoading ? (
        <Box
          bg={backgroundColor}
          sx={{
            width: "100%",
            paddingTop: "56.25%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      ) : null}
      <Box
        width={["100%", "100%", "60%", "60%"]}
        sx={{ position: "relative", paddingTop: ["66%", "66%", "66%", "34%"] }}
        mr={[6]}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          {player}
        </Box>
      </Box>
      {meta ? (
        <>
          <Box width={["100%", "100%", "30%", "30%"]} textAlign="left">
            {meta.title ? (
              <Box mb={[4]}>
                <Heading as="h1" size="h3">
                  {meta.title}
                </Heading>
              </Box>
            ) : null}
            <Text size="small">{meta.description}</Text>
          </Box>
        </>
      ) : null}
    </Flex>
  )
}

const VideoThumbnail = ({ source, thumbnail: t, onClick }) => {
  const [thumbnail, setThumbnail] = useState(t)

  useEffect(() => {
    const fetchThumbnail = async () => {
      const meta = await getVimeoMeta(getIdFromVimeoURL(source))
      setThumbnail(meta.thumbnailUrl)
    }
    if (!t) {
      fetchThumbnail()
    }
  }, [t, source])

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100%",
        width: "100%",
        backgroundImage: `url(${thumbnail})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      onClick={onClick}
    >
      <Button
        variant="icon"
        size="large"
        icon="play"
        colorScheme="transparent"
      />
    </Flex>
  )
}

const MEDIA_TYPES = {
  [CONTENT_TYPES.IMAGE]: ImageWrapper,
  [CONTENT_TYPES.VIDEO]: VideoWrapper,
  [CONTENT_TYPES.VIDEO_INLINE]: InlinePlayer,
  [CONTENT_TYPES.VIDEO_THUMBNAIL]: VideoThumbnail,
}

const MediaWrapper = props => {
  const Component = MEDIA_TYPES[props.__typename]
  return <Component {...props} />
}

export default MediaWrapper
