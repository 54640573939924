import { useEffect } from 'react';

const useOutsideClick = (ref, shouldFire, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (shouldFire) callback();
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [ref, callback, shouldFire]);
};

export default useOutsideClick;
