import * as React from "react"
import {
  Flex,
  Box,
  Card,
  Button,
  FilterItem,
  theme,
  Filter,
  Container,
} from "@singita/components"
import { formatImages } from "../common/format"
import ImageWrapper from "../modules/ImageWrapper"
import { Media } from "gatsby-singita-theme/src/Media"
import { useInView } from "react-intersection-observer"

const GalleryThumbnailNav = ({
  galleries,
  filteredGalleries,
  navigate,
  currentGallery,
  filterOffset,
}) => {
  const { inView, ref } = useInView({ threshold: 0.5, initialInView: true })

  const items = React.useMemo(() => {
    const assets = formatImages(galleries)
    return assets.length
      ? assets.filter(asset => {
          const filteredGallery = filteredGalleries.find(
            gallery => asset.id === gallery.id
          )
          const galleryHasContent =
            (filteredGallery.media && filteredGallery.media.length > 0) ||
            (filteredGallery.assets && filteredGallery.assets.length > 0)

          return asset.type !== "video" && galleryHasContent
        })
      : []
  }, [galleries])

  if (!items.length) return null

  return (
    <>
      <Flex flexDirection="row" flexWrap="wrap" ref={ref}>
        {items.map(item => (
          <Box
            key={item.id}
            width={[0.5, 1]}
            mr={[0, 2]}
            maxWidth={["initial", "150px"]}
            onClick={() => navigate(item.id)}
            sx={{ cursor: "pointer" }}
          >
            <Media greaterThanOrEqual="sm">
              <Card
                variant="small"
                headline={item.headline}
                image={<ImageWrapper image={item.image} />}
                imageSx={{ maxWidth: "100%", height: "100px" }}
              />
            </Media>
            <Media lessThan="sm">
              <Button sx={{ py: [1] }} variant="text">
                {item.headline}
              </Button>
            </Media>
          </Box>
        ))}
      </Flex>
      {!inView ? (
        <Box
          sx={{
            position: "fixed",
            left: 0,
            top: `${filterOffset}px`,
            bg: "bgLighter",
            zIndex: theme.zIndex.navbar - 1,
            width: "100%",
          }}
          py={[1]}
        >
          <Container sx={{ px: ["30px", "30px", 0, 0] }}>
            <Filter>
              {items.map((item, idx) => {
                return (
                  <FilterItem
                    key={item.id}
                    mr={[4]}
                    onClick={() => navigate(item.id)}
                    active={currentGallery === item.id}
                  >
                    {item.headline}
                  </FilterItem>
                )
              })}
            </Filter>
          </Container>
        </Box>
      ) : null}
    </>
  )
}

export default GalleryThumbnailNav
