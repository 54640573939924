import React from "react"

import { Card } from "@singita/components"
import { CONTENT_TYPES } from "../common/constants"
import { mapperWithFunction } from "../common/utils"
import Link from "../components/Link"
import ImageWrapper from "./ImageWrapper"

const CARD_MAP = {
  [CONTENT_TYPES.LODGE]: {
    headline: value => value.structuredData?.headline ?? value.fullTitle,
    description: ["shortDescription", "shortDescription"],
    meta: ({ region }) => [
      {
        text: `${region.headline}, ${region.country}`,
        color: "textDark",
      },
    ],
    linkProps: value => ({ label: "Learn more", colorScheme: value.color }),
    renderImage: ({ image }) =>
      image ? () => <ImageWrapper image={image} /> : null,
  },
  [CONTENT_TYPES.PROMOTION]: {
    headline: ({ headline, structuredData }) =>
      structuredData?.headline ?? headline,
    description: ({ structuredData }) =>
      structuredData?.description?.description,
    meta: ({ regions }) =>
      regions.map(region => ({
        text: `${region.headline}, ${region.country}`,
        color: "textDark",
      })),
    linkProps: () => ({ label: "Learn more" }),
    renderImage: ({ featuredImage }) =>
      featuredImage ? () => <ImageWrapper image={featuredImage} /> : null,
  },
  [CONTENT_TYPES.ARTICLE]: {
    headline: ["headline"],
    description: ["description", "description"],
    meta: ({ year, longMonth, categories }) => [
      {
        text: `${longMonth} ${year}`,
        color: "textDark",
      },
      ...categories.map(category => ({
        text: category.headline,
        color: "brandBrown",
      })),
    ],
    linkProps: () => ({ label: "Read more" }),
    renderImage: ({ previewImage }) =>
      previewImage ? () => <ImageWrapper image={previewImage} /> : null,
  },
  [CONTENT_TYPES.PERSON]: {
    headline: ["name"],
    description: ["role"],
    meta: ({ regions }) =>
      regions.map(({ headline }) => ({
        text: headline,
        color: "textDark",
      })),
    linkProps: () => ({ label: "Learn more" }),
    image: ["image", "fluid", "src"],
    renderImage: ({ image }) =>
      image ? () => <ImageWrapper image={image} /> : null,
  },
}

const CardStripWrapper = props => {
  const { linkProps, ...cardProps } = mapperWithFunction(
    props,
    CARD_MAP[props.__typename]
  )
  const link = { ...props, ...linkProps, size: "small" }

  return (
    <Card
      variant="strip"
      renderCta={colorScheme => <Link {...link} colorScheme={colorScheme} />}
      {...cardProps}
    />
  )
}

export default CardStripWrapper
