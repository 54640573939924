import React, { useContext, useEffect, useReducer } from "react"
import { useQuery } from "@apollo/client"
import { Box, Flex } from "@singita/components"
import { QUERY_ALL_REGIONS_AND_LODGES } from "../graphql/queries"
import { client } from "../../../apollo/availability-client"
import {
  Loader,
  Text,
  Heading,
  Modal,
  Button,
  Container,
  theme,
} from "@singita/components"
import {
  ACTIONS,
  BUTTONS,
  STEPS,
  HEADINGS,
  SUBTITLES,
} from "../common/constants"
import { availabilityReducer, initialValue } from "../common/reducer"
import { getStepComponent } from "../utils/helpers"
import { useLockScroll } from "gatsby-singita-theme/src/hooks"
import AvailabilityContext from "gatsby-singita-theme/src/context/AvailabilityContext"

function getFooterButtons(step, dispatch, isModal) {
  switch (step) {
    case STEPS.REGIONS:
    case STEPS.LODGES:
    case STEPS.DATES:
    case STEPS.GUESTS:
      const values = Object.values(STEPS)
      const idxOfStep = values.indexOf(step)
      return (
        <Flex
          my={[2]}
          mx={[isModal ? 3 : 0]}
          justifyContent="flex-end"
          alignItems="center"
        >
          {idxOfStep !== 0 && (
            <Box mr={[5]}>
              <Button
                variant="text"
                icon="chevron-left"
                iconPosition="left"
                colorScheme="brandBrown"
                onClick={() =>
                  dispatch({
                    type: ACTIONS.STEP,
                    payload: { step: values[idxOfStep - 1] },
                  })
                }
              >
                {BUTTONS[values[idxOfStep - 1]]}
              </Button>
            </Box>
          )}
          <Button
            variant="primary"
            icon="chevron-right"
            colorScheme="brandBrown"
            onClick={() =>
              dispatch({
                type: ACTIONS.STEP,
                payload: { step: values[idxOfStep + 1] },
              })
            }
          >
            {BUTTONS[values[idxOfStep + 1]]}
          </Button>
        </Flex>
      )
    case STEPS.CTA:
      return (
        <Box my={[2]} mx={[isModal ? 3 : 0]} width="fit-content">
          <Button
            variant="text"
            colorScheme="brandBrown"
            onClick={() =>
              dispatch({
                type: ACTIONS.STEP,
                payload: { step: STEPS.REGIONS },
              })
            }
          >
            Back to Search
          </Button>
        </Box>
      )
    case STEPS.ENQUIRE:
      return (
        <Box my={[2]} mx={[isModal ? 3 : 0]} width="fit-content">
          <Button
            variant="text"
            colorScheme="brandBrown"
            onClick={() =>
              dispatch({
                type: ACTIONS.STEP,
                payload: { step: STEPS.CTA },
              })
            }
          >
            Back
          </Button>
        </Box>
      )
    // no default
  }
}

const PlanContainer = ({ children, isModal, modalFooter, ...modalProps }) => {
  return isModal ? (
    <Modal
      size="small"
      title="Plan your trip"
      contentProps={{
        mt: [0],
        pt: [2],
      }}
      sx={{ zIndex: "planYourTripModal" }}
      modalFooter={modalFooter}
      {...modalProps}
    >
      {children}
    </Modal>
  ) : (
    <Container sx={{ px: [0] }}>
      {children}
      <Box mt={[5]}>{modalFooter}</Box>
    </Container>
  )
}

const AvailabilityWizard = ({ isModal = false }) => {
  const [state, dispatch] = useReducer(availabilityReducer, initialValue)
  const { currentStep } = state

  const { setShowAvailability, showAvailability } = useContext(
    AvailabilityContext
  )

  const { loading, data } = useQuery(QUERY_ALL_REGIONS_AND_LODGES, {
    client,
  })

  useLockScroll(isModal && showAvailability)

  useEffect(() => {
    if (!showAvailability) {
      dispatch({ type: ACTIONS.RESET, payload: initialValue })
    }
  }, [showAvailability])

  const stepValues = Object.values(STEPS)
  const idxOfCurrentStep = stepValues.indexOf(currentStep)
  const idxOfLastStep = stepValues.indexOf(STEPS.CTA)
  const isAvailabilityStep =
    idxOfCurrentStep <= stepValues.indexOf(STEPS.GUESTS)

  return (
    <PlanContainer
      size="small"
      isOpen={showAvailability}
      isModal={isModal}
      title="Plan your trip"
      modalFooter={getFooterButtons(currentStep, dispatch, isModal)}
      contentProps={{
        mt: [0],
        pt: [2],
      }}
      handleClose={() => setShowAvailability(false)}
      sx={{ zIndex: "planYourTripModal" }}
      maxWidth="720px"
    >
      {loading ? (
        <Box mt={[4]}>
          <Loader isActive={true} />
        </Box>
      ) : (
        <Box>
          {idxOfCurrentStep <= idxOfLastStep && (
            <Flex justifyContent="space-between" mb={[5]}>
              {Object.values(STEPS)
                .filter((_, idx) => idx <= idxOfLastStep)
                .map((step, idx) => {
                  const isCurrent = currentStep === step
                  return (
                    <Text
                      key={`step-${idx}`}
                      size="small"
                      fontWeight="bold"
                      color={isCurrent ? "brandBrown" : "baseGray"}
                      sx={{
                        opacity: isCurrent ? "full" : "medium",
                        transition: `all ${theme.speed.default} ease`,
                      }}
                    >
                      Step {idx + 1}
                    </Text>
                  )
                })}
            </Flex>
          )}

          {isAvailabilityStep && (
            <>
              <Box mb={[1]}>
                <Heading size="h4" fontWeight="normal">
                  {HEADINGS[currentStep]}
                </Heading>
              </Box>
              <Box mb={[3]}>
                {SUBTITLES[currentStep].map((subtitle, idx) => (
                  <>
                    <Text key={`subtitle-${idx}`} size="tiny" color="textLight">
                      {subtitle}
                    </Text>
                    <br />
                  </>
                ))}
              </Box>
            </>
          )}

          {getStepComponent(
            state.currentStep,
            data.findRegions,
            state.form,
            state.summary,
            dispatch
          )}
        </Box>
      )}
    </PlanContainer>
  )
}

export default AvailabilityWizard
