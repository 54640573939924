import { useState } from 'react';
import useLockScroll from '../hooks/useLockScroll';

const useModal = () => {
  const [modal, setModal] = useState({
    isOpen: false,
    contentId: '',
    contentType: '',
  });
  const { isOpen } = modal;
  useLockScroll(isOpen);

  const setModalState = (contentId, contentType) => {
    setModal({
      isOpen: !isOpen,
      contentId,
      contentType,
    });
  };

  return [modal, setModalState];
};

export default useModal;
