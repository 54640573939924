import React, { useContext } from "react"
import { graphql } from "gatsby"
import { Card } from "@singita/components"
import { mapperWithFunction } from "../common/utils"
import RichTextRenderer from "../components/RichTextRenderer"
import MediaWrapper from "./MediaWrapper"
import { LodgeContext } from "../context/LodgeContext"
import { useInView } from "react-intersection-observer"
import { CONTENT_TYPES } from "../common/constants"

const CARD_HERO_MAP = {
  headline: ["headline"],
  subtitle: ["subtitle"],
  visual: value => {
    if (value.visual) {
      return (
        <MediaWrapper
          {...value.visual}
          containerSx={{
            paddingBottom: value.visualRatio
              ? `${value.visualRatio}%`
              : value.visual.__typename === CONTENT_TYPES.VIDEO
              ? "93%"
              : null,
          }}
          altText={value.visual.altText || null}
        />
      )
    }
    return null
  },
  altText: ["altText"],
  visualPosition: value =>
    value.isInArray
      ? value.index % 2 === 0
        ? "right"
        : "left"
      : value.visualPosition || "right",
  content: ["content"],
  showPattern: ["showPattern"],
  patternPosition: ["patternPosition"],
  visualRatio: ["visualRatio"],
}

const CardHeroWrapper = props => {
  const { content, ...cardProps } = mapperWithFunction(props, CARD_HERO_MAP)
  const {
    lodgeData: { lodgeColor },
  } = useContext(LodgeContext)
  const { ref, inView } = useInView({ threshold: 0.3 })

  return (
    <Card
      ref={ref}
      shouldAnimate={true}
      variant="hero"
      {...cardProps}
      colorScheme={lodgeColor}
      patternProps={props.patternProps}
      visualRatio={props.visualRatio}
    >
      {content && <RichTextRenderer content={content} />}
      {props.children}
    </Card>
  )
}

export default CardHeroWrapper

export const CardHeroFields = graphql`
  fragment CardHeroFields on ContentfulLayoutCardHero {
    contentful_id
    headline
    subtitle
    content {
      raw
      references {
        ... on Node {
          ...ContentFields
          ...RichTextReferences
        }
      }
    }
    visual {
      __typename
      ... on Node {
        ... on ContentfulImage {
          ...ImageFields
          image {
            fluid(quality: 95, maxWidth: 930, maxHeight: 1000) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        ... on ContentfulVideo {
          ...VideoFields
        }
      }
    }
    visualPosition
    visualRatio
    showPattern
    patternPosition
    tag
  }
`
