import React from 'react';
import Regions from '../components/Regions';
import Lodges from '../components/Lodges';
import Dates from '../components/Dates';
import Guests from '../components/Guests';
import CTA from '../components/CTA';
import EnquiryForm from '../components/EnquiryForm';
import { ACTIONS, STEPS } from '../common/constants';
import parsePhoneNumber from 'libphonenumber-js';

export function getUniqueCountries(regions) {
  return regions.length > 0
    ? [...new Set(regions.map(({ country }) => country))]
    : null;
}

export function getSelectedRegions(selectedRegions, regions) {
  if (selectedRegions.length === 0) return regions;
  return regions.filter((region) => selectedRegions.includes(region.code));
}

export function getUpdatedField(key, item, state) {
  let field = [...state.form[key]];
  let fieldSummary = [...state.summary[key]];

  if (field.includes(item.code)) {
    field = field.filter((f) => f !== item.code);
  } else {
    field = [...field, item.code];
  }

  if (fieldSummary.includes(item.name)) {
    fieldSummary = fieldSummary.filter((fs) => fs !== item.name);
  } else {
    fieldSummary = [...fieldSummary, item.name];
  }

  return {
    ...state,
    form: {
      ...state.form,
      [key]: field,
    },
    summary: {
      ...state.summary,
      [key]: fieldSummary,
    },
    errors: {},
  };
}

export function getStepComponent(
  step,
  regions,
  form,
  summary,
  dispatch,
  colorScheme,
  codes = {},
  errors = {},
) {
  switch (step) {
    case STEPS.REGIONS:
      return (
        <Regions
          regions={regions}
          form={form}
          updateRegions={(region) =>
            dispatch({ type: ACTIONS.UPDATE_REGIONS, payload: { region } })
          }
          colorScheme={colorScheme}
          matchCode={codes.region}
        />
      );
    case STEPS.LODGES:
      return (
        <Lodges
          regions={regions}
          form={form}
          updateLodges={(lodge) =>
            dispatch({ type: ACTIONS.UPDATE_LODGES, payload: { lodge } })
          }
          colorScheme={colorScheme}
          matchCode={codes.lodge}
        />
      );
    case STEPS.DATES:
      return (
        <Dates
          form={form}
          setDates={(dates) =>
            dispatch({ type: ACTIONS.UPDATE_DATES, payload: { dates } })
          }
          colorScheme={colorScheme}
          errors={errors}
        />
      );
    case STEPS.GUESTS:
      return (
        <Guests
          form={form}
          setGuests={(step, key) =>
            dispatch({ type: ACTIONS.UPDATE_GUESTS, payload: { step, key } })
          }
          colorScheme={colorScheme}
        />
      );
    case STEPS.CTA:
      if (
        ['children', 'toddler', 'baby'].every((key) => form.guests[key] === 0)
      ) {
        return (
          <CTA
            onEnquire={() =>
              dispatch({ type: ACTIONS.STEP, payload: { step: STEPS.ENQUIRE } })
            }
            onCheckAvailability={() =>
              dispatch({
                type: ACTIONS.STEP,
                payload: {
                  step: STEPS.CHECK_AVAILABILITY,
                  // NOTE:Removed GTM event in reducer. Will not trigger
                  dataLabel: 'check_availability_wizard',
                },
              })
            }
            colorScheme={colorScheme}
          />
        );
      }

    case STEPS.ENQUIRE:
      return (
        <EnquiryForm form={form} summary={summary} colorScheme={colorScheme} />
      );
    // no default
  }
}

export const validate = (fields, values) => {
  const errors = {};

  fields.forEach((field) => {
    if (field.type === 'text' && field.required && !values[field.name]) {
      errors[field.name] =
        field.errorMessage ||
        `Please enter your ${
          field.label ? field.label.toLowerCase() : 'Required field'
        }`;
    } else if (field.type === 'tel') {
      if (values[field.name]) {
        const phoneNumber = parsePhoneNumber(values[field.name]);

        if (phoneNumber && !phoneNumber.isValid()) {
          errors[field.name] = 'Please enter a phone number';
        }
      } else {
        errors[field.name] = 'Please enter a phone number';
      }
    } else if (
      field.type === 'email' &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[field.name])
    ) {
      errors[field.name] = 'Please enter a valid email address';
    } else if (
      field.type === 'select' &&
      field.required &&
      !values[field.name]
    ) {
      errors[field.name] = field.errorMessage || `Please select an option`;
    } else if (field.required && !values[field.name]) {
      errors[field.name] = 'This is a required field';
    }
  });

  return errors;
};
