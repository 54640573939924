import React from "react"
import { Box } from "@singita/components"
import { getSelectedRegions } from "../utils/helpers"
import { Text, Choice, Border } from "@singita/components"

const Lodges = ({ regions, form, updateLodges }) => {
  const selectedRegions = getSelectedRegions(form.regions, regions)

  return (
    <Box>
      {selectedRegions.map(({ code, lodges, name }, idx) => (
        <Box key={code}>
          <Box mb={[2]}>
            <Text size="tiny" fontWeight="bold">
              {name}
            </Text>
          </Box>
          {lodges.map(lodge => (
            <Box key={lodge.code} mb={[1]}>
              <Choice
                type="checkbox"
                key={lodge.code}
                value={lodge.code}
                checked={form.lodges.includes(lodge.code)}
                label={lodge.name}
                onClick={() => updateLodges(lodge)}
                orientation="right"
              />
            </Box>
          ))}
          {idx < selectedRegions.length - 1 && (
            <Border my={[3]} isContainerised={false} />
          )}
        </Box>
      ))}
    </Box>
  )
}

export default Lodges
