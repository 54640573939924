import React, { useMemo } from "react"
import { Box } from "@singita/components"
import { Section } from "@singita/components"
import { graphql } from "gatsby"
import { CONTENT_TYPES } from "../common/constants"
import ConservationPartnerWrapper from "./ConservationPartnerWrapper"
import CardHeroWrapper from "./CardHeroWrapper"
import CardTextWrapper from "./CardTextWrapper"

const SectionLeftRightWrapper = ({
  headline,
  subtitle,
  modules,
  styles,
  description,

  ...rest
}) => {
  const COMPONENT_MAP = useMemo(
    () => ({
      [CONTENT_TYPES.CARD_HERO]: CardHeroWrapper,
      [CONTENT_TYPES.CONSERVATION_PARTNER]: ConservationPartnerWrapper,
      [CONTENT_TYPES.CARD_TEXT]: CardTextWrapper,
    }),
    []
  )

  return (
    <Box {...styles}>
      <Section
        headline={headline}
        description={description && description.description}
        headlineAlignment="center"
        headingStyles={{ description: { px: ["30px"] } }}
      >
        {modules.map((module, idx) => {
          const { __typename } = module
          const Component = COMPONENT_MAP[__typename] || CardHeroWrapper
          const isLast = idx === modules.length - 1
          if (!Component) return null

          return (
            <Box mb={!isLast ? [6, 12] : [0]} key={module.contentful_id}>
              <Component
                {...module}
                index={idx}
                isInArray={modules.length > 0}
                {...rest}
              />
            </Box>
          )
        })}
      </Section>
    </Box>
  )
}

export default SectionLeftRightWrapper

export const SectionLeftRightFields = graphql`
  fragment SectionLeftRightFields on ContentfulLayoutSectionLeftRight {
    headline
    description {
      description
    }
    modules {
      __typename
      ...ConservationPartnerFields
      ...CardHeroFields
    }
    tag
    styles {
      pt
    }
  }
`

export const SectionLeftRightTextFields = graphql`
  fragment SectionLeftRightTextFields on ContentfulLayoutSectionLeftRight {
    headline
    description {
      description
    }
    contentful_id
    modules {
      __typename
      #...CardTextFields
    }
    styles {
      pt
    }
  }
`
