import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import BackgroundImage from "gatsby-background-image"

import { mapperWithFunction } from "../common/utils"
import { HeroVideo, theme } from "@singita/components"
import { useSetNavbarStylesOnPresence } from "gatsby-singita-theme/src/hooks"
import { TEMPLATE_TYPES } from "gatsby-singita-theme/src/common/constants"
import { getJsonLd } from "gatsby-singita-theme/src/components/Helmet"

const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
`

const HERO_VIDEO_MAP = {
  headline: ["headline"],
  subtitle: ["subtitle"],
  fullUrl: ["video", "fullUrl"],
  previewUrl: ["video", "previewUrl"],
  renderThumbnail: value => () => {
    return value.video.thumbnail ? (
      <StyledBackground
        Tag="figure"
        critical
        fluid={value.video.thumbnail.fluid}
      />
    ) : null
  },
  showPattern: ["showPattern"],
  colorScheme: ["colorScheme"],
  thumbnailUrl: ["video", "thumbnail", "fluid", "src"],
  caption: ({ subtitle, caption }) => 
    {
     return caption || subtitle || null;
    } ,
  uploadDate: ["video", "createdAt"]
}

const HeroVideoWrapper = props => {
  const heroRef = useSetNavbarStylesOnPresence(0, "-50px 0px 0px 0px", {
    logoColor: theme.colors.textWhite,
    secondaryButtonColor: theme.colors.textWhite,
  })
  const heroVideoProps = mapperWithFunction(props, HERO_VIDEO_MAP)
  const schemaData = getJsonLd(heroVideoProps, TEMPLATE_TYPES.VIDEO)
  return (
    <>
      <HeroVideo
        {...heroVideoProps}
        ref={heroRef}
        patternProps={props.patternProps}
        headlineSx={{ textAlign: "center" }}
      />
      {<script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />}
    </>
  )
}

export default HeroVideoWrapper

export const heroVideoFields = graphql`
  fragment HeroVideoFields on ContentfulLayoutHeroVideo {
    headline
    subtitle
    showPattern
    video {
      caption
      headline
      fullUrl
      previewUrl
      thumbnail {
        fluid(maxWidth: 1900, quality: 95) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      createdAt
    }
  }
`
