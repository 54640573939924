import React from "react"
import { useInView } from "react-intersection-observer"
import { breakpoints, useWindowWidth, motion, Box } from "@singita/components"
import styled from "@emotion/styled"

const gridItemVariants = {
  animate: ({ index, cols }) => ({
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 50,
      damping: 15,
      delay: 0.15 * (index % cols),
    },
  }),
  initial: {
    opacity: 0,
    y: 100,
  },
}

const StyledGridItem = motion.custom(styled(Box)`
  -ms-grid-column: ${({ index, cols }) => (index % cols) + 1};
  -ms-grid-row: ${({ index, cols, hasSpecialItem }) => {
    let row = Math.floor(index / cols) + 1
    if (hasSpecialItem && row >= 3) row += 2
    return row
  }};
`)

const GridItem = ({ index, children, columns, hasSpecialItem }) => {
  const { ref, inView } = useInView({ threshold: 0.3, triggerOnce: true })
  const windowWidth = useWindowWidth()

  const bp = [breakpoints.sm, breakpoints.md, breakpoints.lg, breakpoints.xl]
  let cols = typeof columns === "number" ? columns : 1

  if (columns?.length > 0 && windowWidth > 0) {
    if (windowWidth > bp[bp.length - 1]) {
      cols = columns[columns.length - 1]
    } else {
      for (const idx in columns) {
        if (windowWidth < bp[idx]) {
          cols = columns[idx]
          break
        }
      }
    }
  }

  const maxCols =
    typeof columns === "number"
      ? columns
      : columns?.length
      ? columns[columns.length - 1]
      : 1

  return (
    <StyledGridItem
      ref={ref}
      variants={gridItemVariants}
      animate={inView ? "animate" : "initial"}
      initial="initial"
      custom={{ index, cols }}
      index={index}
      cols={maxCols}
      hasSpecialItem={hasSpecialItem}
    >
      {children}
    </StyledGridItem>
  )
}

export default GridItem
