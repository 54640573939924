import { useStaticQuery, graphql } from "gatsby";

const useAllSitePages_PageExists = (slug) => {
  const data = useStaticQuery(query);

  return data.allSitePage.edges.some(
    (edge) => edge.node.path === slug
  );
};

export default useAllSitePages_PageExists;

const query = graphql`
  query {
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`;
