import React, { useContext } from "react"
import styled from "@emotion/styled"

import { AlertsContext } from "gatsby-singita-theme/src/context/AlertsContext"
import { Box, theme } from "@singita/components"
import AlertWrapper from "../modules/AlertWrapper"

const BannerPane = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: ${theme.zIndex.notification};
`

const BannerNotifications = props => {
  const { bannerAlerts } = useContext(AlertsContext)

  return (
    <BannerPane>
      {bannerAlerts.map(alert => (
        <AlertWrapper key={alert.id} {...alert} />
      ))}
    </BannerPane>
  )
}

export default BannerNotifications
