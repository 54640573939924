import React, { useContext, useEffect } from "react"
import { getEntries } from "../common/contentful"
import { AlertsContext } from "gatsby-singita-theme/src/context/AlertsContext"

const GlobalAlerts = () => {
  const { addAlert } = useContext(AlertsContext)

  useEffect(() => {
    async function getGlobalAlerts() {
      const res = await getEntries({
        include: 10,
        content_type: "alert",
        "fields.isGlobal": true,
        "fields.isActive": true,
      })
      if (res) {
        res.forEach(alert => addAlert(alert))
      }
    }
    getGlobalAlerts()
  }, [addAlert])

  return null
}

export default React.memo(GlobalAlerts)
