import React from 'react';
import { Text, Box, theme } from '@singita/components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export const PhoneNumber = ({
  label,
  setForm,
  form,
  name,
  error,
  bg,
  disabled,
}) => {
  return (
    <>
      {label && (
        <Box>
          <Text as="label" size="small" fontWeight="bold">
            {label}
          </Text>
        </Box>
      )}
      <Box mb={[1]}>
        <PhoneInput
          containerStyle={{
            width: '100%',
            border: error ? `1px solid ${theme.colors.statusError}` : 'none',
            borderRadius: '3px',
          }}
          inputStyle={{
            width: '100%',
            border: 'none',
            borderBottom: `border-bottom: 1px solid ${theme.colors.baseGrayLight}`,
            borderRadius: 3,
            color: theme.colors.baseGray,
            background: theme.colors[bg] ? theme.colors[bg] : 'white',
            height: '47px',
            fontSize: '16px',
          }}
          country={'us'}
          value={form.contact ? form.contact : '1'}
          onChange={(phone) => setForm({ ...form, [name]: `+${phone}` })}
          inputProps={{ name, 'aria-label': label }}
          disabled={disabled}
        />
      </Box>
      <Text color="statusError" size="small">
        {error}
      </Text>
    </>
  );
};
