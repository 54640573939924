import React from "react"
import { graphql } from "gatsby"
import RichTextRenderer from "../components/RichTextRenderer"
import { mapperWithFunction } from "../common/utils"
import { Box } from "@singita/components"
import { Container, Heading } from "@singita/components"

const ALIGNMENT = {
  LEFT: "Left",
  RIGHT: "Right",
  CENTER: "Center",
  JUSTIFY: "Justify",
}

const ALIGNMENT_MAP = {
  [ALIGNMENT.LEFT]: "left",
  [ALIGNMENT.RIGHT]: "right",
  [ALIGNMENT.CENTER]: "center",
  [ALIGNMENT.JUSTIFY]: "justify",
}

const CONTENT_MAP = {
  alignment: value => ALIGNMENT_MAP[value.alignment],
  contents: ["contents"],
  content: ["contents", "content"],
  headline: ["contents", "headline"],
  subtitle: ["contents", "subtitle"],
  styles: ({ styles = {} }) => {
    if (!styles) return {}
    return Object.keys(styles).reduce((result, item) => {
      if (styles[item]) {
        result[item] = styles[item]
      }

      return result
    }, {})
  },
}
const LayoutContentWrapper = props => {
  const {
    contents,
    content,
    alignment,
    headline,
    subtitle,
    styles: { box, ...rest },
    ...contentProps
  } = mapperWithFunction(props, CONTENT_MAP)

  return (
    <Container pt={[12, 20]} pb={[12, 20]} {...rest}>
      <Box
        width={[12 / 12, 8 / 12]}
        mx="auto"
        {...box}
        {...contentProps}
        textAlign={alignment}
      >
        {contents &&
          contents.map(c => (
            <>
              {c.subtitle && (
                <Box mb={[4]}>
                  <Heading size="h5" color="textDark">
                    {c.subtitle}
                  </Heading>
                </Box>
              )}
              {c.headline && (
                <Box mb={[4]}>
                  <Heading size="h1">{c.headline}</Heading>
                </Box>
              )}

              {c.content && <RichTextRenderer content={c.content} />}
            </>
          ))}
      </Box>
    </Container>
  )
}

export default LayoutContentWrapper

export const LayoutContentFields = graphql`
  fragment LayoutContentFields on ContentfulLayoutContent {
    id
    alignment
    contents {
      headline
      subtitle
      content {
        raw
        references {
          ...RichTextReferences
        }
      }
    }
    styles {
      pt
      pb
      box {
        width
      }
    }
  }
`
