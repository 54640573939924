import { useContext } from 'react';
import ContentModalContext from '../context/ContentModalContext';

const useContentModal = () => {
  const {
    modalProps: { isOpen, item },
    toggleModal,
    closeModal,
  } = useContext(ContentModalContext);

  return { isOpen, item, toggleModal, closeModal };
};

export default useContentModal;
