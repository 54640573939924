export const ACTIONS = {
  STEP: 'step',
  UPDATE_REGIONS: 'update_regions',
  UPDATE_LODGES: 'update_lodges',
  UPDATE_DATES: 'update_dates',
  UPDATE_GUESTS: 'update_guests',
  RESET: 'reset',
  ADD_REGION: 'add_region',
  SET_FORM: 'set_form',
};

export const STEPS = {
  REGIONS: 'regions',
  LODGES: 'lodges',
  DATES: 'dates',
  GUESTS: 'guests',
  CTA: 'call-to-action',
  ENQUIRE: 'enquire',
  CHECK_AVAILABILITY: 'check_availability',
};

export const BUTTONS = {
  [STEPS.REGIONS]: 'Regions',
  [STEPS.LODGES]: 'Lodges',
  [STEPS.DATES]: 'Dates',
  [STEPS.GUESTS]: 'Guests',
  [STEPS.CTA]: 'Submit',
};

export const HEADINGS = {
  [STEPS.REGIONS]: 'Regions',
  [STEPS.LODGES]: 'Lodges',
  [STEPS.DATES]: 'Dates',
  [STEPS.GUESTS]: 'Guests',
};

export const SUBTITLES = {
  [STEPS.REGIONS]: ["Select the regions you're interested in:"],
  [STEPS.LODGES]: [
    "These lodges are availabile in the regions you've selected.",
    "Please select the ones you're interested in:",
  ],
  [STEPS.DATES]: ['Select the dates which will suit you:'],
  [STEPS.GUESTS]: [
    'Please indicate how many adults will need to be accommodated.',
    'If your booking includes children, please enquire directly on the next step.',
    'View our child policy for each lodge <a style="font-weight: bold;" href="https://assets.ctfassets.net/wds1hqrprqxb/2zrmpggoWK5aSjfFB5ifr9/010d50c7228024c06108002070f73c26/Singita_Activities_-_Children_at_a_Glance.pdf" target="_blank">here.</a>',
  ],
};

export const CTA_SECTIONS = {
  AVAILABILITY: 'availability',
  ENQUIRY: 'enquiry',
};

export const CTA_COPY = {
  [CTA_SECTIONS.AVAILABILITY]: {
    heading: 'Check Availability',
    subtitle:
      'To see if we have availability for your preferred criteria, please click the button below.',
    button: 'Check now',
  },
  [CTA_SECTIONS.ENQUIRY]: {
    heading: 'Speak with a Singita Travel Advisor',
    subtitle:
      'Need some help to plan your trip? Enquire below to contact one of our Singita Travel Advisors.',
    button: 'Enquire now',
  },
};

export const ENQUIRY_COPY = {
  intro:
    'Please complete the form below and one of our Singita Travel Advisors will contact you shortly to plan your trip.',
  sendButton: 'Send Enquiry',
  backButton: 'Back',
  errorFetchingForm: 'Error fetching form. Please try again later.',
  errorSubmit: 'Something went wrong. Please try again later.',
  successSubmit:
    'Thanks for making contact with us. We’ll be in touch within 24 hrs.',
  showForm: 'Show form again',
  attention: 'Some fields need your attention',
  guestLabels: {
    adult: 'Adults',
    children: 'Ages 10 - 16',
    toddler: 'Ages 3 - 9',
    baby: 'Ages 0 - 2',
  },
  guestLabelsPlural: {
    adults: 'Adults',
    children: 'Ages 10 - 16',
    toddler: 'Ages 3 - 9',
    baby: 'Ages 0 - 2',
  },
};

export const PLAN_YOUR_TRIP = {
  label: 'Plan Your Trip',
};
