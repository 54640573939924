import * as React from "react"
import {
  Box,
  Section,
  Grid,
  GridContainer,
  GridCell,
  theme,
} from "@singita/components"
import { formatImages } from "../common/format"
import MediaWrapper from "../modules/MediaWrapper"
import { useInView } from "react-intersection-observer"
import { navigate, useLocation } from "@reach/router"

const GridSection = ({ gallery, index: galleryIdx, onInView, viewGallery }) => {
  const { inView, ref } = useInView({ threshold: 0.3 })
  const [isAssetExist, setIsAssetExist] = React.useState(
    gallery.assets.map(() => true)
  )
  const location = useLocation()

  React.useEffect(() => {
    if (inView) {
      onInView(gallery.id)
    }
  }, [inView, gallery])

  const openGallery = assetId => () => {
    viewGallery(gallery.id, assetId)
  }

  return (
    <Box ref={ref}>
      <Section
        id={`gallery-${gallery.id}`}
        direction="row"
        headline={gallery.headline}
        description={gallery.description}
        textContainerSx={{ pr: [0, 5], top: "70px" }}
      >
        <GridContainer
          sx={{
            gridTemplateColumns: [
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(3, 1fr)",
            ],
            gridTemplateRows: [
              `repeat(${Math.ceil(gallery.assets.length / 2)}, 100px)`,
              `repeat(${Math.ceil(gallery.assets.length / 2)}, 120px)`,
              `repeat(${Math.ceil(gallery.assets.length / 3)}, 140px)`,
              `repeat(${Math.ceil(gallery.assets.length / 3)}, 170px)`,
            ],
            rowGap: `${theme.space[1]}px`,
            columnGap: `${theme.space[1]}px`,
          }}
        >
          {gallery.assets.map((asset, idx) => {
            const isAssetOk = isAssetExist[idx]

            const assetPath =
              asset.type === "video" && asset.title
                ? `/videos/${asset.title.toLowerCase().replace(/\s+/g, "-")}`
                : null
            return (
              isAssetOk && (
                <GridCell
                  cols={3}
                  index={idx}
                  key={`gallery-${gallery.id}-${idx}`}
                  sx={{
                    minHeight: ["100px", "120px", "140px", "170px"],
                    cursor: "pointer",
                  }}
                >
                  <MediaWrapper
                    {...asset}
                    role="presentation"
                    imgStyle={{ objectFit: "cover" }}
                    style={{ width: "100%", height: "100%" }}
                    loading={galleryIdx === 0 && idx < 3 ? "eager" : "auto"}
                    backgroundColor={theme.colors.brandBrown}
                    onClick={
                      assetPath
                        ? () =>
                            navigate(assetPath, {
                              state: {
                                prevModalUrl:
                                  !!location.state?.isModal &&
                                  location.pathname,
                                isModal: true,
                                anchor: asset.id,
                              },
                            })
                        : openGallery(asset.id)
                    }
                    onError={() =>
                      setIsAssetExist(prevExists => [
                        ...prevExists.slice(0, idx),
                        false,
                        ...prevExists.slice(idx + 1),
                      ])
                    }
                  />
                </GridCell>
              )
            )
          })}
        </GridContainer>
      </Section>
    </Box>
  )
}

const ListGallery = ({
  galleries: inputGalleries,
  setGallery,
  viewGallery,
}) => {
  const galleries = React.useMemo(
    () => formatImages(inputGalleries, { maxWidth: 400, quality: 90 }, true),
    [inputGalleries]
  )

  if (!galleries.length) return null

  return (
    <>
      {galleries.map((gallery, index) => (
        <GridSection
          key={`gallery-${gallery.id}`}
          gallery={gallery}
          index={index}
          onInView={setGallery}
          viewGallery={viewGallery}
        />
      ))}
    </>
  )
}

export default ListGallery
