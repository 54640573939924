import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { isEmpty } from "lodash"

import { Heading, Content, Text, theme, Box } from "@singita/components"
import Layout from "../modules/Layout"
import { getLinkProps } from "../common/utils"
import Link from "./Link"
import { CONTENT_TYPES } from "../common/constants"
import CardStripWrapper from "../modules/CardStripWrapper"

const CARD_LAYOUTS = [
  CONTENT_TYPES.PROMOTION,
  CONTENT_TYPES.LODGE,
  CONTENT_TYPES.ARTICLE,
  CONTENT_TYPES.PERSON,
]

function getOptions(color) {
  return {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Content>
          <Text as="p" size="body" color={color}>
            {children}
          </Text>
        </Content>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Heading
          size="h1"
          as="h1"
          forwardedAs="h1"
          mb={"1rem"}
          fontWeight="headingLight"
          color={color}
        >
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading
          size="h2"
          as="h2"
          forwardedAs="h2"
          mb={"1rem"}
          fontWeight="headingLight"
        >
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading
          size="h3"
          as="h3"
          forwardedAs="h3"
          mb={"1rem"}
          fontWeight="headingLight"
        >
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Heading
          size="h4"
          as="h4"
          forwardedAs="h4"
          mb={"1rem"}
          fontWeight={theme.fontWeights.headingLight}
        >
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Heading
          size="h5"
          as="h5"
          forwardedAs="h5"
          mb={"1rem"}
          fontWeight="headingLight"
        >
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Heading
          size="h6"
          as="h6"
          mb={"1rem"}
          fontWeight="headingLight"
          fontFamily={theme.fonts.sans}
        >
          {children}
        </Heading>
      ),

      [BLOCKS.UL_LIST]: (node, children) => {
        const Ul = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
          },
        })

        return (
          <Content>
            <Text size="body">{Ul}</Text>
          </Content>
        )
      },

      [BLOCKS.LIST_ITEM]: (node, children) => {
        const Li = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.LIST_ITEM]: (node, children) => children,
          },
        })

        return (
          <Content>
            <Text size="body" as="li">
              {Li}
            </Text>
          </Content>
        )
      },
      [BLOCKS.QUOTE]: (node, children) => {
        const quote = documentToReactComponents(node, {})

        return (
          <Content>
            <Heading as="h5" size="h2">
              {quote}
            </Heading>
          </Content>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const { target } = node.data
        if (CARD_LAYOUTS.includes(target.__typename)) {
          if (target.__typename === CONTENT_TYPES.PROMOTION) {
            target["regions"] = target.region
          }
          return (
            <Box my={[1]}>
              <CardStripWrapper {...target} />
            </Box>
          )
        }
        return <Layout layout={[target]} />
      },
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        return <Layout layout={[node.data.target]} />
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return <Layout layout={[node.data.target]} />
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        if (isEmpty(node.data)) return null

        const text = documentToReactComponents(node.content[0])

        if (node?.data?.target) {
          const { linkProps } = getLinkProps(node.data.target)
          return (
            <Link {...linkProps} as="a">
              {text}
            </Link>
          )
        }
        return null
      },
    },
    renderText: text => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }
}

function RichTextRenderer({ node, color }) {
  const options = getOptions(color)
  return documentToReactComponents(node, options)
}

export default RichTextRenderer
