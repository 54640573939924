import React from "react"
import { graphql } from "gatsby"
import { mapperWithFunction } from "../common/utils"
import { Box } from "@singita/components"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const EMBED_MAP = {
  headline: ["headline"],
  description: ["description"],
  source: ["source"],
}

const TYPES = {
  VISREZ: "Visrez",
  SOUNDCLOUD: "Soundcloud",
}

const TYPE_MAP = {
  [TYPES.VISREZ]: {
    containerStyles: css`
      position: relative;
      overflow: hidden;
      padding-top: 56.25%;
    `,
    iframeStyles: css`
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    `,
  },
  [TYPES.SOUNDCLOUD]: {
    containerStyles: "",
    iframeStyles: "",
  },
}

const EmbedWrapper = props => {
  const embedProps = mapperWithFunction(props, EMBED_MAP)
  const { containerStyles = "", iframeStyles = "" } = TYPE_MAP[props.type] || {}

  const EmbedContainer = styled(Box)`
    ${containerStyles}
    width: 100%;
  `

  const StyledIframe = styled.iframe`
    ${iframeStyles}
    width: 100%;
    height: 100%;
  `

  return (
    <EmbedContainer className="EmbedContainer">
      <StyledIframe className="StyledIframe" src={embedProps.source} />
    </EmbedContainer>
  )
}

export default EmbedWrapper

export const embedFields = graphql`
  fragment EmbedFields on ContentfulEmbed {
    contentful_id
    headline
    source
    type
  }
`
