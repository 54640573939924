import React from "react"
import { Box } from "@singita/components"
import { graphql, Link } from "gatsby"
import { Card, Button } from "@singita/components"
import { mapperWithFunction } from "../common/utils"
import ImageWrapper from "./ImageWrapper"
import { useLanguage } from "gatsby-singita-theme/src/context/LanguageContext"

const DISPLAY_TYPES = {
  STANDARD_CARD: "Standard Card",
  STRIP_CARD: "Strip Card",
}

const CARD_VARIANT_MAP = {
  [DISPLAY_TYPES.STANDARD_CARD]: "lodge",
  [DISPLAY_TYPES.STRIP_CARD]: "strip",
}

const CARD_PROPS = {
  variant: value => {
    return value.display
      ? CARD_VARIANT_MAP[value.display]
      : CARD_VARIANT_MAP[DISPLAY_TYPES.STANDARD_CARD]
  },
  headline: ["headline"],
  subtitle: ({ region }) => {
    return `${region.headline}, ${region.country}`
  },
  image: value => {
    if (value.privateVillaContent && value.privateVillaContent.image) {
      return (
        <ImageWrapper
          image={value.privateVillaContent.image}
          role="presentation"
        />
      )
    } else {
      if (value.isStripCard) {
        return value.imageRef.image.fluid.src
      } else {
        return <ImageWrapper {...value.imageRef} role="presentation" />
      }
    }
  },
  size: () => "large",
  meta: value => {
    return value.isStripCard
      ? [
          {
            text: `${value.region.headline}, ${value.region.country}`,
            color: "textDark",
          },
        ]
      : null
  },
  renderCta: value => {
    return value.isStripCard
      ? () => (
          <Button variant="primary" size="small" colorScheme="brandBrown">
            Detailed Rates
          </Button>
        )
      : null
  },
  bg: value => {
    return value.isStripCard ? "bgLight" : null
  },
}

const LodgeCardWrapper = ({ section, slug, state, onClick, display }) => {
  const { sx, id } = section
  const lang = useLanguage() ?? "en-US"
  const isStripCard = display && display === DISPLAY_TYPES.STRIP_CARD
  const cardProps = mapperWithFunction(
    { ...section, isStripCard, display },
    CARD_PROPS
  )

  const link = lang === "en-US" ? slug : `/${lang}${slug}`

  return (
    <Box sx={{ ...sx, cursor: "pointer" }} onClick={onClick}>
      <Link to={link} state={state}>
        <Card
          key={id}
          isLink={Boolean(slug) || Boolean(onClick)}
          {...cardProps}
        />
      </Link>
    </Box>
  )
}

export default LodgeCardWrapper

export const LodgeCardFields = graphql`
  fragment LodgeCardFields on ContentfulLodge {
    id
    slug
    headline: fullTitle
    region {
      id
      headline
      country
    }
    imageRef {
      ...ImageFields
      image {
        fluid(quality: 85, maxWidth: 930, maxHeight: 1200) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`
