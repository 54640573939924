import styled from '@emotion/styled';
import { theme } from '@singita/components';
import { Box, Flex } from '@singita/components';

export const BackButton = styled(Flex)`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.textWhite};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MenuItemContainer = styled(Box)`
  transition: opacity ${theme.speed.default} ease;
  opacity: ${({ isHovering, isActive }) =>
    isHovering
      ? isActive
        ? theme.opacity.full
        : theme.opacity.feint
      : theme.opacity.full};
  cursor: pointer;
`;
