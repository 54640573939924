import { theme } from "@singita/components"

export const CONTENT_TYPES = {
  LODGE: "ContentfulLodge",
  CONSERVATION: "ContentfulConservationArea",
  IMAGE: "ContentfulImage",
  VIDEO: "ContentfulVideo",
  VIDEO_INLINE: "ContentfulVideoInline",
  CONSERVATION_PARTNER: "ContentfulConservationPartner",
  CARD_HERO: "ContentfulLayoutCardHero",
  CARD_TEXT: "ContentfulLayoutCardText",
  CARD_POSTER: "ContentfulLayoutCardPoster",
  PROMOTION: "ContentfulPromotions",
  PAGE: "ContentfulPages",
  PROJECT: "ContentfulProjects",
  CONTENT: "ContentfulContent",
  CONTENT_LODGES: "ContentfulContentLodges",
  SECTION_GRID: "ContentfulLayoutSectionGrid",
  LINKS: "ContentfulLinks",
  ARTICLE: "ContentfulArticles",
  MENU: "ContentfulMenu",
  HERO: "ContentfulLayoutHero",
  HERO_VIDEO: "ContentfulLayoutHeroVideo",
  EMBED: "ContentfulEmbed",
  PERSON: "ContentfulPerson",
  LAYOUT_CONTENT: "ContentfulLayoutContent",
  GALLERY: "ContentfulLayoutGallery",
  BANNER: "ContentfulLayoutBanner",
  SECTION_HEADING: "SectionHeading",
  ACTIONBAR: "ContentfulActionbar",
  PROJECTS_GRID: "ContentfulLayoutProjectsGrid",
  SECTION_LEFT_RIGHT: "ContentfulLayoutSectionLeftRight",
  ARCHIVE: "ContentfulLayoutArchive",
  FEATURED_ARTICLES: "ContentfulLayoutFeaturedArticles",
  SECTION_GRID_LODGES: "ContentfulLayoutSectionGridLodges",
  SECTION_GRID_LODGES_PRIVATE_VILLAS:
    "ContentfulLayoutSectionGridLodgesPrivateVillas",
  SECTION_GRID_LODGES_RATES: "ContentfulLayoutSectionGridLodgesRates",
  LIST: "ContentfulLayoutList",
  ALERT: "ContentfulAlert",
  TABS: "ContentfulLayoutTabs",
  BORDER: "ContentfulLayoutBorder",
  LINK: "ContentfulLinks",
  STRUCTURED_DATA: "ContentfulStructuredData",
  ASSET: "ContentfulAsset",
  SECTION: "ContentfulLayoutSection",
  ACTIONBAR_BOOKING: "ContentfulActionbarBookingForm",
  FORM: "ContentfulForm",
  REGION: "ContentfulRegion",
  PLAN_YOUR_TRIP: "ContentfulLayoutFormPlanYourTrip",
  CATEGORY: "ContentfulCategory",
  CONTENT_GALLERY: "ContentfulContentGallery",
  CONTENT_ASSETS: "ContentfulContentAssets",
  TABLE: "ContentfulTable",
  TWO_UP: "ContentfulLayout2UpImage",
  LINK_IMAGE: "ContentfulLayoutLinkImage",
  CAREER: "ContentfulCareer",
  VIDEO_THUMBNAIL: "ContentfulVideoThumbnail",
}

export const gridContainerStyles = {
  columnGap: `${theme.space[4]}px`,
  rowGap: `${theme.space[6]}px`,
  gridTemplateColumns: ["1fr", "1fr", "repeat(2, 1fr)", "repeat(3, 1fr)"],
  width: "100%",
}

export const defaultSocialLinks = [
  {
    name: "Facebook",
    icon: "facebook",
    href: "https://www.facebook.com/singita.gamereserves",
  },
  {
    name: "Twitter",
    icon: "twitter",
    href: "https://twitter.com/Singita_",
  },
  {
    name: "Pinterest",
    icon: "pinterest",
    href: "http://pinterest.com/singitareserves/",
  },
  {
    name: "Email",
    icon: "envelope",
    href: "mailto:enquiries@singita.com",
  },
  {
    name: "LinkedIn",
    icon: "linkedin",
    href: "https://www.linkedin.com/company/singita/",
  },
]
export const BUTTON_TYPES = {
  PRIMARY: "Primary",
  OUTLINE: "Outline",
  DOWNLOAD: "Download", //ToDo: Remove
  TEXT: "Text",
}

export const ALERT_TYPES = {
  POPUP: "Popup",
  BANNER: "Banner",
}

export const CONSTANTS = {
  global: {
    websiteName: "Singita",
  },
  contact: {
    email: {
      label: "E.",
      value: "enquiries@singita.com",
    },
    telephone: {
      label: "T.",
      value: "+27 (0) 21 683 3424",
    },
    link: {
      label: "View all contact information",
    },
  },
  social: {
    vimeo: "https://vimeo.com/singita",
    twitter: "https://twitter.com/Singita_",
    facebook: "https://www.facebook.com/singita.gamereserves/",
    instagram: "https://www.instagram.com/singita_/?hl=en",
    linkedin: "https://www.linkedin.com/company/singita/",
  },
  lodge: {
    links: {
      downloads: {
        siteplan: "Download lodge siteplan",
        brochure: "Download brochure",
        activitiesBrochure: "Activities",
        communityExperiences: "Community Experiences",
      },
      actionBar: {
        photos: "Photos & videos",
        menu: "Lodge menu",
        availability: "Check availability",
        info: "Lodge info",
        activities: "Activities",
        brochure: "Brochure",
        activitiesBrochure: "Activities Brochure",
        siteplan: "Lodge siteplan",
        promotions: "Exclusive Offers",
        covidStatement: "Covid-19 protocols",
        covidStatementUrl:
          "https://assets.ctfassets.net/wds1hqrprqxb/3MuVxlQ05igwB8avc67Sw0/9f2706359bf7a15286ea45f3b9a8e0ac/SINGITA_COVID-19-HYGIENE-PRACTICES_V14_May-2021.pdf",
      },
    },
  },
  button: {
    pageMenu: "Page menu",
  },
}

export const LODGE_DATA_MAP_SUBPAGES = {
  title: ({ fullTitle, region }) =>
    `${fullTitle} | ${region.headline}, ${region.country}`,
  image: ["image", "fluid", "src"],
  altText: ["fullTitle"],
}

export const LODGE_DATA_MAP = {
  ...LODGE_DATA_MAP_SUBPAGES,
  description: ["shortDescription", "shortDescription"],
}

export const isBrowser = typeof window !== "undefined"
export const ALERT_STATE_KEY_PREFIX = "singita:showAlert"
