import React from "react"
import { Link, graphql } from "gatsby"

import { Box } from "@singita/components"
import ImageWrapper from "./ImageWrapper"
import { getLinkProps, getTargetProps } from "../common/utils"

const LinkImageWrapper = props => {
  const { image, asset, link, linksToImage, containerSx, target } = props

  /* Not worth rendering if there is no image */
  if (!image && !asset) {
    return null
  }

  const url = linksToImage
    ? image
      ? image.image.fluid.src
      : asset.file.url
    : null

  const linkProps = url
    ? { to: url, ...getTargetProps(target) }
    : link
    ? getLinkProps(link).linkProps
    : {}

  const imageProps = image ? image : asset

  return (
    <Box sx={containerSx}>
      <Link {...linkProps}>
        <ImageWrapper {...imageProps} />
      </Link>
    </Box>
  )
}

export default LinkImageWrapper

export const linkImageFields = graphql`
  fragment LinkImageFields on ContentfulLayoutLinkImage {
    contentful_id
    name
    linksToImage
    link {
      ...LinkFields
    }
    image {
      ...ImageFields
      image {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    asset {
      ...AssetFields
    }
    target
  }
`
