import React from "react"
import { Box } from "@singita/components"
import { Card, Button } from "@singita/components"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import ImageWrapper from "./ImageWrapper"

const ConservationCardWrapper = ({ section, slug }) => {
  const {
    id,
    sx,
    name,
    shortDescription: { description } = {},
    image,
  } = section

  return (
    <Box as={Link} to={slug} sx={{ sx }}>
      <Card
        key={id}
        variant="standard"
        isLink={Boolean(slug)}
        headline={name}
        description={description}
        image={<ImageWrapper image={image} />}
        renderCta={() => (
          <Button
            iconPosition={"right"}
            icon={"chevron-right"}
            variant={"text"}
          >
            Discover more
          </Button>
        )}
      />
    </Box>
  )
}

export default ConservationCardWrapper

export const ConservationAreaFields = graphql`
  fragment ConservationAreaFields on ContentfulConservationArea {
    id
    name
    shortDescription {
      description: shortDescription
    }
    image {
      fluid(quality: 90, maxWidth: 620, maxHeight: 800) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    connectedEntry {
      slug
    }
  }
`
