import React from "react"

import { Number } from "@singita/components"
import { Box } from "@singita/components"

const Guests = ({ form, setGuests }) => {
  return (
    <Box>
      <Number
        key="adults"
        value={form.guests["adults"]}
        label="Adults"
        onIncrement={() => setGuests(1, "adults")}
        onDecrement={() => setGuests(-1, "adults")}
        orientation="right"
        min={1}
      />
    </Box>
  )
}

export default Guests
