import { MenuLink, theme, Flex } from '@singita/components';
import Link from '../ConditionalLink';
import React, { useContext, useState } from 'react';
import { NAVIGATION_TYPES, mainMenuSpvc, mainMenu } from './constants';
import { ResourceSetContext } from '../../context/ResourceSetContext';
import { FadeIn, StaggerContainer, StaggerItem } from './animations';
import MenuSocial from './MenuSocial';
import { Box } from 'rebass';

const MenuPrimary = ({
  setMenu,
  isOpen,
  setIsOpen,
  currentPath,
  menu = null,
  linkColor = 'textWhite',
  size = 'menu',
  renderAfterPrimary,
  menuType,
}) => {
  const context = useContext(ResourceSetContext);
  const [hover, setHover] = useState({
    isHover: false,
    link: -1,
  });

  const getMenuType = (menuType) => {
    if (menuType === 'spvc') {
      return mainMenuSpvc;
    } else {
      return mainMenu();
    }
  };

  const menuItems = menu || getMenuType(menuType);
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      sx={{ height: ['auto', '100%'] }}
    >
      <StaggerContainer pb={[3, 6]} id="menuPrimary" shouldAnimate={isOpen}>
        {menuItems.map(({ label, to, type, ...rest }, i) => (
          <StaggerItem
            key={`mainMenu-${label}`}
            isHovering={hover.isHovering}
            isActive={hover.link === i}
            onMouseEnter={() => {
              setHover({
                isHover: true,
                link: i,
              });
            }}
            onMouseLeave={() => {
              setHover({
                isHover: true,
                link: -1,
              });
            }}
          >
            {type === 'Space' ? (
              <Box mb={[4, 5]} />
            ) : (
              <MenuLink
                color={linkColor}
                size={size}
                onClick={() => {
                  if (type === NAVIGATION_TYPES.MENU) {
                    setMenu(to);
                  }
                  if (currentPath === to) {
                    setIsOpen(false);
                  }
                }}
                sx={{ pb: [2] }}
                {...(type === NAVIGATION_TYPES.LINK ? { as: Link, to } : {})}
                {...rest}
              >
                {label}
              </MenuLink>
            )}
          </StaggerItem>
        ))}
        {renderAfterPrimary ? renderAfterPrimary() : null}
      </StaggerContainer>
      <FadeIn
        width={[4 / 12]}
        alignItems={['flex-start']}
        data-testid="social"
        sx={{ display: ['none', 'none', 'none', 'flex'] }}
        shouldAnimate={isOpen}
        delay={2}
      >
        <MenuSocial
          resources={context.getResourcesForComponent('social')}
          color={theme.colors[linkColor]}
          opacity={theme.opacity.full}
          linkPrefix="MenuPrimary"
        />
      </FadeIn>
    </Flex>
  );
};

export default MenuPrimary;
