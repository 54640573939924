import React from "react"
import { Border } from "@singita/components"
import { Box } from "@singita/components"
import { graphql } from "gatsby"

const BorderWrapper = ({ styles, props, ...rest }) => {
  return (
    <Box {...styles} {...rest}>
      <Border
        borderColor={styles.borderColor ? styles.borderColor : "borderDark"}
      />
    </Box>
  )
}

export default BorderWrapper

export const BorderFields = graphql`
  fragment BorderFields on ContentfulLayoutBorder {
    id
    contentful_id
    name
    styles {
      mt
      mb
      borderColor
    }
  }
`
