import React, { useCallback } from "react"
import { Box, Flex } from "@singita/components"

import { Container, Section, GridContainer, Heading } from "@singita/components"
import FilterWrapper from "../modules/FilterWrapper"
import LodgeCardWrapper from "../modules/LodgeCardWrapper"
import ConservationCardWrapper from "../modules/ConservationCardWrapper"
import PromotionCardWrapper from "../modules/PromotionCardWrapper"
import GalleryCardWrapper from "../modules/GalleryCardWrapper"
import CardWrapper from "../modules/CardWrapper"
import CardTextWrapper from "../modules/CardTextWrapper"
import { gridContainerStyles, CONTENT_TYPES } from "../common/constants"
import GridItem from "./GridItem"

const FILTER_POSITIONS = {
  LEFT: "Left",
  TOP: "Top",
  NONE: "None",
}

export const LINK_TYPES = {
  LODGE: "Lodge",
  LODGE_GALLERY: "Lodge Gallery",
  SMALL_MODAL: "Small Modal",
}

const LINK_MAP = {
  [LINK_TYPES.LODGE]: slug => ({
    slug: `/lodge/${slug}`,
  }),
  [LINK_TYPES.LODGE_GALLERY]: slug => ({
    slug: `/lodge/${slug}/gallery`,
    state: { isModal: true },
  }),
}

const SLUG_MAP = {
  [CONTENT_TYPES.LODGE]: value => `/lodge/${value.slug}`,
  [CONTENT_TYPES.CONSERVATION]: value => `/${value.connectedEntry.slug}`,
  [CONTENT_TYPES.PROMOTION]: value => `/promotion/${value.slug}`,
  [CONTENT_TYPES.CONTENT]: () => "",
  [CONTENT_TYPES.PERSON]: () => "",
  [CONTENT_TYPES.CARD_TEXT]: () => "",
}

const CARD_MAP = {
  [CONTENT_TYPES.LODGE]: LodgeCardWrapper,
  [CONTENT_TYPES.CONSERVATION]: ConservationCardWrapper,
  [CONTENT_TYPES.PROMOTION]: PromotionCardWrapper,
  [CONTENT_TYPES.CONTENT]: GalleryCardWrapper,
  [CONTENT_TYPES.PERSON]: CardWrapper,
  [CONTENT_TYPES.CARD_TEXT]: CardTextWrapper,
}

const Wrapper = ({ filterPosition, isFilterLeft, children }) => {
  return filterPosition ? (
    <Flex
      flexDirection={
        isFilterLeft ? ["column", "column", "column", "row"] : "column"
      }
      alignItems="flex-start"
    >
      {children}
    </Flex>
  ) : (
    children
  )
}

const SectionGrid = props => {
  const {
    headline,
    headlineLineTwo,
    description,
    direction,
    filter,
    isSearchable,
    allItems,
    filteredItems,
    setFilteredItems,
    filterPosition,
    filterHeading,
    styles,
    linkTo,
    modalCallback,
    display,
    renderSpecialItems,
  } = props
  const isFilterLeft = filterPosition === FILTER_POSITIONS.LEFT

  const onSetFilter = useCallback(payload => setFilteredItems(payload), [
    setFilteredItems,
  ])

  return (
    <Container>
      <Section
        headline={headline}
        headlineLineTwo={headlineLineTwo}
        description={description}
        direction={direction}
        textContainerSx={{ pr: [2] }}
      >
        <Wrapper filterPosition={filterPosition} isFilterLeft={isFilterLeft}>
          {filter || isSearchable ? (
            <Box
              width={["100%", "100%", "100%", "auto"]}
              mb={[5]}
              mr={[isFilterLeft ? 10 : 0]}
              sx={{
                flexShrink: 0,
                position: [
                  "static",
                  "static",
                  "static",
                  isFilterLeft ? "sticky" : "static",
                ],
                top: "85px",
                bottom: 0,
              }}
            >
              {filterHeading && (
                <Box mb={[3]}>
                  <Heading size="h6" color="textDark" fontWeight="bold">
                    {filterHeading}
                  </Heading>
                </Box>
              )}
              <FilterWrapper
                items={allItems}
                filter={filter}
                onSetFilter={onSetFilter}
                isVertical={isFilterLeft}
                isSearchable={isSearchable}
              />
            </Box>
          ) : null}
          {filteredItems.length > 0 ? (
            <GridContainer sx={{ ...gridContainerStyles, ...styles }}>
              {renderSpecialItems && renderSpecialItems()}
              {filteredItems.map((item, idx) => {
                const Component = CARD_MAP[item.__typename]
                if (!Component) return null

                let linkProps
                if (linkTo) {
                  if (linkTo === LINK_TYPES.SMALL_MODAL) {
                    linkProps = {
                      onClick: () => modalCallback(item),
                    }
                  } else {
                    linkProps = LINK_MAP[linkTo](item.slug)
                  }
                } else {
                  linkProps = {
                    slug: SLUG_MAP[item.__typename](item),
                  }
                }

                return (
                  <GridItem
                    key={item.id}
                    index={idx}
                    columns={styles?.columns || [1, 1, 2, 3]}
                    hasSpecialItem={Boolean(renderSpecialItems)}
                  >
                    <Component
                      display={display}
                      section={item}
                      {...linkProps}
                    />
                  </GridItem>
                )
              })}
            </GridContainer>
          ) : (
            <Heading size="h4" color="textDark">
              No results found.
            </Heading>
          )}
        </Wrapper>
      </Section>
    </Container>
  )
}

export default SectionGrid
