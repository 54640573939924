import { useCallback, useEffect, useState } from 'react';
import { breakpoints } from '@singita/components';
import { isBrowser } from '../../../singita-web/src/common/constants';

const useMobile = (breakpoint = 'md') => {
  const [isMobile, setIsMobile] = useState(false);

  const checkWidth = useCallback(() => {
    if (isBrowser) {
      if (window.innerWidth < breakpoints[breakpoint]) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, [breakpoint, setIsMobile]);

  useEffect(() => {
    window.addEventListener('resize', checkWidth);
    return () => window.removeEventListener('resize', checkWidth);
  });

  useEffect(() => {
    checkWidth();
  }, [checkWidth]);

  return isMobile;
};

export default useMobile;
