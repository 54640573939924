import React from "react"
import { gql, useQuery } from "@apollo/client"

import { graphql, Link } from "gatsby"
import { Container, Card } from "@singita/components"
import { getFluidGatsbyImage } from "gatsby-singita-theme/src/common/image"
import ImageWrapper from "./ImageWrapper"
import { generateBlogSlug } from "../common/utils"
import moment from "moment"

const FEATURED_QUERY = gql`
  query($tags: [String!]!, $count: Int!) {
    articlesCollection(
      limit: $count
      order: publishDate_DESC
      where: { tag_contains_some: $tags }
    ) {
      items {
        headline
        slug
        description
        publishDate
        previewImage {
          url
          contentType
          width
          height
        }

        categoriesCollection {
          items {
            headline
          }
        }
      }
    }
  }
`

const FeaturedArticlesWrapper = props => {
  const { articleTags: tags, articleCount: count } = props
  const { loading, error, data } = useQuery(FEATURED_QUERY, {
    variables: { tags: tags || ["Featured"], count: count || 1 },
  })

  if (loading) return null

  return (
    <Container py={[7, 7, 7, 15]}>
      {data.articlesCollection.items.map(item => {
        const dateObj = moment(item.publishDate)

        return (
          <Link
            to={generateBlogSlug(
              item.slug,
              dateObj.format("YYYY"),
              dateObj.format("MM")
            )}
          >
            <Card
              variant="featured"
              isLink={true}
              highlight="Featured"
              headline={item.headline}
              description={item.description}
              image={
                <ImageWrapper
                  image={{
                    fluid: getFluidGatsbyImage(item.previewImage, {
                      maxWidth: 900,
                      quality: 85,
                    }),
                  }}
                />
              }
              meta={[
                ...(item.categoriesCollection &&
                item.categoriesCollection.items &&
                item.categoriesCollection.items.length > 0
                  ? [
                      {
                        text: item.categoriesCollection.items[0].headline,
                        color: "brandBrown",
                      },
                    ]
                  : []),
                {
                  text: `${dateObj.format("MMMM")} ${dateObj.format("YYYY")}`,
                  color: "textDark",
                },
              ]}
            />
          </Link>
        )
      })}
    </Container>
  )
}

export default FeaturedArticlesWrapper

export const featuredFields = graphql`
  fragment FeaturedArticlesFields on ContentfulLayoutFeaturedArticles {
    id
    articleTags
    articleCount
  }
`
