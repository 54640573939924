import { gql } from "@apollo/client"

export const FORM_QUERY = gql`
  query getForm($id: String!) {
    viewForm(id: $id) {
      id
      title
      description
      buttonText
      buttonClass
      fields {
        label
        placeholder
        description
        id
        fieldId
        name
        type
        className
        required
        validate
        options {
          label
          value
        }
        initialValue
        styles
        variant
        size
        bg
        disabled
        min
        max
        orientation
        errorMessage
        group {
          type
          label
          isSpaced
          value
        }
      }
    }
  }
`
export const LODGE_QUERY = gql`
  {
    findLodges {
      name
      code
    }
  }
`
