import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { GridContainer, Text, Box, theme } from "@singita/components"
import { isEmpty } from "lodash"

const Cell = styled(Box)`
  border-bottom: 1px solid ${theme.colors.baseGrayLight};
  border-right: 1px solid ${theme.colors.baseGrayLight};
  padding: ${theme.space[2]}px;
  -ms-grid-column: ${({ index, cols }) => (index % cols) + 1};
  -ms-grid-row: ${({ index, cols }) => Math.floor(index / cols) + 1};
`

const TableWrapper = props => {
  const { data } = props
  const content = data?.internal?.content || data
  if (!content || isEmpty(content)) return null

  const { columns, rows, tableConfig } =
    typeof content === "string" ? JSON.parse(content) : content

  if (!columns || !rows || columns.length === 0 || rows.length === 0) {
    return null
  }

  const gridStyles = {
    gridTemplateColumns: [
      `repeat(${columns.length}, minmax(150px, 250px))`,
      `repeat(${columns.length}, 1fr)`,
    ],
    gridTemplateRows: `repeat(${rows.length}, auto)`,
    overflowX: "auto",
  }

  let cellIdx = 0

  return (
    <GridContainer
      sx={{
        ...gridStyles,
        borderTop: `1px solid ${theme.colors.baseGrayLight}`,
        borderLeft: `1px solid ${theme.colors.baseGrayLight}`,
      }}
    >
      {!tableConfig.showHeading &&
        columns.map(({ label }) => (
          <Cell index={cellIdx++} cols={columns.length}>
            <Text size="body" fontWeight="bold">
              {label}
            </Text>
          </Cell>
        ))}
      {rows.map(row =>
        columns.map(({ key }) => (
          <Cell index={cellIdx++} cols={columns.length}>
            <Text size="body">{row[key]}</Text>
          </Cell>
        ))
      )}
    </GridContainer>
  )
}

export default TableWrapper

export const tableFields = graphql`
  fragment TableFields on ContentfulTable {
    contentful_id
    data {
      internal {
        content
      }
    }
  }
`
