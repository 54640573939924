import React, {
  useContext,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Header, Button, theme, Flex, Box } from '@singita/components';
import { LodgeContext } from '../context/LodgeContext';
import { NavbarContext } from '../context/NavbarContext';
import { homeLink } from '../common/utils';
import useLockScroll from '../hooks/useLockScroll';
import SearchWrapper from '../modules/SearchWrapper';
import Menu from '../modules/MenuWrapper';
import AvailabilityContext from '../context/AvailabilityContext';
import { LocaleLink as Link } from 'gatsby-singita-theme/src/components/LocaleLink';
import { Translation } from 'react-i18next';

const Navbar = ({
  currentPath,
  ctaLabel,
  showSearchButton,
  showPlanYourTripProp,
  menuBg,
  menuType,
  children,
}) => {
  const [showMenu, setMenu] = useState(false);
  const [showSearch, setSearch] = useState(false);

  const {
    lodgeData: { lodgeColor },
  } = useContext(LodgeContext);
  const { navbarStyles, setNavbarHeight, setNavbarStyles } = useContext(
    NavbarContext,
  );
  const { setShowAvailability, showPlanYourTrip } = useContext(
    AvailabilityContext,
  );

  const headerRef = useRef();

  useLockScroll(showMenu || showSearch);

  useLayoutEffect(() => {
    if (headerRef && headerRef.current) {
      const { height } = headerRef.current.getBoundingClientRect();
      setNavbarHeight(height);
    }
  }, [setNavbarHeight]);

  const navbarStylesRef = useRef(navbarStyles);
  const navbarStylesPrev = useRef(navbarStyles);
  const showMenuPrev = useRef(showMenu);
  const showSearchPrev = useRef(showSearch);

  useEffect(() => {
    if (showMenu || showSearch) {
      setNavbarStyles({ logoColor: theme.colors.baseWhite });
      showMenuPrev.current = showMenu;
      showSearchPrev.current = showSearch;
      return;
    }

    if (
      (!showMenu && showMenuPrev.current) ||
      (!showSearch && showSearchPrev.current)
    ) {
      setTimeout(() => setNavbarStyles(navbarStylesPrev.current), 0.3);
    }

    showMenuPrev.current = showMenu;
    showSearchPrev.current = showSearch;
  }, [showMenu, showSearch, setNavbarStyles]);

  useEffect(() => {
    if (Object.keys(navbarStyles).length > 0) {
      navbarStylesPrev.current = { ...navbarStylesRef.current };
      navbarStylesRef.current = navbarStyles;
    }
  }, [navbarStyles]);

  const CtaButtonStatic = ({ to, ctaLabel }) => {
    return (
      <Button
        variant="primary"
        colorScheme={lodgeColor || 'brandBrown'}
        mr={[2]}
        sx={{ display: ['none', 'block'] }}
        as={Link}
        to={to}
      >
        {ctaLabel}
      </Button>
    );
  };

  return (
    <Box
      data-testid="navbar"
      sx={{
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: theme.zIndex.navbar,
      }}
    >
      <Header
        homeLink={homeLink}
        ref={headerRef}
        bg={navbarStyles?.bg || theme.colors.transparent}
        logoColor={navbarStyles?.logoColor || theme.colors.brandBrown}
        secondaryButtonColor={
          navbarStyles?.secondaryButtonColor || theme.colors.textWhite
        }
        sx={{
          ...(navbarStyles.opacity ? { opacity: navbarStyles.opacity } : {}),
        }}
        renderButton={(color) => (
          <Flex alignItems="center">
            {!(showSearch || showMenu) ? (
              !showPlanYourTrip ? (
                <CtaButtonStatic
                  to={'/contact'}
                  ctaLabel={
                    <Translation>{(t) => t('navbar.contact')}</Translation>
                  }
                />
              ) : showPlanYourTripProp ? (
                <Button
                  variant="primary"
                  colorScheme={lodgeColor || 'brandBrown'}
                  mr={[2]}
                  sx={{ display: ['none', 'block'] }}
                  onClick={() => setShowAvailability(true)}
                >
                  {ctaLabel}
                </Button>
              ) : (
                <CtaButtonStatic
                  to={'/contact'}
                  ctaLabel={
                    <Translation>{(t) => t('navbar.contact')}</Translation>
                  }
                />
              )
            ) : null}
            {showSearchButton && (
              <Button
                size="default"
                variant="text"
                colorScheme={color}
                iconPosition={showSearch ? 'right' : 'left'}
                icon={'search'}
                mr={[2]}
                onClick={() => setSearch(!showSearch)}
                selectedIcon="close"
                isSelected={showSearch}
              >
                {showSearch ? (
                  <Translation>{(t) => t('navbar.close-search')}</Translation>
                ) : (
                  <Translation>{(t) => t('navbar.search')}</Translation>
                )}
              </Button>
            )}
            {!showSearch && (
              <Button
                size="default"
                variant="text"
                colorScheme={color}
                iconPosition="right"
                icon={showMenu ? 'close' : 'menu'}
                onClick={() => setMenu(!showMenu)}
              >
                {showMenu ? (
                  <Translation>{(t) => t('Close')}</Translation>
                ) : (
                  <Translation>{(t) => t('navbar.menu')}</Translation>
                )}
              </Button>
            )}
            {children}
          </Flex>
        )}
      />
      <Box
        bg={theme.colors.brandBrown}
        sx={{
          position: 'fixed',
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          transform: showMenu ? `translateY(0)` : `translateY(-100%)`,
          opacity: showMenu ? 1 : 0,
          transition: '0.8s',
          overflowY: 'scroll',
        }}
      >
        {showMenu && (
          <Menu
            menuBg={menuBg}
            menuType={menuType}
            isOpen={showMenu}
            setIsOpen={setMenu}
            currentPath={currentPath}
          />
        )}
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          transform: showSearch ? `translateY(0)` : `translateY(-100%)`,
          opacity: showSearch ? 1 : 0,
          transition: '0.8s',
          overflowY: showSearch ? 'scroll' : 'hidden',
        }}
      >
        {showSearch && <SearchWrapper isOpen={showSearch} />}
      </Box>
    </Box>
  );
};
Navbar.defaultProps = {
  showSearchButton: true,
  ctaLabel: 'Plan your trip',
  showPlanYourTripProp: true,
};

Navbar.propTypes = {
  /** Should the Search button be shown? */
  showSearchButton: PropTypes.bool,
  /** What is the CTA label? */
  ctaLabel: PropTypes.string,
  /** Should the Plan your Trip modal and availibility checker be availible? */
  showPlanYourTripProp: PropTypes.bool,
};

export default Navbar;
