import React, { useState, createContext } from "react"

export const LodgeContext = createContext({
  lodgeData: {},
  setLodgeData: () => {},
})

export const LodgeContextProvider = ({ children }) => {
  const [lodgeData, setData] = useState({
    lodgeColor: null,
  })

  function setLodgeData(lodgeColor) {
    setData({ lodgeColor })
  }

  return (
    <LodgeContext.Provider value={{ lodgeData, setLodgeData }}>
      {children}
    </LodgeContext.Provider>
  )
}
