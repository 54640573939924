import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { ResourceSetContext } from '../context/ResourceSetContext';
import { TEMPLATE_TYPES } from '../common/constants';
import { useLanguage } from '../context/LanguageContext';

const HelmetWrapper = ({
  title,
  description,
  image,
  altText,
  url,
  schemaJsonLd,
  noIndex,
  noFollow
}) => {
  const context = useContext(ResourceSetContext);
  const globalRS = context.getResourcesForComponent('global');
  const lang = useLanguage();

  return (
    <Helmet
      defaultTitle={
        title ? title : globalRS.websiteName
      }
      // Set language based on language of page
      htmlAttributes={{ lang: lang ?? 'en-US' }}
    >
      {description && <meta property="description" content={description} name="description" />}
      {lang === 'en-US' ? (
        // Only show on English pages
        <link rel="canonical" href={url} />
      ) : (
        /* Add noindex to translated pages */
        <meta name="robots" content="noindex" />
      )}
      <meta name="google-site-verification" content="XQZqnHjyspW4j6ulYMxd0-M3QTRt7vVcm4woeQY2ypo" />
      {noIndex && lang === 'en-US' && <meta name="robots" content="noindex" />}
      {noFollow && lang === 'en-US' && <meta name="robots" content="nofollow" />}

      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
      <meta
        property="og:title"
        content={
          title ? title : globalRS.websiteName
        }
      />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={globalRS.projectType} />
      <meta property="og:site_name" content={globalRS.websiteName} />
      <meta property="og:image" itemprop="image" content={image} />
      <meta property="og:image:alt" content={altText} />
      {description && <meta property="og:description" content={description} />}
      <meta property="twitter:image" name="twitter:image" content={`https:${image}`}/>
      <meta property="twitter:site" name="twitter:site" content={globalRS.websiteName} />
      <meta property="twitter:title" name="twitter:title" content={ title ? title : globalRS.websiteName }  />
      {description &&  <meta property="twitter:description" name="twitter:description" content={description}/>}
      <meta name="twitter:card" content="summary_large_image" />

      {schemaJsonLd && (
        <script type="application/ld+json">
          {JSON.stringify(schemaJsonLd)}
        </script>
      )}
    </Helmet>
  );
};
export default HelmetWrapper;

export const getJsonLd = (seoProps, template) => {
  switch (template) {
    case TEMPLATE_TYPES.HOME: {
      return {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        '@id': '',
        isPartOf: {
          '@type': 'WebSite',
          url: seoProps.websiteURL,
          '@id': '/#this-site',
          publisher: { '@id': '/#this-org' },
        },
        publisher: {
          '@type': 'Organization',
          '@id': '/#this-org',
          name: seoProps.websiteName,
          legalName: seoProps.websiteName,
          url: seoProps.websiteURL,
          image: seoProps.image,
          logo: seoProps.logo,
          telephone: seoProps.telephone,
          email: seoProps.email,
          foundingDate: seoProps.foundingDate,
          founders: [
            {
              '@type': 'Organization',
              name: seoProps.websiteName,
            },
          ],
          address: [
            {
              '@type': 'PostalAddress',
              streetAddress: seoProps.streetAddress,
              addressLocality: seoProps.addressLocality,
              addressRegion: seoProps.addressRegion,
              postalCode: seoProps.postalCode,
              addressCountry: seoProps.addressCountry,
            },
          ],
          contactPoint: {
            '@type': 'ContactPoint',
            contactType: 'customer support',
            telephone: seoProps.telephone,
            email: seoProps.email,
          },
          sameAs: [
            seoProps.facebook.url,
            seoProps.instagram.url,
            seoProps.linkedin.url,
            seoProps.youtube.url,
            seoProps.wikipedia.url,
            seoProps.twitter.url,
            seoProps.vimeo.url,
          ],
        },
      };
    }

    case TEMPLATE_TYPES.PAGE: {
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Home',
            item: seoProps.origin,
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: seoProps.menuLabel,
            item: seoProps.url,
          },
        ],
      };
    }

    case TEMPLATE_TYPES.ARTICLE: {
      return {
        '@context': 'http://schema.org',
        '@type': 'NewsArticle',
        '@id': '',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': seoProps.url,
        },
        headline: seoProps.title,
        image: [seoProps.image],
        datePublished: seoProps.publishDate,
        author: {
          '@type': 'Person',
          name: seoProps.author,
        },
        keywords: seoProps.categories,
        breadcrumb: {
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'Home',
              item: seoProps.origin,
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: seoProps.type || 'Articles',
              item: `${seoProps.origin}/${seoProps.breadcrumb}`,
            },
            {
              '@type': 'ListItem',
              position: 3,
              name: seoProps.menuLabel,
              item: seoProps.url,
            },
          ],
        },
      };
    }

    case TEMPLATE_TYPES.ARTICLE_ARCHIVE: {
      return {
        '@context': 'http://schema.org',
        '@type': 'NewsArticle',
        '@id': '',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': seoProps.url,
        },
        headline: seoProps.title,
        image: [seoProps.image],
        datePublished: seoProps.publishDate,
        author: {
          '@type': 'Person',
          name: seoProps.author,
        },
        keywords: seoProps.categories,
        breadcrumb: {
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'Home',
              item: seoProps.origin,
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: 'Articles',
              item: `${seoProps.origin}/archive`,
            },
            {
              '@type': 'ListItem',
              position: 3,
              name: seoProps.menuLabel,
              item: seoProps.url,
            },
          ],
        },
      };
    }

    case TEMPLATE_TYPES.PROJECT: {
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Home',
            item: seoProps.origin,
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: 'Conservation',
            item: `${seoProps.origin}/conservation`,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: seoProps.area,
            item: `${seoProps.origin}/${seoProps.areaSlug}`,
          },
          {
            '@type': 'ListItem',
            position: 4,
            name: seoProps.menuLabel,
            item: seoProps.url,
          },
        ],
      };
    }

    case TEMPLATE_TYPES.PROMOTION: {
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Home',
            item: seoProps.origin,
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: 'Promotions',
            item: `${seoProps.origin}/promotions`,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: seoProps.menuLabel,
            item: seoProps.url,
          },
        ],
      };
    }

    case TEMPLATE_TYPES.LODGE: {
      return {
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        '@graph': [
          {
            '@type': 'Product',
            '@id': seoProps.url,
            name: seoProps.title,
            image: [seoProps.image],
            description: seoProps.description,
            brand: {
              '@type': 'Brand',
              name: 'Singita',
            },
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: seoProps.meta?.ratingValue,
              reviewCount: seoProps.meta?.reviewCount,
            },
          },
          {
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: seoProps.origin,
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Lodges',
                item: `${seoProps.origin}/lodges`,
              },
              {
                '@type': 'ListItem',
                position: 3,
                name: seoProps.title,
                item: seoProps.url,
              },
            ],
          },
        ],
      };
    }

    case TEMPLATE_TYPES.LODGE_INFO: {
      return {
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        '@graph': [
          {
            '@type': 'Hotel',
            '@id': seoProps.url,
            name: seoProps.title,
            image: [seoProps.image],
            description: seoProps.description,
            address: {
              '@type': 'PostalAddress',
              streetAddress: seoProps.meta?.streetAddress,
              addressLocality: seoProps.meta?.addressLocality,
              addressRegion: seoProps.meta?.addressRegion,
              postalCode: seoProps.meta?.postalCode,
              addressCountry: seoProps.meta?.addressCountry,
            },
            geo: {
              '@type': 'GeoCoordinates',
              latitude: seoProps.meta?.latitude,
              longitude: seoProps.meta?.longitude,
            },
            url: seoProps.url,
            telephone: seoProps.meta?.telephone,
          },
          {
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: seoProps.origin,
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Lodges',
                item: `${seoProps.origin}/lodges`,
              },
              {
                '@type': 'ListItem',
                position: 3,
                name: seoProps.title,
                item: seoProps.url,
              },
            ],
          },
        ],
      };
    }

    case TEMPLATE_TYPES.VIDEO: {
      return {
        '@context': 'https://schema.org/',
        '@type': 'VideoObject',
        "name":seoProps.title || seoProps.headline,
        "description":seoProps.description || seoProps.caption,
        "creator": seoProps.creator,
        "uploadDate": new Date(seoProps.uploadDate).toISOString(),
        "embedUrl": seoProps.previewUrl,
        "thumbnailUrl": seoProps.thumbnailUrl,
        "width": seoProps.width,
        "height": seoProps.height
      };
    }

    case TEMPLATE_TYPES.AUTHOR: {
      return {
        '@context': 'http://schema.org',
        "@type": "Person",
        "@id":`${seoProps.url}#Person`,
        "name": seoProps.name, 
        "description": seoProps.description, 
        "url": seoProps.url, 
        "jobTitle": seoProps.role,
        "image":[
          {
              "@type": "ImageObject",
              "url": seoProps.image,
          }
        ],
      };
    }

    case TEMPLATE_TYPES.EXPERIENCE: {
      return {
        "@context": "https://schema.org/",
        "@type": "Thing",
        "name": seoProps.title,
        "description": seoProps.description,
        "location": seoProps.location
      } 
    }

    case TEMPLATE_TYPES.GALLERY: {
      return {
        '@context': 'http://schema.org',
        "@type": "ImageGallery",
        "name": seoProps.title, 
        "location":seoProps.description,
        "image":seoProps.image,
        "caption":seoProps.altText,
      };
    }

    // no default
  }
};
