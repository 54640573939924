import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { Box } from "@singita/components"
import { Heading, Content as ContentStyles } from "@singita/components"
import RichTextRenderer from "../components/RichTextRenderer"

const ContentWrapper = props => {
  if (!props.meta) {
    return (
      <>
        {props.subtitle && (
          <Box mb={[4]}>
            <Heading size="h5" color="textDark">
              {props.subtitle}
            </Heading>
          </Box>
        )}
        {props.headline && (
          <Heading as="h3" size="h1" mb={[4, 8]}>
            {props.headline}
          </Heading>
        )}
        {props.content && props.content && (
          <ContentStyles>
            <RichTextRenderer content={props.content} />
          </ContentStyles>
        )}
      </>
    )
  }
  return (
    <Layout
      layout={[
        {
          ...props,
          __typename: props.meta ? props.meta.component : "Content",
        },
      ]}
    />
  )
}

export default ContentWrapper

export const Content = graphql`
  fragment Content on ContentfulContent {
    contentful_id
    id
    name
    headline
    content {
      raw
      references {
        ...RichTextReferences
      }
    }
    meta {
      component
    }
    __typename
  }
`

export const contentLinkFields = graphql`
  fragment ContentFields on ContentfulContent {
    id
    contentful_id
    headline
    subtitle
    content {
      raw
      references {
        __typename
        ...RichTextReferences
      }
    }
  }
`
