import React, { useContext } from "react"
import { Hero, theme } from "@singita/components"
import { graphql } from "gatsby"
import { useSetNavbarStylesOnPresence } from "gatsby-singita-theme/src/hooks"
import { LodgeContext } from "gatsby-singita-theme/src/context/LodgeContext"
import ImageWrapper from "./ImageWrapper"

const HeroWrapper = ({
  imageRef,
  headline,
  heroDescription,
  subtitle,
  showPattern,
  patternProps,
  colorScheme,
  styles,
}) => {
  const heroRef = useSetNavbarStylesOnPresence(0, "-50px 0px 0px 0px", {
    logoColor: theme.colors.textWhite,
    secondaryButtonColor: theme.colors.textWhite,
  })

  const {
    lodgeData: { lodgeColor },
  } = useContext(LodgeContext)

  return (
    <Hero
      data-testid="hero-wrapper"
      ref={heroRef}
      headline={headline}
      description={heroDescription}
      caption={subtitle}
      image={<ImageWrapper {...imageRef} />}
      showPattern={showPattern}
      patternProps={{
        ...patternProps,
        ...(lodgeColor ? { color: lodgeColor } : {}),
      }}
      colorScheme={lodgeColor ?? colorScheme}
      {...styles}
      captionSx={{ textTransform: "none"}}
      headlineSx={{ textAlign: "center" }}
    />
  )
}

export default HeroWrapper

export const HeroFields = graphql`
  fragment HeroFields on ContentfulLayoutHero {
    id
    headline
    heroDescription: description
    images {
      fluid(maxWidth: 1900, quality: 95) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    imageRef {
      ...ImageFields
      image {
        fluid(maxWidth: 1900, quality: 95) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    subtitle
    showPattern
    styles {
      mb
    }
  }
`
