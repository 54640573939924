import { theme } from '@singita/components';

export const CONTENT_TYPES = {
  LODGE: 'ContentfulLodge',
  CONSERVATION: 'ContentfulConservationArea',
  IMAGE: 'ContentfulImage',
  VIDEO: 'ContentfulVideo',
  CONSERVATION_PARTNER: 'ContentfulConservationPartner',
  CARD_HERO: 'ContentfulLayoutCardHero',
  CARD_TEXT: 'ContentfulLayoutCardText',
  PROMOTION: 'ContentfulPromotions',
  PAGE: 'ContentfulPages',
  PROJECT: 'ContentfulProjects',
  CONTENT: 'ContentfulContent',
  CONTENT_LODGES: 'ContentfulContentLodges',
  SECTION_GRID: 'ContentfulLayoutSectionGrid',
  LINKS: 'ContentfulLinks',
  ARTICLE: 'ContentfulArticles',
  MENU: 'ContentfulMenu',
  HERO: 'ContentfulLayoutHero',
  HERO_VIDEO: 'ContentfulLayoutHeroVideo',
  EMBED: 'ContentfulEmbed',
  PERSON: 'ContentfulPerson',
  LAYOUT_CONTENT: 'ContentfulLayoutContent',
  GALLERY: 'ContentfulLayoutGallery',
  BANNER: 'ContentfulLayoutBanner',
  SECTION_HEADING: 'SectionHeading',
  ACTIONBAR: 'ContentfulActionbar',
  PROJECTS_GRID: 'ContentfulLayoutProjectsGrid',
  SECTION_LEFT_RIGHT: 'ContentfulLayoutSectionLeftRight',
  ARCHIVE: 'ContentfulLayoutArchive',
  FEATURED_ARTICLES: 'ContentfulLayoutFeaturedArticles',
  SECTION_GRID_LODGES: 'ContentfulLayoutSectionGridLodges',
  SECTION_GRID_LODGES_PRIVATE_VILLAS:
    'ContentfulLayoutSectionGridLodgesPrivateVillas',
  SECTION_GRID_PROMOTIONS_PRIVATE_VILLAS:
    'ContentfulLayoutSectionGridPrivateVillaPromotions',
  SECTION_GRID_LODGES_RATES: 'ContentfulLayoutSectionGridLodgesRates',
  LIST: 'ContentfulLayoutList',
  ALERT: 'ContentfulAlert',
  TABS: 'ContentfulLayoutTabs',
  BORDER: 'ContentfulLayoutBorder',
  LINK: 'ContentfulLinks',
  STRUCTURED_DATA: 'ContentfulStructuredData',
  ASSET: 'ContentfulAsset',
  SECTION: 'ContentfulLayoutSection',
  ACTIONBAR_BOOKING: 'ContentfulActionbarBookingForm',
  FORM: 'ContentfulForm',
  REGION: 'ContentfulRegion',
  PLAN_YOUR_TRIP: 'ContentfulLayoutFormPlanYourTrip',
};

export const TEMPLATE_TYPES = {
  HOME: 'home-template',
  PAGE: 'page-template',
  ARTICLE: 'article-template',
  AUTHOR: 'author-template',
  ARTICLE_ARCHIVE: 'article-archive-template',
  PROJECT: 'project-template',
  PROMOTION: 'promotion-template',
  LODGE: 'lodge-template',
  LODGE_INFO: 'lodge-information-template',
  LODGE_ACTIVITY: 'lodge-activity-template',
  VIDEO: 'video',
  GALLERY: 'gallery',
  EXPERIENCE: 'experience',
  WILDLIFE: 'wildlife-report',
  BUSH_STORY: 'bush-story',
};

export const ARTICLE_TYPES = {
  BLOG: 'Blog Post',
  WILDLIFE: 'Wildlife Story',
  PRESS: 'Press Release',
};

export const gridContainerStyles = {
  columnGap: [0, `${theme.space[4]}px`],
  rowGap: `${theme.space[6]}px`,
  gridTemplateColumns: ['1fr', '1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
  width: '100%',
};

export const defaultSocialLinks = [
  {
    name: 'Facebook',
    icon: 'facebook',
    href: 'https://www.facebook.com/singita.gamereserves',
  },
  {
    name: 'Twitter',
    icon: 'twitter',
    href: 'https://twitter.com/Singita_',
  },
  {
    name: 'Pinterest',
    icon: 'pinterest',
    href: 'http://pinterest.com/singitareserves/',
  },
  {
    name: 'Email',
    icon: 'envelope',
    href: 'mailto:enquiries@singita.com',
  },
  {
    name: 'LinkedIn',
    icon: 'linked-in',
    href: 'https://www.linkedin.com/company/singita/',
  },
];
export const BUTTON_TYPES = {
  PRIMARY: 'Primary',
  OUTLINE: 'Outline',
  DOWNLOAD: 'Download', //ToDo: Remove
  TEXT: 'Text',
};

export const ALERT_TYPES = {
  POPUP: 'Popup',
  BANNER: 'Banner',
};

export const CONSTANTS = {
  global: {
    websiteName: 'Singita',
  },
  contact: {
    email: {
      label: 'E.',
      value: 'enquiries@singita.com',
    },
    telephone: {
      label: 'T.',
      value: '+27 (0) 21 683 3424',
    },
    link: {
      label: 'View all contact information',
    },
  },
  social: {
    vimeo: 'https://vimeo.com/singita',
    twitter: 'https://twitter.com/Singita_',
    facebook: 'https://www.facebook.com/singita.gamereserves/',
    instagram: 'https://www.instagram.com/singita_/?hl=en',
    podcast: 'https://open.spotify.com/show/0MEzl7srAsHkXgott0eXKW',
  },
  lodge: {
    links: {
      downloads: {
        siteplan: 'Download Lodge Siteplan',
        brochure: 'Download Brochure',
      },
      actionBar: {
        photos: 'Photos & Videos',
        menu: 'Lodge Menu',
        availability: 'Check availability',
        info: 'Lodge Info',
        activities: 'Activities',
        brochure: 'Brochure',
        siteplan: 'Lodge Siteplan',
      },
    },
  },
};
