import React from "react"

import { Heading, Flex, Box } from "@singita/components"
import { FORM_STATUS } from "../common/constants"
import { Translation } from "react-i18next"

export const FormConfirmation = ({
  response,
  message,
  uploadError,
  children,
}) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Box>
        {response === FORM_STATUS.SUCCESS && !uploadError ? (
          <>
            <Heading size="h3">{message}</Heading>
            <Flex alignItems="center" justifyContent="center" mt={[4]}>
              {children}
            </Flex>
          </>
        ) : null}

        {response === FORM_STATUS.FAILED || uploadError ? (
          <>
            <Heading size="h3">
              <Translation>{t => t("form.form-failed")}</Translation>
            </Heading>
            <Flex alignItems="center" justifyContent="center" mt={[4]}>
              {children}
            </Flex>
          </>
        ) : null}
      </Box>
    </Flex>
  )
}
