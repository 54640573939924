import moment from "moment"
import parsePhoneNumber from "libphonenumber-js"

export const validate = (fields, values) => {
  const errors = {}

  fields.forEach(field => {
    if (field.required && !values[field.name]) {
      errors[field.name] = field.errorMessage || "Required Field"
    } else if (field.type === "phone") {
      const phoneNumber = parsePhoneNumber(values[field.name])

      if (!values[field.name] || !phoneNumber) {
        errors[field.name] = "Invalid phone number"
      } else if (phoneNumber && !phoneNumber.isValid()) {
        errors[field.name] = "Invalid phone number"
      }
    } else if (
      field.type === "email" &&
      !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        values[field.name]
      )
    ) {
      errors[field.name] = "Invalid email address"
    } else if (field.type === "upload") {
      if (
        values[field.name] &&
        !fileTypesMap.includes(values[field.name].type)
      ) {
        errors[field.name] = "Invalid file type."
      }
    }
  })

  return errors
}

export const fileTypesMap = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "image/png",
  "image/jpeg",
  "application/msword",
]

export const normalizeFields = fields => {
  const normalizedObject = Object.keys(fields).reduce((result, item) => {
    const mustNormalize = normalizeMap[item]

    if (mustNormalize) {
      result[item] = mustNormalize.normalizeTo(fields[item])
    } else {
      result[item] = fields[item]
    }
    return result
  }, {})

  return normalizedObject
}

const normalizeMap = {
  travelDate: {
    normalizeTo: dates => {
      const { startDate, endDate } = dates
      return `${moment(startDate).format("YYYY-MM-DD")} to ${moment(
        endDate
      ).format("YYYY-MM-DD")}`
    },
  },
  phone: {
    normalizeTo: number => {
      const phoneNumber = parsePhoneNumber(number)
      return phoneNumber.format("INTERNATIONAL")
    },
  },
  newsletter: {
    normalizeTo: value => {
      return value ? "Yes" : "No"
    },
  },
  consent: {
    normalizeTo: value => {
      return value ? "Yes" : "No"
    },
  },
  visit: {
    normalizeTo: value => {
      return value ? "Yes" : "No"
    },
  },
}

export const getFormLayoutStyles = (styles, variant) => {
  if (styles) {
    return styles
  }

  const layoutStyles = customFormLayoutStyles[variant]

  return layoutStyles
    ? layoutStyles.default
    : customFormLayoutStyles.fallbackDefault
}

const customFormLayoutStyles = {
  standard: {
    default: {
      mb: [2],
    },
  },
  outline: {
    default: {
      mb: [2],
    },
  },
  fallbackDefault: {
    mb: [2],
  },
}

export const getLabel = (label, required) => {
  if (label) {
    return required ? `${label} *` : label
  }
}
