import React from "react"
import { graphql } from "gatsby"
import { Box } from "@singita/components"
import { Text, List, ListItem, theme } from "@singita/components"
import { getLinkProps } from "../common/utils"
import { useContentModal } from "gatsby-singita-theme/src/hooks"
import RichTextRenderer from "../components/RichTextRenderer"

const ListWrapper = props => {
  const { toggleModal } = useContentModal()
  const {
    headline,
    listItems,
    columns,
    styles,
    description,
    colorScheme,
  } = props

  return (
    <List
      headline={headline}
      cols={columns}
      sx={styles}
      renderDescription={
        description && <RichTextRenderer content={description} />
      }
    >
      {listItems.map((item, idx) => {
        const isFirst = idx === 0
        const link =
          item.link || item.url
            ? {
                ...(item.link || {}),
                ...(item.url ? { to: item.url } : {}),
              }
            : null

        let linkProps = {}
        if (link) {
          linkProps = getLinkProps(link, toggleModal).linkProps
        }

        return (
          <Box key={`list-item-box-${idx}`} {...linkProps}>
            <ListItem
              key={`list-item-${idx}`}
              pb={[link ? 3 : 1]}
              pt={[link ? 3 : isFirst ? 0 : 1]}
              hasBorder={link}
              iconLeft={item.icon}
              iconLeftColor={colorScheme ?? theme.colors.brandBrown}
              iconRight={link ? "chevron-right" : null}
              isLink={Boolean(link)}
              iconRightSize={12}
            >
              <Text key={`list-item-text-${idx}`} size="body" fontWeight="bold">
                {item.headline ? item.headline : item.name}
              </Text>
            </ListItem>
          </Box>
        )
      })}
    </List>
  )
}

export default ListWrapper

export const listFields = graphql`
  fragment ListFields on ContentfulLayoutList {
    contentful_id
    name
    headline
    description {
      raw
    }
    columns
    listItems {
      name
      headline
      icon
      link {
        __typename
        ...AllLinks
      }
      url
    }
    styles {
      pt
      pb
      mt
      mb
      px
    }
  }
`
