import { useEffect } from 'react';

function useLockScroll(shouldLock, className = 'no-scroll') {
  useEffect(() => {
    if (shouldLock) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
  }, [shouldLock, className]);
}

export default useLockScroll;
