import React from "react"
import { Flex, Container, Section } from "@singita/components"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import Layout from "../modules/Layout"
import Link from "../components/Link"
import { mapperWithFunction } from "../common/utils"

export const ImageInner = styled(Flex)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
`

const SECTION_MAP = {
  headline: ["headline"],
  description: ["description", "description"],
  direction: ({ direction }) => (direction ? direction.toLowerCase() : null),
  renderCta: ({ links }) =>
    links ? links.map(link => <Link {...link} />) : null,
  sx: ["style"],
}

const SectionWrapper = props => {
  const sectionProps = mapperWithFunction(props, SECTION_MAP)
  const isRow = sectionProps.direction === "row"

  if (!props.items) return null

  return (
    <Container>
      <Section
        className="Section"
        {...sectionProps}
        {...props.style}
        textContainerSx={{ width: isRow ? [1, 1, 5 / 12] : [1] }}
        childrenContainerSx={{ width: isRow ? [1, 1, 6 / 12] : [1] }}
      >
        {props.items?.map(item => (
          <Layout className="SectionLayout" layout={[item]} />
        ))}
      </Section>
    </Container>
  )
}

export default SectionWrapper

export const SectionLayoutFields = graphql`
  fragment SectionFields on ContentfulLayoutSection {
    id
    headline
    description {
      description
    }
    items {
      __typename
      ... on Node {
        ...ListFields
        ...Content
      }
    }
    direction
    links {
      __typename
      ...LinkFields
    }
    style {
      pt
      pb
      pl
      pr
    }
  }
`
