import React, { useContext, useEffect, useState } from 'react';
import Link from '../ConditionalLink';
import {
  theme,
  Text,
  Icon,
  Heading,
  Accordion,
  Box,
  Flex,
} from '@singita/components';
import { FadeIn, StaggerItem, StaggerContainer } from './animations';
import { Media } from 'gatsby-singita-theme/src/Media';
import { BackButton, MenuItemContainer } from './styles';
import { MENU_VARIANTS } from './constants';
import { ResourceSetContext } from '../../context/ResourceSetContext';
import { Translation } from 'react-i18next';

const MenuLodge = ({ setBg, lodges, setMenu, setIsOpen, currentPath }) => {
  const initialHoverState = {
    isHover: false,
    link: null,
    bg: 'brandBrown',
  };
  const [hover, setHovering] = useState(initialHoverState);
  const { getResourcesForComponent } = useContext(ResourceSetContext);
  const { privateVillas } = getResourcesForComponent('lodges');

  useEffect(() => {
    setBg(hover.bg);
  }, [hover, setBg]);

  return (
    <Box pl={[6, 6, 0, 0]}>
      <Flex
        alignItems="center"
        sx={{ marginLeft: `calc(-34px - ${theme.space[2]}px - 2px)` }}
        mb={[3, 3, 5, 5]}
      >
        <BackButton
          data-testid="lodge-menu-back"
          onClick={() => setMenu(MENU_VARIANTS.MAIN)}
          mr={[2]}
        >
          <Icon
            name={'chevron-left'}
            size={16}
            sx={{ color: theme.colors.textWhite }}
          />
        </BackButton>
        <Heading size="menu" color="textWhite" fontWeight="light">
          <Translation>{(t) => t('menu.lodges')}</Translation>
        </Heading>
      </Flex>
      {lodges && (
        <>
          <Media greaterThanOrEqual="lg">
            <Flex flexDirection="row">
              {Object.keys(lodges).map((country) => {
                return (
                  <Flex mr={[5]} width={[1, 2 / 12]} flexDirection="column">
                    <FadeIn delay={0.3}>
                      <Heading size="h4" color="textWhite" sx={{ mb: [3] }}>
                        {country}
                      </Heading>
                    </FadeIn>
                    {Object.keys(lodges[country]).map((region) => {
                      return (
                        <Flex flexDirection="column" mb={[5]}>
                          <FadeIn mb={[3]} delay={0.4}>
                            <Text
                              size="small"
                              color="textWhite"
                              fontSize="10px"
                              sx={{
                                opacity: 0.5,
                                textTransform: 'uppercase',
                                letterSpacing: '1px',
                              }}
                            >
                              {region}
                            </Text>
                          </FadeIn>
                          <StaggerContainer delay={0.3}>
                            {lodges[country][region].map((link) => (
                              <StaggerItem key={link.id}>
                                <MenuItemContainer
                                  mb={[2]}
                                  isHovering={hover.isHover}
                                  isActive={hover.link === link.id}
                                  onMouseEnter={() => {
                                    setHovering((state) => ({
                                      isHover: true,
                                      link: link.id,
                                      bg: link.color || state.bg,
                                    }));
                                  }}
                                  onMouseLeave={() => {
                                    setHovering(initialHoverState);
                                  }}
                                  onClick={() => {
                                    if (currentPath === `/lodge/${link.to}`) {
                                      setIsOpen(false);
                                    }
                                  }}
                                >
                                  <Link to={`/lodge/${link.to}/`}>
                                    <Text
                                      size="body"
                                      color="textWhite"
                                      sx={{ mb: [2] }}
                                    >
                                      {link.label}
                                    </Text>
                                  </Link>
                                </MenuItemContainer>
                              </StaggerItem>
                            ))}
                          </StaggerContainer>
                        </Flex>
                      );
                    })}
                  </Flex>
                );
              })}
              <Flex width={[1, 3 / 12]} flexDirection="column">
                <FadeIn delay={0.3}>
                  <Heading size="h4" color="textWhite" sx={{ mb: [3] }}>
                    {privateVillas.menuHeading}
                  </Heading>
                </FadeIn>

                <Flex flexDirection="column" mb={[5]}>
                  <MenuItemContainer
                    pb={[2]}
                    isHovering={hover.isHover}
                    isActive={hover.link === 'id-private'}
                    onMouseEnter={() => {
                      setHovering(() => ({
                        isHover: true,
                        link: 'id-private',
                        bg: privateVillas.color,
                      }));
                    }}
                    onMouseLeave={() => {
                      setHovering(initialHoverState);
                    }}
                  >
                    <Link to={'/private-villas/'}>
                      <StaggerContainer>
                        <StaggerItem>
                          <Text size="body" color="textWhite" sx={{ mb: [2] }}>
                            {privateVillas.menuLink}
                          </Text>
                        </StaggerItem>
                      </StaggerContainer>
                    </Link>
                  </MenuItemContainer>
                </Flex>
              </Flex>
            </Flex>
          </Media>
          <Media lessThan="lg">
            <StaggerContainer display="flex" flexDirection="column">
              {Object.keys(lodges).map((country) => {
                return (
                  <StaggerItem>
                    <Accordion headline={country} color="textWhite">
                      {Object.keys(lodges[country]).map((region, idx) => {
                        return (
                          <Flex
                            flexDirection="column"
                            mb={[
                              idx === Object.keys(lodges[country]).length - 1
                                ? 0
                                : 3,
                            ]}
                          >
                            <Text
                              size="small"
                              color="textWhite"
                              fontSize="10px"
                              sx={{
                                opacity: 0.5,
                                textTransform: 'uppercase',
                                mb: [3],
                              }}
                            >
                              {region}
                            </Text>
                            {lodges[country][region].map((link) => (
                              <MenuItemContainer
                                mb={[2]}
                                isHovering={hover.isHover}
                                isActive={hover.link === link.id}
                                onMouseEnter={() => {
                                  setHovering((state) => ({
                                    isHover: true,
                                    link: link.id,
                                    bg: link.color || state.bg,
                                  }));
                                }}
                                onMouseLeave={() => {
                                  setHovering(() => initialHoverState);
                                }}
                                onClick={() => {
                                  if (currentPath === `/lodge/${link.to}`) {
                                    setIsOpen(false);
                                  }
                                }}
                              >
                                <Link to={`/lodge/${link.to}/`}>
                                  <Text
                                    size="body"
                                    color="textWhite"
                                    sx={{ mb: [2] }}
                                  >
                                    {link.label}
                                  </Text>
                                </Link>
                              </MenuItemContainer>
                            ))}
                          </Flex>
                        );
                      })}
                    </Accordion>
                  </StaggerItem>
                );
              })}
            </StaggerContainer>
          </Media>
        </>
      )}
    </Box>
  );
};

export default MenuLodge;
