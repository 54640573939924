import React from "react"
import { graphql } from "gatsby"

import { Card } from "@singita/components"
import { mapperWithFunction } from "../common/utils"
import MediaWrapper from "./MediaWrapper"
import Link from "../components/Link"

const CARD_POSTER_MAP = {
  headline: ["headline"],
  visual: value =>
    value.visual ? (
      <MediaWrapper
        {...value.visual}
        style={{
          width: "100%",
          height: "100%",
          userSelect: "none",
        }}
        imgStyle={{
          objectFit: "cover",
        }}
      />
    ) : null,
  renderCta: value =>
    value.link
      ? () => (
          <Link colorScheme="baseWhite" iconPosition="right" {...value.link} />
        )
      : null,
}

const CardPosterWrapper = props => {
  const cardProps = mapperWithFunction(props, CARD_POSTER_MAP)

  return <Card variant="poster" {...cardProps} />
}

export default CardPosterWrapper

export const CardPosterFields = graphql`
  fragment CardPosterFields on ContentfulLayoutCardPoster {
    id
    name
    headline
    visual {
      __typename
      ... on Node {
        ... on ContentfulImage {
          ...ImageFields
          image {
            fluid(quality: 95, maxWidth: 1440, maxHeight: 760) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        ... on ContentfulVideo {
          ...VideoFields
        }
      }
    }
    link {
      ...LinkFields
    }
  }
`
