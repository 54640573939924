import React, { useContext } from "react"
import styled from "@emotion/styled"
import { AlertsContext } from "gatsby-singita-theme/src/context/AlertsContext"
import { Box, motion, AnimateSharedLayout, theme } from "@singita/components"
import AlertWrapper from "../modules/AlertWrapper"

let PaneContainer = styled(Box)`
  position: fixed;
  right: 16px;
  bottom: 80px;
  display: flex;
  flex-direction: column;
  min-width: 450px;
  max-width: 600px;
  z-index: ${theme.zIndex.notification};
`
PaneContainer = motion.custom(PaneContainer)

const PopupPane = props => {
  const { alerts } = useContext(AlertsContext)

  return (
    <AnimateSharedLayout>
      <PaneContainer layout>
        {alerts.map(alert => (
          <AlertWrapper key={alert.id} {...alert} />
        ))}
      </PaneContainer>
    </AnimateSharedLayout>
  )
}

export default PopupPane
