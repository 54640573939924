import React from "react"
import { graphql } from "gatsby"
import { Card } from "@singita/components"
import { mapperWithFunction } from "../common/utils"
import RichTextRenderer from "../components/RichTextRenderer"

const CARD_TEXT_MAP = {
  headline: ["headline"],
  subtitle: ["subtitle"],
  content: ["content"],
  styles: ["styles"],
  sx: () => ({ height: "100%" }),
}

const CardTextWrapper = ({ section }) => {
  const { content, ...cardProps } = mapperWithFunction(section, CARD_TEXT_MAP)

  return (
    <Card variant="text" mb={[4]} {...cardProps}>
      {content && <RichTextRenderer content={content} />}
    </Card>
  )
}

export default CardTextWrapper

export const CardTextFields = graphql`
  fragment CardTextFields on ContentfulLayoutCardText {
    contentful_id
    headline
    subtitle
    content {
      raw
    }
    styles {
      width
    }
  }
`
