import { gql } from '@apollo/client';

export const QUERY_ALL_REGIONS_AND_LODGES = gql`
  query getAllRegions {
    findRegions {
      name
      country
      code
      lodges {
        name
        code
      }
    }
  }
`;

export const QUERY_REGIONS = gql`
  fragment LodgeDetails on Lodge {
    name
    code
    region {
      name
      country
    }
    lodgeDetails {
      title
      featured_media {
        source_url
      }
      hero {
        source_url
      }
      acf
    }
    code
    units {
      id
      name
      code
      bedsPerUnit
      numberOfUnits
      bookingEngineCode
      currency
      hasGreenSeason
      bestMatch(
        startDate: $startDate
        endDate: $endDate
        adults: $adults
        children: $children
        toddler: $toddler
        add: 60
        subtract: 60
      ) {
        startDate
        endDate
        difference
        nights
      }
      available(
        startDate: $startDate
        endDate: $endDate
        adults: $adults
        children: $children
        toddler: $toddler
      ) {
        available
        numberOfUnits
        neededUnits
        rates {
          date
          high
          green
          isHighSeason
          base
          children
          adults
        }
      }
    }
  }
  query getRegionResults(
    $regions: [String]
    $lodges: [String]
    $startDate: String!
    $endDate: String!
    $adults: Int!
    $children: Int
    $toddler: Int
    $hasToddler: Boolean
  ) {
    findRegions(input: { regions: $regions }) {
      name
      country
      code
      lodges(input: { lodges: $lodges }) {
        ...LodgeDetails
      }
      familyLodges(input: { lodges: $lodges, hasToddler: $hasToddler }) {
        ...LodgeDetails
      }
    }
  }
`;

export const FORM_QUERY = gql`
  query getForm($id: String!) {
    viewForm(id: $id) {
      id
      title
      description
      buttonText
      buttonClass
      fields {
        label
        placeholder
        description
        id
        fieldId
        name
        type
        className
        required
        validate
        options {
          label
          value
        }
        initialValue
        styles
        variant
        size
        bg
        disabled
        min
        max
        orientation
        errorMessage
        group {
          type
          label
          isSpaced
          value
        }
      }
    }
  }
`;
