import React from "react"
import {
  Input,
  Select,
  Selection,
  Button,
  Text,
  Heading,
  Box,
  Flex,
  Loader,
} from "@singita/components"
import {
  Dates,
  NumberField,
  Radio,
  Lodges,
  Winnie,
  PhoneNumber,
  Upload,
} from "./components"
import { getFormLayoutStyles, getLabel } from "./utils/helpers"

const FormBuilder = ({
  reference,
  fields,
  onSubmit,
  renderTitle,
  onChange,
  errors,
  formStyles,
  buttonText = "Submit",
  form,
  setForm,
  isLoading,
}) => {
  return (
    <Box>
      {renderTitle ? renderTitle() : null}
      <Flex as="form" onSubmit={onSubmit} ref={reference} {...formStyles}>
        {fields.map(
          ({
            name,
            type,
            label,
            options,
            styles,
            placeholder = "",
            variant = "standard",
            size,
            disabled,
            min,
            max,
            orientation,
            group = [],
            initialValue,
            bg,
            required,
            description,
          }) => {
            const layoutStyles = getFormLayoutStyles(styles, variant)
            const realLabel = getLabel(label, required)

            switch (type) {
              case "multiSelect":
                const value = form[name]
                  ? form[name].reduce((result, item) => {
                      const getOption = options.find(o => o.value === item)
                      if (getOption) {
                        result.push({ ...getOption, key: getOption.value })
                      }
                      return result
                    }, [])
                  : []

                return (
                  <Box {...layoutStyles}>
                    <Select
                      id={name}
                      label={realLabel}
                      name={name}
                      options={options.map(option => {
                        return {
                          ...option,
                          key: option.value,
                        }
                      })}
                      onChange={option => {
                        if (option && option.length) {
                          const optionArray = option.map(o => o.value)
                          setForm({ ...form, [name]: optionArray })
                        } else {
                          setForm({ ...form, [name]: [] })
                        }
                      }}
                      placeholder={placeholder}
                      bg="white"
                      noOptionsMessage="No further options"
                      error={errors[name]}
                      isMulti={true}
                      value={value}
                      disabled={isLoading}
                    />
                  </Box>
                )
              case "select":
                return (
                  <Box {...layoutStyles}>
                    <Select
                      label={realLabel}
                      placeholder={placeholder}
                      options={options.map(option => {
                        return {
                          ...option,
                          key: option.value,
                        }
                      })}
                      onChange={option => {
                        setForm({ ...form, [name]: option.value })
                      }}
                      name={name}
                      error={errors[name]}
                      bg="white"
                      value={options.find(
                        option => option.value === form[name]
                      )}
                      disabled={isLoading}
                    />
                    {description && <Text size="tiny">{description}</Text>}
                  </Box>
                )
              case "phone":
                return (
                  <Box {...layoutStyles}>
                    <PhoneNumber
                      form={form}
                      setForm={setForm}
                      name={name}
                      label={realLabel}
                      error={errors[name]}
                      bg={bg}
                      disabled={isLoading}
                    />
                  </Box>
                )
              case "input":
                return (
                  <Box {...layoutStyles}>
                    <Input
                      variant={variant}
                      size="standard"
                      name={name}
                      label={realLabel}
                      type="input"
                      bg="white"
                      onChange={onChange}
                      error={errors[name]}
                      value={form[name] || ""}
                      autoComplete="on"
                      disabled={isLoading || disabled}
                      placeholder={placeholder}
                    />
                  </Box>
                )
              case "email":
                return (
                  <Box {...layoutStyles}>
                    <Input
                      variant={variant}
                      size="standard"
                      name={name}
                      label={realLabel}
                      type="input"
                      bg="white"
                      onChange={onChange}
                      error={errors[name]}
                      value={form[name] || ""}
                      autoComplete="on"
                      disabled={isLoading}
                      placeholder={placeholder}
                    />
                  </Box>
                )
              case "textarea":
                return (
                  <Box {...layoutStyles}>
                    <Input
                      variant={variant}
                      size="standard"
                      name={name}
                      label={realLabel}
                      type="textarea"
                      bg="white"
                      onChange={onChange}
                      error={errors[name]}
                      value={form[name] || ""}
                      disabled={isLoading}
                      placeholder={placeholder}
                    />
                  </Box>
                )
              case "checkbox":
                return (
                  <Box {...layoutStyles}>
                    <Selection
                      label={realLabel}
                      orientation="left"
                      isSpaced={false}
                      onChange={onChange}
                      name={name}
                      error={errors[name]}
                      checked={form[name] || false}
                      value={form[name] || ""}
                      disabled={isLoading}
                      placeholder={placeholder}
                    />
                  </Box>
                )
              case "radio":
                return (
                  <Box {...layoutStyles}>
                    <Radio
                      label={realLabel}
                      group={group}
                      onChange={onChange}
                      name={name}
                      error={errors[name]}
                      form={form}
                      disabled={isLoading}
                    />
                  </Box>
                )
              case "text":
                return (
                  <Box {...layoutStyles}>
                    <Text size={size}>{label}</Text>
                  </Box>
                )
              case "heading":
                return (
                  <Box {...layoutStyles}>
                    <Heading size={size}>{label}</Heading>
                  </Box>
                )
              case "upload":
                return (
                  <Box {...layoutStyles}>
                    <Upload
                      name={name}
                      placeholder={placeholder}
                      setForm={setForm}
                      form={form}
                      label={realLabel}
                      error={errors[name]}
                      disabled={isLoading}
                    />
                  </Box>
                )
              case "number":
                return (
                  <Box {...layoutStyles}>
                    <NumberField
                      name={name}
                      label={realLabel}
                      disabled={disabled}
                      initialValue={initialValue}
                      min={min}
                      max={max}
                      orientation={orientation}
                      form={form}
                      setForm={setForm}
                      disabled={isLoading}
                    />
                  </Box>
                )
              case "date":
                return (
                  <Box {...layoutStyles}>
                    <Dates
                      form={form}
                      setForm={setForm}
                      label={label}
                      name={name}
                      disabled={isLoading}
                    />
                  </Box>
                )
              case "lodges":
                return (
                  <Box {...layoutStyles}>
                    <Lodges
                      label={realLabel}
                      placeholder={placeholder}
                      name={name}
                      error={errors[name]}
                      form={form}
                      setForm={setForm}
                      disabled={isLoading}
                    />
                  </Box>
                )
              default:
                return null
            }
          }
        )}
        <Winnie onChange={onChange} />
        <Flex alignItems="center" justifyContent="center">
          {isLoading ? (
            <Loader isActive={isLoading} size={100} />
          ) : (
            <Button type="submit">{buttonText}</Button>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default FormBuilder
