import React from "react"
import { graphql } from "gatsby"

import { Flex, Box, Text } from "@singita/components"
import ImageWrapper from "./ImageWrapper"
import AssetWrapper from "./AssetWrapper"

const TwoUpWrapper = props => {
  const { images, media, caption, containerSx } = props
  return (
    <Flex flexDirection="column" alignItems="center" sx={containerSx}>
      <Flex flexDirection={["column", "column", "row", "row"]} width="100%">
        {images
          ? images.map((image, index) => (
              <Box
                width="100%"
                mr={index === 0 ? [0, 0, 2, 2] : [0]}
                mb={index === 0 ? [1, 1, 0, 0] : [0]}
              >
                <ImageWrapper {...image} />
              </Box>
            ))
          : media
          ? media.map((image, index) => (
              <Box
                width="100%"
                mr={index === 0 ? [0, 0, 2, 2] : [0]}
                mb={index === 0 ? [2, 2, 0, 0] : [0]}
              >
                <AssetWrapper {...image} />
              </Box>
            ))
          : null}
      </Flex>
      {caption && (
        <Box mt={[1]} textAlign="center">
          <Text size="tiny">{caption}</Text>
        </Box>
      )}
    </Flex>
  )
}

export default TwoUpWrapper

export const twoUpFields = graphql`
  fragment TwoUpFields on ContentfulLayout2UpImage {
    contentful_id
    name
    images {
      ...ImageFields
      image {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    media {
      ...AssetFields
    }
    caption
  }
`
