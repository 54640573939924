import i18n from '../../i18n';

export const MENU_VARIANTS = {
  MAIN: 'main',
  LODGE: 'lodge',
  SPVC: 'spvc',
};

export const NAVIGATION_TYPES = {
  MENU: 'menu',
  LINK: 'link',
};

export const mainMenu = () => [
  {
    label: i18n.t('menu.lodges'),
    to: MENU_VARIANTS.LODGE,
    type: NAVIGATION_TYPES.MENU,
  },
  {
    label: i18n.t('menu.private-villas'),
    to: '/private-villas/',
    type: NAVIGATION_TYPES.LINK,
  },
  {
    label: i18n.t('menu.conservation'),
    to: '/conservation/',
    type: NAVIGATION_TYPES.LINK,
  },
  {
    label: i18n.t('menu.wildlife'),
    to: '/wildlife/',
    type: NAVIGATION_TYPES.LINK,
  },
  { label: i18n.t('menu.about'), to: '/about/', type: NAVIGATION_TYPES.LINK },
];
export const mainMenuSpvc = [
  {
    label: 'Serengeti House',
    to: '/villa/singita-serengeti-house',
    type: NAVIGATION_TYPES.LINK,
  },
  {
    label: 'Explore',
    to: '/villa/singita-explore',
    type: NAVIGATION_TYPES.LINK,
  },
  {
    label: 'Milele',
    to: '/villa/singita-milele',
    type: NAVIGATION_TYPES.LINK,
  },
  {
    label: 'Castleton',
    to: '/villa/singita-castleton',
    type: NAVIGATION_TYPES.LINK,
  },
  {
    label: 'Ebony Villa',
    to: '/villa/singita-ebony-villa',
    type: NAVIGATION_TYPES.LINK,
  },
  {
    label: 'Lebombo Villa',
    to: '/villa/singita-lebombo-villa',
    type: NAVIGATION_TYPES.LINK,
  },
  {
    label: 'Malilangwe House',
    to: '/villa/singita-malilangwe-house',
    type: NAVIGATION_TYPES.LINK,
  },
  {
    label: 'Kataza House',
    to: '/villa/singita-kataza-house',
    type: NAVIGATION_TYPES.LINK,
  },
];
