import React from "react"
import { Newsletter as NewsletterComponent } from "@singita/components"
import FormWrapper from "../modules/Forms/FormWrapper"
import { useLanguage } from "gatsby-singita-theme/src/context/LanguageContext"
import { useNewsletterQuery } from "../hooks/useNewsletterQuery"
const NewsletterWrapper = () => {
  const lang = useLanguage()
  const form = useNewsletterQuery(lang)

  const {
    desc,
    title,
    styles = {},
    formId,
    successMessage,
    newsletterListId,
  } = form.node

  return (
    <NewsletterComponent
      headline={title}
      subtitle={desc}
      sx={{ bg: "bgLight", px: [3, 5, 12, 25], py: [6, 10, 13] }}
      renderForm={() => (
        <FormWrapper
          formId={formId}
          styles={styles}
          successMessage={successMessage}
          renderTitle={false}
          newsletterListId={newsletterListId}
        />
      )}
    />
  )
}

export default NewsletterWrapper
