import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import BackgroundImage from 'gatsby-background-image';

import { Video, Box, Text } from '@singita/components';
import { mapperWithFunction } from '../common/utils';
import { VideoContext } from 'gatsby-singita-theme/src/context/VideoContext';

const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
`;
const StyledBackgroundImg = styled(Box)`
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const VIDEO_MAP = {
  fullUrl: ['fullUrl'],
  previewUrl: ['previewUrl'],
  renderThumbnail: (value) => {
    return value.thumbnail
      ? () =>
          value.thumbnail.fluid ? (
            <StyledBackground Tag="figure" fluid={value.thumbnail.fluid} />
          ) : (
            <StyledBackgroundImg src={value.thumbnail} />
          )
      : null;
  },
  canPlay: (value) => value.canPlay ?? true,
  minHeight: ['minHeight'],
};

const VideoWrapper = (props) => {
  const { containerSx = {}, visualRatio = null } = props;
  const { isOpen, setIsOpen } = useContext(VideoContext);
  const videoProps = mapperWithFunction(props, VIDEO_MAP);
  return (
    <Box height="100%" sx={containerSx}>
      <Video
        {...videoProps}
        visualRatio={visualRatio}
        isModalOpen={isOpen}
        toggleModal={(videoUrl) => setIsOpen(videoUrl, true)}
      />
      {props.caption ? (
        <Box mt={[1]} textAlign="center">
          <Text size="tiny">{props.caption}</Text>
        </Box>
      ) : null}
    </Box>
  );
};

export default VideoWrapper;

export const VideoFields = graphql`
  fragment VideoFields on ContentfulVideo {
    contentful_id
    caption
    headline
    fullUrl
    previewUrl
    thumbnail {
      fluid(maxWidth: 1000, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    createdAt
  }
`;
