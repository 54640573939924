import React, { useCallback, useEffect, useState } from "react"
import { Box, Flex } from "@singita/components"
import { graphql } from "gatsby"
import RichTextRenderer from "../components/RichTextRenderer"

import {
  Modal,
  Container,
  Section,
  GridContainer,
  Heading,
  Card,
  Button,
  theme,
  Filter,
  FilterItem,
} from "@singita/components"
import FilterWrapper from "../modules/FilterWrapper"
import { getIdForTag, mapperWithFunction } from "../common/utils"
import { gridContainerStyles } from "../common/constants"
import RatesTable from "../components/RatesTable"
import { AvailabilityActionBar } from "../modules/Availability"
import GridItem from "../components/GridItem"
import { useLockScroll } from "gatsby-singita-theme/src/hooks"
import ImageWrapper from "./ImageWrapper"
import { getEntries } from "../common/contentful"

const CARD_MAP = {
  headline: ["headline"],
  meta: value => [
    {
      text: `${value.region.headline}, ${value.region.country}`,
      color: "textDark",
    },
  ],
  renderCta: ({ openModal, ...item }) => () => (
    <Button
      variant="primary"
      colorScheme="brandBrown"
      size="small"
      onClick={() => openModal(item)}
    >
      Detailed Rates
    </Button>
  ),
  image: value => {
    return value?.imageRef?.image?.fluid?.src || null;
  },
  renderImage: value =>
    value.image
      ? () => <ImageWrapper image={value.image} />
      : value?.imageRef?.image
      ? () => <ImageWrapper image={value.imageRef.image} />
      : null,
}

const RatesContent = ({ item }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  const tags = JSON.parse(item.tags.internal.content)
  const contentId = getIdForTag("rates", tags)

  useEffect(() => {
    if (contentId) {
      async function getContent() {
        setLoading(true)
        const res = await getEntries({
          include: 10,
          content_type: "content",
          "sys.id": contentId,
        })
        if (res) {
          setData(res[0])
        }
        setLoading(false)
      }
      getContent()
    }
  }, [contentId])

  return !loading ? <RichTextRenderer content={data.content} /> : null
}

const SectionGridLayoutLodgesRates = props => {
  const { filter, lodges } = props
  const [filteredItems, setFilteredItems] = useState(lodges.items)
  const [modalProps, setModalProps] = useState({ isOpen: false, item: null })
  useLockScroll(modalProps.isOpen)

  const openModal = item => setModalProps({ isOpen: true, item })

  const [years, setYears] = useState([])
  const onSetFilter = useCallback(payload => setFilteredItems(payload), [
    setFilteredItems,
  ])

  useEffect(() => {
    const years = filteredItems
      .reduce((acc, item) => {
        const { units } = item
        units.forEach(({ rates }) => {
          if (rates) {
            rates.forEach(({ year }) => {
              if (modalProps.item && modalProps.item.slug === item.slug) {
                if (year && !acc.includes(year)) {
                  acc.push(year)
                }
              }
            })
          }
        })
        return acc
      }, [])
      .filter(year => year >= new Date().getFullYear())
      .sort()

    setYears(years)
    setYearFilter(years.length > 0 ? years[0] : new Date().getFullYear()) // Update yearFilter state
  }, [filteredItems, modalProps.item])

  const [yearFilter, setYearFilter] = useState(
    years.length > 0 ? years[0] : new Date().getFullYear()
  )
  return (
    <>
      <Modal
        title={modalProps.item ? `Rates at ${modalProps.item.headline}` : null}
        isOpen={modalProps.isOpen}
        size="large"
        handleClose={() => {
          setModalProps({ isOpen: false, item: null })
        }}
        bg={theme.colors.brandBeige}
      >
        {modalProps.item && (
          <Box>
            {years.length > 1 && (
              <Box mb={[5]}>
                <Filter>
                  {years.map(year => {
                    return (
                      <FilterItem
                        active={yearFilter === year}
                        onClick={() => setYearFilter(year)}
                      >
                        {year}
                      </FilterItem>
                    )
                  })}
                </Filter>
              </Box>
            )}
            <Box mb={[10]}>
              <RatesTable lodge={modalProps.item} yearFilter={yearFilter} />
            </Box>
            <AvailabilityActionBar />
            <RatesContent item={modalProps.item} />
          </Box>
        )}
      </Modal>
      <Container>
        <Section>
          <Flex
            flexDirection={["column", "column", "column", "row"]}
            alignItems="flex-start"
          >
            {filter && (
              <Box
                width={["100%", "100%", "100%", "auto"]}
                mb={[5]}
                mr={[10]}
                sx={{
                  flexShrink: 0,
                  position: ["static", "static", "static", "sticky"],
                  top: "85px",
                  bottom: 0,
                }}
              >
                <FilterWrapper
                  items={lodges.items}
                  filter={filter}
                  onSetFilter={onSetFilter}
                  isVertical={true}
                />
              </Box>
            )}
            {filteredItems.length > 0 ? (
              <GridContainer
                sx={{ ...gridContainerStyles, gridTemplateColumns: ["1fr"] }}
              >
                {filteredItems.map((item, idx) => {
                  const cardProps = mapperWithFunction(
                    { ...item, openModal },
                    CARD_MAP
                  )

                  return (
                    <GridItem index={idx} columns={1}>
                      <Card
                        variant="strip"
                        size="large"
                        bg="bgLight"
                        padding={{ pr: [3, 0] }}
                        {...cardProps}
                      />
                    </GridItem>
                  )
                })}
              </GridContainer>
            ) : (
              <Heading size="h4" color="textDark">
                No results found.
              </Heading>
            )}
          </Flex>
        </Section>
      </Container>
    </>
  )
}

export default SectionGridLayoutLodgesRates

export const unitFields = graphql`
  fragment UnitFields on ContentfulLodge {
    units {
      headline
      subtitle
      numberOfUnits
      isHiddenOnRatesTable
      rates {
        year
        season
        rates
      }
      unitRates {
        currency
        rates {
          year
          PR
          OPR
          SR
          prDescription
          oprDescription
          srDescription
        }
      }
      hasChildRates
      ratesSuffix
    }
  }
`

export const sectionGridLayoutLodgesRatesFields = graphql`
  fragment SectionGridLayoutLodgesRatesFields on ContentfulLayoutSectionGridLodgesRates {
    filter
    lodges {
      items {
        ...LodgeCardFields
        ...UnitFields
        tags {
          internal {
            content
          }
        }
        region {
          seasonDates {
            year
            season
            dates
          }
        }
      }
    }
  }
`
