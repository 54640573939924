import React from "react"
import { Selection, Text, Box } from "@singita/components"

export const Radio = ({
  label,
  group,
  onChange,
  name,
  error,
  form,
  disabled,
}) => {
  const checkedValue = form[name] || null
  return (
    <>
      {label && (
        <Box>
          <Text size="small" fontWeight="bold">
            {label}
          </Text>
        </Box>
      )}
      {group.length &&
        group.map((radioField, index) => (
          <Box key={`radio-${name}-option-${index}`} mb={[1]}>
            <Selection
              type="radio"
              label={radioField.label}
              orientation="left"
              isSpaced={radioField.isSpaced}
              onChange={onChange}
              name={name}
              value={radioField.value}
              checked={
                checkedValue === radioField.value ? radioField.value : null
              }
              error={error}
              disabled={disabled}
            />
          </Box>
        ))}
      <Text color="statusError" size="small">
        {error}
      </Text>
    </>
  )
}
