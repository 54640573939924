export const ACTIONS = {
  STEP: "step",
  UPDATE_REGIONS: "update_regions",
  UPDATE_LODGES: "update_lodges",
  UPDATE_DATES: "update_dates",
  UPDATE_GUESTS: "update_guests",
  RESET: "reset",
}

export const STEPS = {
  REGIONS: "regions",
  LODGES: "lodges",
  DATES: "dates",
  GUESTS: "guests",
  CTA: "call-to-action",
  ENQUIRE: "enquire",
  CHECK_AVAILABILITY: "check_availability",
}

export const BUTTONS = {
  [STEPS.REGIONS]: "Regions",
  [STEPS.LODGES]: "Lodges",
  [STEPS.DATES]: "Dates",
  [STEPS.GUESTS]: "Guests",
  [STEPS.CTA]: "Submit",
}

export const HEADINGS = {
  [STEPS.REGIONS]: "Regions",
  [STEPS.LODGES]: "Lodges",
  [STEPS.DATES]: "Dates",
  [STEPS.GUESTS]: "Guests",
}

export const SUBTITLES = {
  [STEPS.REGIONS]: ["Select the regions you're interested in:"],
  [STEPS.LODGES]: [
    "These lodges are availabile in the regions you've selected.",
    "Please select the ones you're interested in:",
  ],
  [STEPS.DATES]: ["Select the dates which will suit you:"],
  [STEPS.GUESTS]: [
    "Please indicate how many adults will need to be accommodated.",
    "If your booking includes children, please enquire directly on the next step.",
    "",
    "View our child policy for each lodge here.",
  ],
}

export const CTA_SECTIONS = {
  AVAILABILITY: "availability",
  ENQUIRY: "enquiry",
}

export const CTA_COPY = {
  [CTA_SECTIONS.AVAILABILITY]: {
    heading: "Check Availability",
    subtitle:
      "To see if we have availability for your preferred criteria, please click the button below.",
    button: "Check now",
  },
  [CTA_SECTIONS.ENQUIRY]: {
    heading: "Speak with a Singita Travel Advisor",
    subtitle:
      "Need some help to plan your trip? Enquire below to contact one of our Singita Travel Advisors.",
    button: "Enquire now",
  },
}

export const ENQUIRY_COPY = {
  intro:
    "Please complete the form below and one of our Singita Travel Advisors will contact you shortly to plan your trip.",
  note:
    "PLEASE NOTE: These results indicate availability and do not guarantee a booking. One of our Singita Travel Advisors will contact you shortly to plan your trip.",
  sendButton: "Send Enquiry",
  backButton: "Back",
}

export const PLAN_YOUR_TRIP = {
  label: "Plan your trip",
}
