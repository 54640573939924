import React from "react"
import { graphql } from "gatsby"
import { mapperWithFunction } from "../common/utils"
import CardHeroWrapper from "./CardHeroWrapper"

const CONSERVATION_AREA_MAP = {
  headline: ["headline"],
  visual: value => value?.visual,
  visualPosition: value => (value.index % 2 === 0 ? "right" : "left"),
  content: ["content"],
}

const ConservationPartnerWrapper = props => {
  const cardProps = mapperWithFunction(props, CONSERVATION_AREA_MAP)

  return <CardHeroWrapper {...cardProps} />
}

export default ConservationPartnerWrapper

export const ConservationPartnerFields = graphql`
  fragment ConservationPartnerFields on ContentfulConservationPartner {
    contentful_id
    headline: name
    visual {
      __typename
      ... on ContentfulImage {
        image {
          fluid(quality: 95, maxWidth: 930, maxHeight: 1000) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    content: description {
      raw
      references {
        __typename
        ...ContentFields
        ...RichTextReferences
      }
    }
  }
`
