import { useContext, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { NavbarContext } from '../context/NavbarContext';
import { theme } from '@singita/components';
import { LodgeContext } from '../context/LodgeContext';
import useMobile from 'gatsby-singita-theme/src/hooks/useMobile';

const useSetNavbarStylesOnPresence = (
  threshold,
  rootMargin,
  inViewColors,
  shouldFire = true,
) => {
  const { ref, inView } = useInView({
    threshold,
    rootMargin,
    skip: !shouldFire,
  });
  const prevInView = useRef(false);
  const isMobile = useMobile('md');

  const { setNavbarStyles } = useContext(NavbarContext);
  const {
    lodgeData: { lodgeColor },
  } = useContext(LodgeContext);

  useEffect(() => {
    if (inView && !prevInView.current) {
      setNavbarStyles(inViewColors);
    } else if (!inView && prevInView.current) {
      setNavbarStyles({
        logoColor: lodgeColor ? lodgeColor : theme.colors.brandBrown,
        secondaryButtonColor: theme.colors.textDark,
        ...(isMobile ? { bg: theme.colors.bgLighter, opacity: 0.95 } : {}),
      });
    }
    prevInView.current = inView;
  }, [inView, inViewColors, lodgeColor, setNavbarStyles]);

  return ref;
};

export default useSetNavbarStylesOnPresence;
