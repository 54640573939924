import React from "react"
import { Box, Card } from "@singita/components"
import { getLinkProps, mapperWithFunction } from "../common/utils"
import { CONTENT_TYPES } from "../common/constants"
import ImageWrapper from "../modules/ImageWrapper"
import { useContentModal } from "gatsby-singita-theme/src/hooks"

const CARD_MAP = {
  [CONTENT_TYPES.PERSON]: {
    variant: () => "small",
    headline: ["name"],
    description: ["role"],
    image: value => {
      return value.image ? <ImageWrapper image={value.image} /> : null
    },
    isLink: () => true,
    onClick: ({ toggleModal, ...props }) => {
      const {
        linkProps: { onClick },
      } = getLinkProps(props, toggleModal)
      return onClick
    },
  },
}

const CardWrapper = ({ section }) => {
  const { toggleModal } = useContentModal()
  const { onClick, ...cardProps } = mapperWithFunction(
    { ...section, toggleModal },
    CARD_MAP[section.__typename]
  )

  return (
    <Box sx={{ cursor: "pointer" }} onClick={onClick}>
      <Card {...cardProps} />
    </Box>
  )
}

export default CardWrapper
