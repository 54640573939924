import { CONTENT_TYPES } from "./constants"
import { getFluidGatsbyImage } from "gatsby-singita-theme/src/common/images"

const formatMedia = (
  assets,
  options = { maxWidth: 1200 },
  videosAsThumbnails = false
) => {
  if (!assets) return []

  return assets.map(asset => {
    if (asset.type === "video") {
      return {
        ...asset,
        __typename: videosAsThumbnails
          ? CONTENT_TYPES.VIDEO_THUMBNAIL
          : CONTENT_TYPES.VIDEO_INLINE,
      }
    }

    let source = asset.source.replace(
      /https:\/\/library-admin.singita.com\/wp-content\/uploads\/\d{4}\/\d{2}\//g,
      "library/wp-content/uploads/"
    )

    source = source.replace("https://singitacdn.s3.eu-west-1.amazonaws.com", "")
    return {
      ...asset,
      fluid: getFluidGatsbyImage(
        {
          ...asset,
          source,
        },
        options
      ),
      __typename: CONTENT_TYPES.IMAGE,
    }
  })
}

const formatAssets = assets => {
  if (!assets) return []

  return assets.map(a => ({ ...a, __typename: CONTENT_TYPES.IMAGE }))
}

export const formatImages = (
  galleries = [],
  options,
  videosAsThumbnails = false
) => {
  const result = []

  if (!galleries) return result

  galleries.forEach(gallery => {
    const assets = [
      ...formatAssets(gallery.assets),
      ...formatMedia(gallery.media, options, videosAsThumbnails),
    ]

    if (!assets.length) return

    const obj = {
      id: gallery.id,
      headline: gallery.headline,
      assets: assets,
      image: assets[0],
      desc: assets[0].desc || "",
      __typename: CONTENT_TYPES.IMAGE,
    }

    result.push(obj)
  })

  return result
}
