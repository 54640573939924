import React, { useState } from "react"
import { graphql } from "gatsby"

import SectionGrid from "../components/SectionGrid"

const SectionGridLodgesPrivateVillasWrapper = ({
  headline,
  headlineLineTwo,
  description,
  lodges,
  filter,
  isSearchable,
  styles = {},
}) => {
  const [filteredItems, setFilteredItems] = useState(lodges.items)

  return (
    <SectionGrid
        headline={headline}
        headlineLineTwo={headlineLineTwo}
        description={description ? description.description : null}
        direction={styles ? styles.direction || "column" : "column"}
        filter={filter}
        isSearchable={isSearchable}
        allItems={lodges.items}
        filteredItems={filteredItems}
        setFilteredItems={setFilteredItems}
        styles={styles}
      />
  )
}

export default SectionGridLodgesPrivateVillasWrapper

export const sectionGridLodgesPrivateVillasFields = graphql`
  fragment SectionGridLodgesPrivateVillasFields on ContentfulLayoutSectionGridLodgesPrivateVillas {
    headline
    headlineLineTwo
    description {
      description
    }
    lodges {
      items {
        __typename
        ...LodgeCardFields
        color
        privateVillaContent {
          headline
          image {
            fluid(quality: 85, maxWidth: 930, maxHeight: 1200) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          content {
            raw
            references {
              ...RichTextReferences
            }
          }
        }
      }
    }
    filter
    styles {
      gridTemplateColumns
      # direction
    }
    isSearchable
  }
`
