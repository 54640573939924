import React, { useState } from "react"
import { graphql } from "gatsby"
import { Box } from "@singita/components"
import styled from "@emotion/styled"
import SectionGrid from "../components/SectionGrid"
import CardPosterWrapper from "./CardPosterWrapper"
import GridItem from "../components/GridItem"

const StyledSpecialItem = styled(Box)`
  -ms-grid-column: 1;
  -ms-grid-column-span: ${({ columns }) => columns};
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
`

const SectionGridLodgesWrapper = ({
  headline,
  headlineLineTwo,
  description,
  lodges,
  filter,
  filterPosition,
  isSearchable,
  styles = {},
  linkTo,
  display,
  poster,
}) => {
  const [filteredItems, setFilteredItems] = useState(lodges.items)

  let renderSpecialItems
  if (poster) {
    const { columns } = styles
    const maxCols =
      typeof columns === "number"
        ? columns
        : columns?.length
        ? columns[columns.length - 1]
        : 1
    renderSpecialItems = () => (
      <StyledSpecialItem
        sx={{
          gridColumn: ["1 / -1"],
          gridRow: ["3 / 5"],
        }}
        columns={maxCols}
      >
        <GridItem index={0}>
          <CardPosterWrapper {...poster} />
        </GridItem>
      </StyledSpecialItem>
    )
  }

  return (
    <SectionGrid
      headline={headline}
      headlineLineTwo={headlineLineTwo}
      description={description ? description.description : null}
      direction={styles ? styles.direction || "column" : "column"}
      filter={filter}
      isSearchable={isSearchable}
      allItems={lodges.items}
      filteredItems={filteredItems}
      setFilteredItems={setFilteredItems}
      filterPosition={filterPosition}
      //filterHeading="Choose Region"
      styles={styles}
      linkTo={linkTo}
      display={display}
      renderSpecialItems={renderSpecialItems}
    />
  )
}

export default SectionGridLodgesWrapper

export const sectionGridLodgesFields = graphql`
  fragment SectionGridLodgesFields on ContentfulLayoutSectionGridLodges {
    headline
    headlineLineTwo
    description {
      description
    }
    lodges {
      __typename
      ... on ContentfulContentLodges {
        items {
          __typename
          ...LodgeCardFields
        }
      }
      ... on ContentfulRegion {
        items: lodge {
          __typename
          ...LodgeCardFields
        }
      }
    }
    filter
    styles {
      columns
      gridTemplateColumns
      # direction
    }
    isSearchable
    filterPosition
    linkTo
    display
    poster {
      ...CardPosterFields
    }
  }
`
