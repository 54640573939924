import React, { createContext, useCallback, useState } from 'react';
import { ALERT_TYPES } from '../../../singita-web/src/common/constants';

const AlertsContext = createContext({
  alerts: [],
  bannerAlerts: [],
  addAlert: () => {},
  removeAlert: () => {},
  key: '',
  updateKey: () => {},
});

const AlertsProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);
  const [bannerAlerts, setBannerAlerts] = useState([]);
  const [, setKey] = useState('');

  const addAlert = useCallback(
    (alert) => {
      const newAlertsFn = (alerts) => {
        const existing = alerts.find(({ id }) => alert.id === id);
        if (existing) {
          // do not add again
          return alerts;
        } else {
          return [...alerts, alert];
        }
      };
      if (alert.type === ALERT_TYPES.POPUP) {
        setAlerts(newAlertsFn);
      } else if (alert.type === ALERT_TYPES.BANNER) {
        setBannerAlerts(newAlertsFn);
      }
    },
    [setAlerts],
  );

  const removeAlert = useCallback(
    (id) => {
      const newAlerts = alerts.filter((alert) => alert.id !== id);
      setAlerts(newAlerts);
    },
    [setAlerts],
  );

  const updateKey = useCallback(() => {
    setKey(Math.random());
  }, [setKey]);

  return (
    <AlertsContext.Provider
      value={{ alerts, bannerAlerts, addAlert, removeAlert, updateKey }}
    >
      {children}
    </AlertsContext.Provider>
  );
};

export { AlertsContext, AlertsProvider };
