import React from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "@singita/components"

const inViewAnimation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      type: "spring",
      damping: 15,
      stiffness: 50,
    },
  },
}

const AnimateInView = ({ children, threshold = 0.3, ...rest }) => {
  const { ref, inView } = useInView({ threshold })

  return (
    <motion.div
      ref={ref}
      variants={inViewAnimation}
      initial="initial"
      animate={inView ? "animate" : "initial"}
      {...rest}
    >
      {children}
    </motion.div>
  )
}

export default AnimateInView
