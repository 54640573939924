import queryString from "query-string"
import { getUpdatedField } from "../utils/helpers"
import { ACTIONS, STEPS } from "./constants"
import moment from "moment"

const DATE_FORMAT = "YYYY-MM-DD"

export const initialValue = {
  currentStep: STEPS.REGIONS,
  regionsList: [],
  form: {
    additionalLodges: [],
    regions: [],
    lodges: [],
    startDate: moment.utc().add(10, "days"),
    endDate: moment.utc().add(15, "days"),
    guests: {
      adults: 2,
      children: 0,
      toddler: 0,
      baby: 0,
    },
  },
  errors: {},
  summary: {
    regions: [],
    lodges: [],
  },
}

export const availabilityReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.STEP: {
      const { step } = action.payload

      if (state.currentStep === STEPS.DATES) {
        if (!state.form.startDate || !state.form.endDate) {
          const errors = {
            startDate: !state.form.startDate,
            endDate: !state.form.endDate,
          }
          return { ...state, errors: { ...state.errors, ...errors } }
        }
      }

      if (step === STEPS.CHECK_AVAILABILITY) {
        const {
          form: {
            lodges,
            regions,
            guests: { adults, children, toddler, baby },
            startDate,
            endDate,
          },
        } = state

        const values = queryString.stringify(
          {
            lodges,
            regions,
            adults,
            children,
            toddler,
            baby,
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT),
          },
          { arrayFormat: "bracket" }
        )

        window.location.href = `https://availability.singita.com/search?${values}`
        return state
      }

      return { ...state, currentStep: step, errors: {} }
    }
    case ACTIONS.UPDATE_REGIONS:
      const { region } = action.payload
      return getUpdatedField("regions", region, state)
    case ACTIONS.UPDATE_LODGES:
      const { lodge } = action.payload
      return getUpdatedField("lodges", lodge, state)
    case ACTIONS.UPDATE_DATES:
      const {
        dates: { startDate, endDate },
      } = action.payload
      return {
        ...state,
        form: {
          ...state.form,
          startDate,
          endDate,
        },
        errors: {},
      }
    case ACTIONS.UPDATE_GUESTS:
      const { step, key } = action.payload
      return {
        ...state,
        form: {
          ...state.form,
          guests: {
            ...state.form.guests,
            [key]: state.form.guests[key] + step,
          },
        },
        errors: {},
      }
    case ACTIONS.RESET:
      return action.payload
    default:
      return state
  }
}
