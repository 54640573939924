import React from "react"

import { DatePicker } from "@singita/components"
import { Box } from "@singita/components"

const Dates = ({ form, setDates }) => {
  const { startDate, endDate } = form
  return (
    <Box sx={{ height: "350px" }}>
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        setDates={dates => setDates(dates)}
      />
    </Box>
  )
}

export default Dates
