import React from "react"
import { Box, Modal, theme } from "@singita/components"
import RichTextRenderer from "./RichTextRenderer"
import styled from "@emotion/styled"
import { useContentModal, useLockScroll } from "gatsby-singita-theme/src/hooks"
import ImageWrapper from "../modules/ImageWrapper"

const StyledModalX = styled(Box)`
  input,
  textarea,
  select,
  .DateRangePickerInput,
  .react-select__control {
    background-color: ${theme.colors.bgLighter};
  }

  .react-select__multi-value {
    background-color: white;
  }
`

const ContentModal = () => {
  const { isOpen, item, toggleModal } = useContentModal()

  useLockScroll(isOpen)

  return (
    <Modal
      isOpen={isOpen}
      handleClose={toggleModal}
      size="medium"
      sx={{ zIndex: "modalContent" }}
      title={item?.headline}
    >
      {item && (
        <>
          {item?.image && (
            <Box
              height={["230px"]}
              width={["180px"]}
              sx={{ float: ["none", "none", "left", "left"] }}
              mr={[4]}
              mb={[2]}
            >
              <ImageWrapper
                image={item.image}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </Box>
          )}
          <StyledModalX>
            <RichTextRenderer content={item.content} />

            {item.render ? item.render() : null}
          </StyledModalX>
        </>
      )}
    </Modal>
  )
}

export default ContentModal
