import React from "react"
import { Heading, Box } from "@singita/components"

const FormTitle = ({ title }) => {
  if (!title) return null
  return (
    <Box mb={[3]} mt={[4]}>
      <Heading
        size="h6"
        as="h6"
        mb={"1rem"}
        fontFamily="sans"
        fontWeight="bold"
      >
        {title}
      </Heading>
    </Box>
  )
}

export default FormTitle
