import React, { useMemo } from "react"
import styled from "@emotion/styled"
import { Box, GridContainer } from "@singita/components"
import { Text, theme } from "@singita/components"
import { Translation } from "react-i18next"
import { getSeasonRate } from "../common/utils"

const SEASONS = {
  PEAK: "Peak",
  SHOULDER: "Shoulder",
  OFF_PEAK: "Off-Peak",
}

const seasonsMap = {
  PR: SEASONS.PEAK,
  SR: SEASONS.SHOULDER,
  OPR: SEASONS.OFF_PEAK,
}

const seasonDescriptionsMap = {
  PR: "prDescription",
  SR: "srDescription",
  OPR: "oprDescription",
}

const Cell = styled(Box)`
  border-bottom: 1px solid ${theme.colors.baseGrayLight};
  border-right: 1px solid ${theme.colors.baseGrayLight};
  padding: ${theme.space[2]}px;
  -ms-grid-column: ${({ index }) => (index % 5) + 1};
  -ms-grid-row: ${({ index }) => Math.floor(index / 5) + 1};
`

function generateColumnsForSeasons(hasSeasons, columnDefinition) {
  return Object.values(hasSeasons)
    .filter(val => val)
    .map(() => {
      return columnDefinition
    })
    .join(" ")
}

const RatesTable = ({ lodge, yearFilter, colorScheme }) => {
  const {
    region: { seasonDates },
    units,
  } = lodge

  const groupedUnits = useMemo(() => {
    if (!lodge) return {}

    return units.map((unit, index) => {
      const { rates, unitRates } = unit
      if (!rates && !unitRates) return null

      const hasUnitRates = unitRates && Object.keys(unitRates).length > 0
      if (hasUnitRates) {
        return unitRates.rates
          .filter(({ year }) => year.toString() === yearFilter.toString())
          .reduce((acc, next) => {
            const {
              prDescription,
              oprDescription,
              srDescription,
              ...seasonFields
            } = next
            Object.keys(seasonFields)
              .filter(ss => !!seasonFields[ss])
              .forEach(ss => {
                const season = seasonsMap[ss]
                if (
                  !season &&
                  !seasonDescriptionsMap[ss] &&
                  !next[seasonDescriptionsMap[ss]]
                )
                  return acc
                const rates = getSeasonRate(
                  unitRates.currency,
                  seasonFields[ss],
                  unit.hasChildRates,
                  seasonDescriptionsMap[ss]
                    ? next[seasonDescriptionsMap[ss]]
                    : null,
                  unit.ratesSuffix
                )
                acc[season] = rates
              })
            return acc
          }, {})
      }
      return rates
        .filter(({ year }) => year.toString() === yearFilter.toString())
        .reduce((acc, { season, rates }) => {
          acc[season] = rates
          return acc
        }, {})
    })
  }, [lodge, yearFilter, units])

  const filteredDates = (seasonDates || [])
    .filter(({ year }) => year.toString() === yearFilter.toString())
    .reduce((acc, val) => {
      acc[val.season] = val.dates
      return acc
    }, {})

  const hasUnits = units.some(({ numberOfUnits }) => Boolean(numberOfUnits))
  const hasSeasons = Object.values(SEASONS).reduce((acc, season) => {
    acc[season] = groupedUnits.some(unit => unit && season in unit)
    return acc
  }, {})

  let cellIdx = 0

  return lodge ? (
    <GridContainer
      sx={{
        gridTemplateColumns: [
          `minmax(150px, 250px) ${
            hasUnits ? "80px" : ""
          } ${generateColumnsForSeasons(hasSeasons, "minmax(150px, 250px)")}`,
          `1fr ${hasUnits ? "80px" : ""} ${generateColumnsForSeasons(
            hasSeasons,
            "1fr"
          )}`,
        ],
        borderTop: `1px solid ${theme.colors.baseGrayLight}`,
        borderLeft: `1px solid ${theme.colors.baseGrayLight}`,
        overflowX: "auto",
      }}
    >
      <Cell index={cellIdx++} />
      {hasUnits && (
        <Cell index={cellIdx++}>
          <Text size="body" fontWeight="bold">
            <Translation>{t => t(`rates.units`)}</Translation>
          </Text>
        </Cell>
      )}
      {Object.values(SEASONS).map(season =>
        hasSeasons[season] ? (
          <Cell index={cellIdx++}>
            <Box>
              <Text
                size="body"
                fontWeight="bold"
                color={colorScheme ?? "brandBrown"}
              >
                {yearFilter}
              </Text>
            </Box>
            <Box>
              <Text size="body" fontWeight="bold">
                {filteredDates[season]}
              </Text>
            </Box>
          </Cell>
        ) : null
      )}
      {units
        .filter(({ isHiddenOnRatesTable }) => !isHiddenOnRatesTable)
        .map((unit, idx) => (
          <>
            <Cell index={cellIdx++}>
              <Text size="body" fontWeight="bold">
                {unit.headline}
              </Text>
              <br />
              <Text size="small">{unit.subtitle}</Text>
            </Cell>
            {hasUnits && (
              <Cell index={cellIdx++}>
                <Text size="body">{unit.numberOfUnits}</Text>
              </Cell>
            )}
            {/* TODO: need to filter rates for current year filter, and then group into seasons */}
            {Object.values(SEASONS).map(season => {
              const rates = groupedUnits[idx] ? groupedUnits[idx][season] : null
              return hasSeasons[season] ? (
                <Cell index={cellIdx++}>
                  {rates && (
                    <Text>
                      {rates.split("\n").map(rate => (
                        <>
                          {rate}
                          <br />
                        </>
                      ))}
                    </Text>
                  )}
                </Cell>
              ) : null
            })}
          </>
        ))}
    </GridContainer>
  ) : null
}

export default RatesTable
