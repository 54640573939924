import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react"
import { Filter, FilterItem, Input, theme } from "@singita/components"
import { get } from "lodash"
import { Box, Flex } from "@singita/components"
import { Media } from "gatsby-singita-theme/src/Media"
import { CONTENT_TYPES } from "../common/constants"
import { LodgeContext } from "gatsby-singita-theme/src/context/LodgeContext"

export const FILTER_TYPES = {
  LODGE: "Lodge",
  STAFF: "Staff",
  COUNTRY: "Country",
  AREA: "Area",
  CATEGORY: "Category",
  STAFFCATEGORY: "StaffCategory",
}

function getPath(item, map) {
  let path
  if (typeof map === "function") {
    path = map(item)
  } else {
    path = map
  }
  return path
}

function getFilterFunc(map) {
  return (items, filter) => {
    if (filter) {
      if (filter === "all") return items
      return items.filter(item => {
        const path = getPath(item, map)
        if (typeof path === "object") {
          return path.includes(filter)
        } else {
          return get(item, path) === filter
        }
      })
    }

    const options = items
      .map(item => {
        const path = getPath(item, map)

        return path
      })
      .reduce((acc, val) => acc.concat(val), [])

    return [...new Set(options)]
  }
}

const FILTER_MAP = {
  [FILTER_TYPES.LODGE]: value => {
    return [get(value, "region.headline")]
  },
  [FILTER_TYPES.STAFF]: value => {
    return [get(value, "personCategory.name")]
  },
  [FILTER_TYPES.STAFFCATEGORY]: value => {
    return [get(value, "personRole.name")]
  },
  [FILTER_TYPES.COUNTRY]: value => {
    if (get(value, "region")) {
      return [get(value, "region.country")]
    } else if (get(value, "regions")) {
      return value.regions.map(region => region.country)
    }
  },
  [FILTER_TYPES.AREA]: value => {
    return [get(value, "area.name")]
  },
  [FILTER_TYPES.CATEGORY]: value => {
    return [get(value, "category")]
  },
}

const SEARCH_FIELD = {
  [CONTENT_TYPES.LODGE]: "headline",
  [CONTENT_TYPES.PERSON]: "name",
}

const FilterWrapper = ({
  items,
  filter,
  onSetFilter,
  isVertical,
  isSearchable,
  activeColor,
  includeAll = true,
  shouldSort = false,
}) => {
  const filterFunc = useMemo(() => getFilterFunc(FILTER_MAP[filter]), [filter])
  let options = filterFunc(items)
  if (shouldSort) {
    options = options.sort()
  }

  const [activeFilter, setActiveFilter] = useState(
    includeAll ? "all" : options[0]
  )
  const [searchTerm, setSearchTerm] = useState("")

  const onFilterChange = filter => {
    let filteredItems = filterFunc(items, filter)
    if (searchTerm) {
      filteredItems = filteredItems.filter(filterBySearchTerm)
    }
    onSetFilter(filteredItems)
  }

  useEffect(() => {
    onFilterChange(activeFilter)
  }, [activeFilter])

  const {
    lodgeData: { lodgeColor },
  } = useContext(LodgeContext)

  const filterBySearchTerm = useCallback(
    item => {
      const searchField = SEARCH_FIELD[item.__typename] || "headline"
      const regex = new RegExp(searchTerm, "i")
      return item[searchField].match(regex)
    },
    [searchTerm]
  )

  const onChange = filter => {
    setActiveFilter(filter)
  }
  const onSearchChange = event => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    if (isSearchable) {
      const filteredItems = filterFunc(items, activeFilter).filter(
        filterBySearchTerm
      )
      onSetFilter(filteredItems)
    }
  }, [
    searchTerm,
    activeFilter,
    filterBySearchTerm,
    filterFunc,
    isSearchable,
    items,
    onSetFilter,
  ])

  const color = lodgeColor ?? activeColor ?? theme.colors.brandBrown

  const filterOptions = options.map((item, idx) =>
    item ? (
      <FilterItem
        key={`${item}-${idx}`}
        fontWeight="bold"
        active={activeFilter === item}
        onClick={() => onChange(item)}
        isVertical={isVertical}
        key={`filter-item-${idx}`}
        activeColor={color}
      >
        {item}
      </FilterItem>
    ) : null
  )

  const filterItems = (
    <>
      {includeAll && (
        <FilterItem
          fontWeight="bold"
          active={activeFilter === "all"}
          onClick={() => onChange("all")}
          isVertical={isVertical}
          activeColor={color}
        >
          All
        </FilterItem>
      )}
      {filterOptions}
    </>
  )

  return (
    <Flex
      flexDirection={["column", "column", "row"]}
      justifyContent="space-between"
      alignItems={
        isVertical ? "flex-start" : ["flex-start", "flex-start", "center"]
      }
    >
      <Box width={[1]} sx={{ order: [1, 1, 0] }} mr={[isVertical ? 0 : 2]}>
        <Media greaterThanOrEqual="lg">
          <Filter color={color} isVertical={isVertical}>
            {filterItems}
          </Filter>
        </Media>
        <Media lessThan="lg">
          <Filter color={color} isVertical={false} hasScrollOverflow={true}>
            {filterItems}
          </Filter>
        </Media>
      </Box>
      {isSearchable && (
        <Input
          type="input"
          placeholder="Search..."
          onChange={onSearchChange}
          sx={{ width: "300px", flexShrink: 0, order: [0, 0, 1] }}
          bg="baseWhite"
        />
      )}
    </Flex>
  )
}

export default FilterWrapper
