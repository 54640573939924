import React, { useContext } from 'react';
import { theme, Flex } from '@singita/components';
import Link from '../Link';
import { FadeIn, StaggerContainer, StaggerItem } from './animations';
import MenuSocial from './MenuSocial';
import { ResourceSetContext } from '../../context/ResourceSetContext';
import MenuContact from './MenuContact';
import { useLanguage } from '../../context/LanguageContext';

const StaggeredLinks = ({
  items = [],
  setIsOpen,
  currentPath,
  ...restProps
}) => {
  if (!items) return null;

  return items
    ? items.node.menuItems &&
        items.node.menuItems.length > 0 &&
        items.node.menuItems.map((link) => (
          <StaggerItem
            mb={[2]}
            key={link.id}
            onClick={() =>
              currentPath === `/${link.slug}` ? setIsOpen(false) : null
            }
          >
            <Link {...link} {...restProps} />
          </StaggerItem>
        ))
    : null;
};

const MenuSecondary = ({
  menus,
  isOpen,
  setIsOpen,
  currentPath,
  renderAfterSecondary,
  colorScheme = 'white',
  color = 'textWhite',
}) => {
  const context = useContext(ResourceSetContext);
  const lang = useLanguage();

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      sx={{ height: ['auto', '100%'] }}
    >
      <Flex mb={[8, 8, 8, 0]}>
        {menus.secondary && (
          <StaggerContainer
            shouldAnimate={isOpen}
            display="flex"
            flexDirection="column"
            width={[1]}
            pr={2}
            data-testid="subMenu"
          >
            <StaggeredLinks
              items={menus.secondary}
              colorScheme={colorScheme}
              variant="text"
              labelKey={'headline'}
              fontWeight="normal"
              setIsOpen={setIsOpen}
              currentPath={currentPath}
            />
          </StaggerContainer>
        )}

        {menus.feature && (
          <StaggerContainer shouldAnimate={isOpen} width={[1]} pl={1}>
            <StaggeredLinks
              items={menus.feature}
              colorScheme={colorScheme}
              iconPosition="left"
              fontWeight="light"
            />
          </StaggerContainer>
        )}
      </Flex>
      {renderAfterSecondary ? renderAfterSecondary() : null}
      <FadeIn mb={[6, 6, 6, 0]} shouldAnimate={isOpen} delay={2}>
        <MenuContact
          resources={context.getResourcesForComponent(
            'contactInfo',
            lang ?? 'en-US',
          )}
          contactMenu={menus.contact}
          data-testid={'contact'}
          color={theme.colors[color]}
          buttonColor={theme.colors[color]}
        />
      </FadeIn>
      <FadeIn
        width={[6 / 12]}
        alignItems={['flex-start']}
        data-testid="social"
        sx={{ display: ['flex', 'flex', 'flex', 'none'] }}
        shouldAnimate={isOpen}
        delay={2}
      >
        <MenuSocial
          resources={context.getResourcesForComponent(
            'social',
            lang ?? 'en-US',
          )}
          color={theme.colors[color]}
          opacity={theme.opacity.full}
          linkPrefix="MenuSecondary"
        />
      </FadeIn>
    </Flex>
  );
};

export default MenuSecondary;
