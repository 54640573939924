import React, { useCallback, useContext, useRef } from "react"
import { Box, Flex } from "@singita/components"
import styled from "@emotion/styled"
import { initialValue } from "../common/reducer"
import {
  ActionBar,
  Text,
  Button,
  DatePicker,
  Popover,
  theme,
} from "@singita/components"
import { ACTIONS, STEPS } from "../common/constants"
import { getStepComponent } from "gatsby-singita-theme/src/components/Availability/utils/helpers"
import { graphql } from "gatsby"
import { useActionBar, useOutsideClick } from "gatsby-singita-theme/src/hooks"
import AvailabilityContext from "gatsby-singita-theme/src/context/AvailabilityContext"
import { LodgeContext } from "gatsby-singita-theme/src/context/LodgeContext"
import useAvailability from "gatsby-singita-theme/src/components/Availability/common/useAvailability"
import { useLanguage } from "gatsby-singita-theme/src/context/LanguageContext"

const FieldContainer = styled(Box)`
  position: relative;
  border: 1px solid;
  border-color: ${theme.colors.transparent} ${theme.colors.transparent}
    ${theme.colors.transparent} ${theme.colors.borderDark};
  height: 100%;
  min-width: 250px;
  max-width: 325px;

  ${({ focused }) =>
    focused
      ? `border-color: ${theme.colors.borderDark}; border-radius: 4px;`
      : ""}

  ${({ error }) =>
    error
      ? `border-color: ${theme.colors.statusError}; border-radius: 4px;`
      : ""}
`

const PopoverContainer = styled(Box)`
  left: 0;
  width: 100%;
  bottom: calc(100% + 8px);
  padding-top: 5px;
  position: absolute;
`

const FieldBox = ({
  isOpen,
  label,
  onClick,
  renderPopover,
  closePopover,
  children,
  error,
}) => {
  const ref = useRef()
  useOutsideClick(ref, isOpen, () => closePopover())

  return (
    <FieldContainer focused={isOpen} error={error} mr={[1]}>
      <Flex
        px={[2]}
        pt={[1]}
        onClick={onClick}
        flexDirection="column"
        height="100%"
        sx={{ cursor: renderPopover ? "pointer" : "default" }}
      >
        <Text
          size="extraSmall"
          fontWeight="bold"
          sx={{ textTransform: "uppercase", mb: "4px" }}
        >
          {label}
        </Text>
        {children}
      </Flex>
      {renderPopover && isOpen && (
        <PopoverContainer>
          <Popover ref={ref} sx={{ p: [2] }}>
            {renderPopover}
          </Popover>
        </PopoverContainer>
      )}
    </FieldContainer>
  )
}

function getPropsForStep(
  step,
  currentStep,
  form,
  regions,
  summary,
  dispatch,
  colorScheme,
  codes
) {
  switch (step) {
    case STEPS.REGIONS:
      return {
        children: (
          <Text size="tiny" color="textLight">
            {summary.regions.length > 0
              ? summary.regions.join(", ")
              : "All Regions"}
          </Text>
        ),
        label: "Regions",
        renderPopover: getStepComponent(
          step,
          regions,
          form,
          summary,
          dispatch,
          colorScheme,
          codes
        ),
      }
    case STEPS.LODGES:
      return {
        children: (
          <Text size="tiny" color="textLight">
            {summary.lodges.length > 0
              ? summary.lodges.join(", ")
              : "All Lodges"}
          </Text>
        ),
        label: "Lodges",
        renderPopover: getStepComponent(
          step,
          regions,
          form,
          summary,
          dispatch,
          colorScheme,
          codes
        ),
      }
    case STEPS.DATES:
      return {
        children: (
          <DatePicker
            startDate={form.startDate}
            endDate={form.endDate}
            setDates={dates =>
              dispatch({ type: ACTIONS.UPDATE_DATES, payload: { dates } })
            }
            openDirection="up"
            variant="minimal"
            isOpen={step === currentStep}
            colorScheme={colorScheme}
          />
        ),
        label: "Dates",
      }
    case STEPS.GUESTS:
      return {
        children: (
          <Text size="tiny" color="textLight">
            {form.guests.adults} Guests
          </Text>
        ),
        label: "Guests",
        renderPopover: getStepComponent(
          step,
          regions,
          form,
          summary,
          dispatch,
          colorScheme,
          codes
        ),
      }
    // no default
  }
}

const AvailabilityActionBar = ({
  headline,
  description,
  codes,
  colorScheme,
}) => {
  const { state, dispatch, data, error } = useAvailability(codes, true, {
    ...initialValue,
    currentStep: null,
  })
  const { form, summary, currentStep, errors } = state

  const {
    lodgeData: { lodgeColor },
  } = useContext(LodgeContext)

  const closePopover = useCallback(
    () => dispatch({ type: ACTIONS.STEP, payload: { step: null } }),
    [dispatch]
  )
  const setStep = useCallback(
    step => {
      if (currentStep && step === currentStep) {
        dispatch({ type: ACTIONS.STEP, payload: { step: null } })
      } else {
        dispatch({ type: ACTIONS.STEP, payload: { step } })
      }
    },
    [currentStep, dispatch]
  )
  const [ref, isMobile] = useActionBar("xl")
  const { setShowAvailability } = useContext(AvailabilityContext)

  const steps = Object.values(STEPS)
  const lastStep = steps.indexOf(STEPS.GUESTS) + 1

  const color = colorScheme || lodgeColor || theme.colors.brandBrown

  // If the page is translated, do not display the booking calendar
  const lang = useLanguage()
  if (lang !== "en-US") return null

  if (error) {
    return null
  }

  return (
    <ActionBar
      ref={ref}
      headline={isMobile ? headline || "Plan your trip" : null}
      description={isMobile && description ? description.description : null}
      isSticky={true}
      color={color}
    >
      {isMobile ? (
        <Button
          variant="primary"
          colorScheme={color}
          onClick={() => setShowAvailability(true)}
        >
          Plan your trip
        </Button>
      ) : (
        <Flex width="100%" justifyContent="center" alignItems="center">
          {steps.slice(0, lastStep).map(step => {
            const stepProps = getPropsForStep(
              step,
              currentStep,
              form,
              data ? data.findRegions : null,
              summary,
              dispatch,
              color,
              codes
            )

            const hasErrors =
              step === STEPS.DATES && (!form.startDate || !form.endDate)

            return (
              <FieldBox
                isOpen={currentStep === step}
                onClick={() => setStep(step)}
                closePopover={closePopover}
                error={hasErrors}
                {...stepProps}
              />
            )
          })}
          <Box ml={[2]}>
            <Button
              variant="primary"
              colorScheme={color}
              onClick={() => {
                if (form.startDate && form.endDate) {
                  dispatch({
                    type: ACTIONS.STEP,
                    payload: {
                      step: STEPS.CHECK_AVAILABILITY,
                      // NOTE:Removed GTM event in reducer. Will not trigger
                      dataLabel: "check_availability_action_bar",
                    },
                  })
                }
              }}
            >
              Check availability
            </Button>
          </Box>
        </Flex>
      )}
    </ActionBar>
  )
}

export default AvailabilityActionBar

export const actionbarBookingFields = graphql`
  fragment ActionbarBookingFields on ContentfulActionbarBookingForm {
    contentful_id
    name
    headline
    description {
      description
    }
  }
`
