import React from 'react';
import { graphql } from 'gatsby';
import { Box } from '@singita/components';
import { getLinkProps } from '../common/utils';
import useContentModal from '../hooks/useContentModal';

const Link = (props) => {
  const { containerSx = {} } = props;
  const { toggleModal } = useContentModal();
  const { linkProps = {}, children } = getLinkProps(props, toggleModal);

  return (
    <Box {...linkProps} sx={containerSx}>
      {children}
    </Box>
  );
};

export default Link;

export const pageLinkFields = graphql`
  fragment PageLinkFields on ContentfulPages {
    slug
    structuredData {
      headline
      menuLabel
    }
  }
`;

export const articleLinkFields = graphql`
  fragment ArticleLinkFields on ContentfulArticles {
    slug
    date: publishDate(formatString: "MMMM YYYY")
    month: publishDate(formatString: "MM")
    year: publishDate(formatString: "YYYY")
  }
`;

export const lodgeLinkFields = graphql`
  fragment LodgeLinkFields on ContentfulLodge {
    slug
    structuredData {
      headline
    }
  }
`;

export const projectLinkFields = graphql`
  fragment ProjectLinkFields on ContentfulProjects {
    slug
    structuredData {
      headline
    }
  }
`;

export const promotionsLinkFields = graphql`
  fragment PromotionsLinkFields on ContentfulPromotions {
    slug
    structuredData {
      headline
    }
  }
`;

export const linkFields = graphql`
  fragment LinkFields on ContentfulLinks {
    __typename
    contentful_id
    label
    icon
    destination {
      __typename
      ...AllLinks
    }
    to
    type
    file {
      file {
        url
      }
    }
    target
  }
`;

export const allLinks = graphql`
  fragment AllLinks on Node {
    __typename
    ...PageLinkFields
    ...ArticleLinkFields
    ...LodgeLinkFields
    ...ProjectLinkFields
    ...PromotionsLinkFields
    ...ContentLinkFields
  }
`;
