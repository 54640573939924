import React from 'react';
import { Box } from '@singita/components';
import { getUniqueCountries } from '../utils/helpers';
import { Text, Choice, Border } from '@singita/components';

const Regions = ({ regions, form, updateRegions, colorScheme, matchCode }) => {
  const regionCodes = matchCode ? matchCode.split('/') : [];
  const countries = getUniqueCountries(regions);
  return (
    <Box>
      {countries.map((country, idx) => {
        return (
          <Box key={country}>
            <Box mb={[2]}>
              <Text size="tiny" fontWeight="bold">
                {country}
              </Text>
            </Box>
            {regions
              .filter((region) => region.country === country)
              .map((region) => (
                <Box key={region.code} mb={[1]}>
                  <Choice
                    type="checkbox"
                    key={region.code}
                    value={region.code}
                    checked={
                      regionCodes.includes(region.code) ||
                      form.regions.includes(region.code)
                    }
                    label={region.name}
                    orientation="right"
                    onChange={() => updateRegions(region)}
                    color={colorScheme}
                  />
                </Box>
              ))}
            {idx < countries.length - 1 && (
              <Border my={[3]} isContainerised={false} />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default Regions;
