import React from "react"
import { graphql, Link } from "gatsby"
import { Card } from "@singita/components"
import ImageWrapper from "./ImageWrapper"

const GalleryCardWrapper = ({ section }) => {
  const { image, headline, links } = section
  const hasLink = links && links.length
  return (
    <Link to={hasLink ? `/${links[0].slug}` : null}>
      <Card
        variant="standard"
        isLink={hasLink}
        image={<ImageWrapper image={image} />}
        headline={headline}
        imageSx={{ height: ["500px"] }}
      />
    </Link>
  )
}

export default GalleryCardWrapper

export const galleryCardFields = graphql`
  fragment GalleryCardFields on ContentfulContent {
    headline
    image {
      fluid(maxWidth: 500, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    links {
      __typename
      ... on ContentfulPages {
        slug
      }
    }
  }
`
