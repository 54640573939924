import React, { useContext } from "react"
import { ActionBar, Button, Flex, Popover } from "@singita/components"
import { graphql } from "gatsby"
import Link from "../components/Link"
import { Box } from "@singita/components"
import { Media } from "gatsby-singita-theme/src/Media"
import { ModalContext } from "gatsby-singita-theme/src/context/ModalContext"
import { CONSTANTS } from "../common/constants"
import { useLanguage } from "gatsby-singita-theme/src/context/LanguageContext"

const ActionBarWrapper = ({ headline, description, links }) => {
  const { toggleModal } = useContext(ModalContext)
  const { button } = CONSTANTS

  // If the page is translated, do not display the ActionBar
  const lang = useLanguage()
  if (lang !== "en-US") return null

  let children
  if (links.length > 1) {
    children = (
      <>
        <Media greaterThanOrEqual="lg">
          <Flex>
            {links.map(link => (
              <Box mr={[1]}>
                <Link popoverPositionHorizontal="right" {...link} />
              </Box>
            ))}
          </Flex>
        </Media>
        <Media lessThan="lg">
          <Button
            variant="primary"
            popoverPosition="top"
            popoverPositionHorizontal="right"
            renderPopover={() => (
              <Popover>
                {links.map(link => (
                  <Link
                    {...link}
                    popoverPositionHorizontal="right"
                    sx={{ width: "100%" }}
                    maxDepth={1}
                    onClick={toggleModal}
                  />
                ))}
              </Popover>
            )}
          >
            {button.pageMenu}
          </Button>
        </Media>
      </>
    )
  } else {
    children = links.map(link => <Link {...link} />)
  }

  return (
    <ActionBar
      headline={headline && headline}
      description={description && description.description}
    >
      {children}
    </ActionBar>
  )
}

export default ActionBarWrapper

export const ActionBarFields = graphql`
  fragment ActionBarFields on ContentfulActionbar {
    id
    name
    headline
    description {
      description
    }
    links {
      __typename
      ... on Node {
        ...LinkFields
        ... on ContentfulMenu {
          headline
          menuItems {
            __typename
            ...AllLinks
            ...LinkFields
          }
        }
      }
    }
  }
`
