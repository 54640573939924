import axios from "axios"
import resolveResponse from "contentful-resolve-response"
import { forEach, isArray, compact, assign } from "lodash"
import { getContentfulTypenameFromEntry } from "./utils"

const accessToken = process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN
const spaceId = process.env.GATSBY_CONTENTFUL_SPACE_ID
const environmentId = process.env.GATSBY_CONTENTFUL_ENVIRONMENT_ID || "master"
const host = process.env.GATSBY_CONTENTFUL_HOST
const defaultParams = { access_token: accessToken }

const CDN_URL = `https://${host}/spaces/${spaceId}/environments/${environmentId}/entries`

const contentfulClient = axios.create({
  baseURL: CDN_URL,
  params: defaultParams,
})

// will fetch entries given the params, and flattern the response
export async function getEntries(params) {
  const res = await contentfulClient.get("", { params })
  return parseEntries(resolveResponse(res.data))
}

function parseEntries(entries) {
  return entries.map(entry => {
    const model = {
      _model: {},
    }
    assign(model, parseEntry(entry, model._model))
    delete model._model
    return model
  })
}

function parseEntry(entry, model) {
  const meta = getMetadata(entry)
  forEach(entry.fields, (value, key) => {
    if (isArray(value)) {
      model[key] = compact(value.map(item => parseValue(item)))
    } else {
      const parsed = parseValue(value)
      if (key === "file") {
        model = { ...model, ...parsed }
      }
      model[key] = parsed
    }
  })
  return { ...model, ...meta }
}

function parseValue(value) {
  const { nodeType } = value
  if (nodeType && nodeType === "document") {
    return parseRichTextValue(value)
  }

  const { sys } = value
  if (sys === undefined) {
    return value
  }
  const meta = getMetadata(value)

  return { ...dereferenceLink(value), ...meta }
}

function parseRichTextValue(value) {
  const { content } = value
  if (!isArray(content) || !content.length) {
    return value
  }
  return {
    ...value,
    content: parseRichTextContent(content),
    isDynamic: true,
  }
}

function parseRichTextContent(items) {
  return items.map(item => {
    let contentList = item.content

    if (contentList && contentList.length > 0) {
      contentList = parseRichTextContent(contentList)
    }

    if (item.data && item.data.target && item.data.target.sys) {
      return {
        ...item,
        data: { ...item.data, target: dereferenceLink(item.data.target) },
        content: contentList,
        isDynamic: true,
      }
    }
    return {
      ...item,
      content: contentList,
      isDynamic: true,
    }
  })
}

function dereferenceLink(value) {
  if (value._model) {
    return value._model
  }
  value._model = {}
  assign(value, parseEntry(value, value._model))
  delete value._model
  delete value.fields
  return value
}

function getMetadata(entry) {
  const __typename = getContentfulTypenameFromEntry(entry)
  const id = entry.sys.id
  return {
    __typename,
    id,
  }
}
