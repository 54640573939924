import React from 'react';
import { Contact as ContactComponent } from '@singita/components';
import { CONSTANTS } from '../../common/constants';
import Link from '../Link';

// We've decided against adding telephone and email links to the Contact Menu,
// as these values might be referenced in several places and therefore we'd like to use constants instead.
const MenuContact = ({
  resources,
  contactMenu,
  buttonColor = 'brandBrown',
  ...rest
}) => {
  const data = resources ? resources : CONSTANTS.contact;

  const handleGtmEvent = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event,
    });
  };

  const emailNode = (
    <a
      href={`mailto:${data.email?.value}`}
      aria-label={`Send an email to ${data.email?.value}`}
      onClick={() => handleGtmEvent('click_enquire')}
    >
      {`${data.email?.label} ${data.email?.value}`}
    </a>
  );

  const telephoneNode = (
    <a
      href={`tel:${data.telephone?.value}`}
      aria-label={`Call the number ${data.telephone?.value}`}
      onClick={() => handleGtmEvent('click_tel')}
    >
      {`${data.telephone?.label} ${data.telephone?.value}`}
    </a>
  );

  const contactProps = {
    email: emailNode,
    telephone: telephoneNode,
    ...rest,
  };

  return (
    <ContactComponent color={buttonColor} {...contactProps}>
      {contactMenu.node.menuItems.map((link, idx) => (
        <Link
          key={`contact-element-${idx}`}
          {...link}
          label={
            link.slug === 'contact'
              ? data.link?.label
              : link?.headline || link?.label
          }
          colorScheme={buttonColor}
          variant="text"
          icon="chevron-right"
          iconPosition="right"
          size="default"
        />
      ))}
    </ContactComponent>
  );
};

export default MenuContact;
