import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LocaleLink as Link } from 'gatsby-singita-theme/src/components/LocaleLink';

const host = process.env.GATSBY_SITE_URL || 'https://www.singita.com';

const ConditionalLink = React.forwardRef((props, ref) => {
  const { to, ...rest } = props;
  const data = useStaticQuery(graphql`
    query SiteExternalQuery {
      site {
        siteMetadata {
          external
        }
      }
    }
  `);

  if (data.site.siteMetadata.external) {
    return (
      <a
        href={`${host}${to}`}
        rel="noopener noreferrer"
        target="_blank"
        {...rest}
      />
    );
  } else {
    return <Link to={to} {...rest} />;
  }
});

export default ConditionalLink;
