import React, { useContext } from "react"
import { ModalContext } from "gatsby-singita-theme/src/context/ModalContext"
import { Modal } from "@singita/components"
import { useLockScroll } from "gatsby-singita-theme/src/hooks"

const GeneralModal = () => {
  const { isOpen, modalChildren, toggleModal, title } = useContext(ModalContext)

  useLockScroll(isOpen)

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      size="medium"
      handleClose={toggleModal}
    >
      {modalChildren}
    </Modal>
  )
}

export default GeneralModal
