export const FORM_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILED: "failed",
}

export const FORM_LANGUAGE = {
  BUTTON: "Submit",
  WORKING: "Working...",
  FORM_FAILED:
    "Something went wrong. Please contact support or try again later.",
  LOADING: "Loading form...",
  REQUIRED_FIELDS: "Some fields need your attention",
  SHOW_FORM: "Show form again",
}
