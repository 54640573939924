import React from 'react';
import { Accordion, Heading } from '@singita/components';
import { Flex, Box } from '@singita/components';
import { Media } from '../../Media';
import Link from '../Link';

const MenuList = ({ headlineSx, headline, links }) => {
  const linkElements = links.map((link, idx) => (
    <Flex key={`menulist-flex-${idx}`} flexDirection="column">
      <Link
       key={`menulist-link-${idx}`}
        variant={'text'}
        size={'default'}
        colorScheme={'textDark'}
        sx={{ mb: [2] }}
        {...link}
      />
    </Flex>
  ));

  return (
    <>
      <Media greaterThanOrEqual="lg">
        <Box flex="1 0 auto" mb={[2, 2, 2, 0]} pr={[3]}>
          <Box mb={[2]}>
            <Heading size="h7" fontFamily={'variable'} sx={{ ...headlineSx }}>
              {headline}
            </Heading>
          </Box>
          {linkElements}
        </Box>
      </Media>
      <Media lessThan="lg">
        <Box>
          <Accordion headline={headline}>{linkElements}</Accordion>
        </Box>
      </Media>
    </>
  );
};

export default MenuList;
