import React, { useEffect } from 'react';

import { DatePicker, Text } from '@singita/components';
import { Box, Flex } from '@singita/components';
import moment from 'moment';
import { isEqual } from 'lodash';
const Dates = ({ form, setDates, colorScheme, errors = {} }) => {
  const { startDate, endDate } = form;

  let isOutsideRange = (date) => false;
  let _startDate;
  let _endDate;

  if (form.lodges && isEqual(form.lodges, ['TZG-SM'])) {
    isOutsideRange = (date) => date.isBefore(moment('2024-05-16'));
  }

  return (
    <Box sx={{ height: '350px' }}>
      <DatePicker
        startDate={_startDate || startDate}
        endDate={_endDate || endDate}
        setDates={(dates) => setDates(dates)}
        colorScheme={colorScheme}
      />
      <Flex flexDirection="column">
        {errors.startDate ? (
          <Text color="statusError" size="small">
            Please enter a start date.
          </Text>
        ) : null}
        {errors.endDate ? (
          <Text color="statusError" size="small">
            Please enter an end date.
          </Text>
        ) : null}
      </Flex>
    </Box>
  );
};

export default Dates;
