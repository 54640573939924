import { gql } from "@apollo/client"

export const SUBMIT_FORM_MUTATION = gql`
  mutation submitTransactionWebsiteForm($input: InputFormDetails!) {
    submitTransactionWebsiteForm(input: $input) {
      transaction_id
      transaction_date
      hasHash
      hashdata
    }
  }
`
