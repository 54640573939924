import React, { useState, useRef } from 'react';
import {
  Input,
  Heading,
  Text,
  Border,
  Button,
  Loader,
  Box,
  Select,
  Selection,
  Flex,
} from '@singita/components';
import { ENQUIRY_COPY } from '../common/constants';
import { useQuery, useMutation } from '@apollo/client';
import { client } from '../../../apollo/availability-client';
import { FORM_QUERY } from '../graphql/queries';
import { MUTATION_SUBMIT_FORM } from '../graphql/mutations';
import { validate } from '../utils/helpers';
import { PhoneNumber } from './PhoneNumber';
import { countryOptions } from '../../../common/countries';

const DATE_FORMAT = 'dddd, DD MMMM YYYY';
const DEFAULT_FORM_VALUES = { newsletter: false };

const EnquiryForm = ({ summary, form, colorScheme }) => {
  const { loading, error, data } = useQuery(FORM_QUERY, {
    variables: {
      id: 'enquiry',
    },
    client,
  });
  const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);
  const [errors, setErrors] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);
  const formRef = useRef();
  const [hideForm, setHideForm] = useState(false);
  const confirmRef = useRef();
  const [submitErrors, setSubmitErrors] = useState(false);

  const [submitForm, { loading: apiLoading }] = useMutation(
    MUTATION_SUBMIT_FORM,
    {
      client,
      onCompleted({ submitTransactionForm }) {
        window.dataLayer = window.dataLayer || [];
        if (submitTransactionForm) {
          window.dataLayer.push({
            event: 'formSubmission',
            category: 'Form',
            action: 'Success',
            label: 'availability_enquiry',
            form: submitTransactionForm,
          });
          resetForm();
          setHideForm(true);
          confirmRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      },
      onError(err) {
        window.dataLayer = window.dataLayer || [];
        if (err) {
          const errMessage =
            err && err.graphQLErrors.length
              ? err.graphQLErrors.map((e) => e.message)
              : ['Error submitting form'];
          window.dataLayer.push({
            event: 'formSubmission',
            category: 'Form',
            action: 'Fail',
            label: 'availability_trip',
            value: errMessage,
          });
          setSubmitLoader(false);
          setSubmitErrors(true);
          setHideForm(true);
          setErrors({});
          confirmRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          console.log('err', err);
        }
      },
    },
  );

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: name === 'email' ? value.toLowerCase() : value,
    });
  };

  const handleSubmit = async (e, fields, id) => {
    e.preventDefault();

    if (formValues.a_password) {
      return;
    }
    setSubmitLoader(true);

    const err = validate(fields, formValues);

    if (Object.keys(err).length) {
      setErrors(err);
      setSubmitLoader(false);
      return;
    }

    submitForm({
      variables: {
        input: {
          fields: {
            ...formValues,
          },
          form: id,
          search: form,
        },
      },
    });
  };

  const resetForm = () => {
    setSubmitLoader(false);
    setSubmitErrors(false);
    setErrors({});
    setFormValues(DEFAULT_FORM_VALUES);
    formRef.current.reset();
  };

  const getFieldByType = (field) => {
    if (field.name === 'country') {
      return (
        <Box mb={[2]}>
          <Select
            bg="bgLighter"
            label={field.label}
            name={field.name}
            options={countryOptions.map((option) => {
              return { ...option, key: option.value };
            })}
            onChange={(option) => {
              setFormValues({
                ...formValues,
                [field.name]: option.value,
              });
            }}
            value={countryOptions.find(
              (option) => option.value === formValues[field.name],
            )}
            error={errors[field.name]}
          />
        </Box>
      );
    } else
      switch (field.type) {
        case 'text':
        case 'email':
          return (
            <Box mb={[2]}>
              <Input
                name={field.name}
                label={field.label}
                type="input"
                bg="bgLighter"
                autoComplete="on"
                onChange={handleInputChange}
                value={formValues[field.name] || ''}
                error={errors[field.name]}
              />
            </Box>
          );
        case 'phone':
          return (
            <Box mb={[2]}>
              <PhoneNumber
                form={formValues}
                setForm={setFormValues}
                name={field.name}
                label={field.label}
                error={errors[field.name]}
                bg="bgLighter"
              />
            </Box>
          );
        case 'checkbox':
          return (
            <Box mb={[2]}>
              <Selection
                label={field.label}
                isSpaced={false}
                onChange={handleInputChange}
                name={field.name}
                checked={formValues[field.name] || false}
                value={formValues[field.name] || ''}
                error={errors[field.name]}
              />
            </Box>
          );
        case 'textarea':
          return (
            <Box mb={[2]}>
              <Input
                type="textarea"
                label={field.label}
                bg="bgLighter"
                name={field.name}
                value={formValues[field.name] || ''}
                onChange={handleInputChange}
                error={errors[field.name]}
              />
            </Box>
          );
        case 'select':
          return (
            <Box mb={[2]}>
              <Select
                bg="bgLighter"
                label={field.label}
                name={field.name}
                options={
                  field.options && field.options.length
                    ? field.options.map((option) => {
                        return { ...option, key: option.value };
                      })
                    : []
                }
                onChange={(option) => {
                  setFormValues({
                    ...formValues,
                    [field.name]: option.value,
                  });
                }}
                value={field.options.find(
                  (option) => option.value === formValues[field.name],
                )}
                error={errors[field.name]}
              />
            </Box>
          );
        default:
          return null;
      }
  };

  if (loading && !data) {
    return <Loader />;
  }

  if (error) {
    return <Heading size="h3">{ENQUIRY_COPY.errorFetchingForm}</Heading>;
  }

  const { fields = [], id } = data.viewForm;
  const {
    intro,
    note,
    sendButton,
    errorSubmit,
    successSubmit,
    showForm,
    attention,
  } = ENQUIRY_COPY;
  const { lodges, regions } = summary;
  const isLoading = apiLoading || submitLoader;

  return (
    <>
      <Box ref={confirmRef} hidden={!hideForm}>
        <Flex alignItems="center" justifyContent="center">
          <Heading size="h3">
            {submitErrors ? errorSubmit : successSubmit}
          </Heading>
        </Flex>
        <Flex alignItems="center" justifyContent="center">
          <Button
            onClick={() => {
              setHideForm(false);
            }}
          >
            {showForm}
          </Button>
        </Flex>
      </Box>
      <Box hidden={hideForm}>
        <Box mb={[3]}>
          <Text size="tiny" color="textLight">
            {intro}
          </Text>
        </Box>
        <Box mb={[2]}>
          <Heading size="h4" fontWeight="normal">
            Summary
          </Heading>
          <Border isContainerised={false} my={[2]} />
          <Box mb={[1]}>
            <Text size="tiny" fontWeight="bold">
              Regions:
            </Text>
            <br />
            <Text data-testid="summary-regions" size="small" color="textLight">
              {regions.length > 0 ? regions.join(', ') : 'All Regions'}
            </Text>
          </Box>
          <Box mb={[1]}>
            <Text size="tiny" fontWeight="bold">
              Lodges:
            </Text>
            <br />
            <Text data-testid="summary-lodges" size="small" color="textLight">
              {lodges.length > 0 ? lodges.join(', ') : 'All Lodges'}
            </Text>
          </Box>
          <Box mb={[1]}>
            <Text size="tiny" fontWeight="bold">
              Dates:
            </Text>
            <br />
            <Text size="small" color="textLight">
              {form.startDate.format(DATE_FORMAT)} -{' '}
              {form.endDate.format(DATE_FORMAT)}
            </Text>
          </Box>
          <Box mb={[1]}>
            <Text size="tiny" fontWeight="bold" mb={[2]}>
              Guests:
            </Text>

            <Flex flexDirection={['column', 'row', 'row', 'row']}>
              {['adults', 'children', 'toddler', 'baby']
                .filter((guest) => form.guests[guest] > 0)
                .map((key) => {
                  return (
                    <Flex flexDirection="column" sx={{ pb: [1], mr: [4] }}>
                      <Text size="small" fontWeight="bold" color="textLight">
                        {form.guests[key] === 1
                          ? ENQUIRY_COPY.guestLabels[key]
                          : `${ENQUIRY_COPY.guestLabelsPlural[key]}`}{' '}
                        :{' '}
                        <Text size="small" color="textLight">
                          {form.guests[key]}
                        </Text>
                      </Text>
                    </Flex>
                  );
                })}
            </Flex>
          </Box>
        </Box>
        <Box
          as="form"
          mb={[4]}
          onSubmit={(e) => handleSubmit(e, fields, id)}
          ref={formRef}
        >
          {fields.map((field) => getFieldByType(field))}
          <input
            data-testid="a_password"
            name="a_password"
            type="text"
            onChange={handleInputChange}
            tabIndex="-1"
            autoComplete="off"
            style={{ display: 'none' }}
          />
          {isLoading ? (
            <Flex alignItems="left" justifyContent="left">
              <Loader isActive={isLoading} size={100} />
            </Flex>
          ) : (
            <Button
              variant="primary"
              colorScheme={colorScheme ?? 'brandBrown'}
              mb={[2]}
            >
              {sendButton}
            </Button>
          )}
          {Object.keys(errors).length ? (
            <Box mb={[3]}>
              <Text color="statusError" size="small">
                {attention}
              </Text>
            </Box>
          ) : null}
        </Box>
        {note && (
          <Box mb={[3]}>
            <Text size="tiny" fontWeight="bold">
              {note}
            </Text>
          </Box>
        )}
      </Box>
    </>
  );
};

export default EnquiryForm;
