import React, { useEffect, useRef, useState } from 'react';
import Link from '../components/ConditionalLink';
import { css } from '@emotion/react';
import {
  InstantSearch,
  Index,
  Configure,
  connectSearchBox,
  connectHits,
  connectStateResults,
  connectPagination,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { generateBlogSlug, mapperWithFunction } from '../common/utils';
import useLockScroll from '../hooks/useLockScroll';
import {
  Box,
  Flex,
  Image,
  Input,
  Card,
  Container,
  Text,
  Heading,
  Button,
  theme,
  Icon,
} from '@singita/components';
import moment from 'moment';
import VideoWrapper from './VideoWrapper';
import { getIdFromVimeoURL, getVimeoMeta } from '../common/utils';

const TYPES = {
  PAGE: 'page',
  LODGE: 'lodge',
  PROMOTION: 'promotion',
  BLOG: 'blog',
  WILDLIFE: 'wildlife',
  PRESS: 'press',
  PROJECTS: 'projects',
  DOCUMENTS: 'documents',
  MEDIA: 'media',
};

const INDICES = {
  [TYPES.PAGE]: 'pages',
  [TYPES.LODGE]: 'lodges',
  [TYPES.PROMOTION]: 'promotions',
  [TYPES.BLOG]: 'articles_date_desc',
  [TYPES.WILDLIFE]: 'articles_date_desc',
  [TYPES.PRESS]: 'articles_date_desc',
  [TYPES.PROJECTS]: 'projects',
  [TYPES.DOCUMENTS]: 'documents',
  [TYPES.MEDIA]: 'library_library_posts',
};

const TYPE_HEADING = {
  [TYPES.PAGE]: 'Pages',
  [TYPES.LODGE]: 'Lodges',
  [TYPES.PROMOTION]: 'Promotions',
  [TYPES.BLOG]: 'Blog',
  [TYPES.WILDLIFE]: 'Wildlife Reports',
  [TYPES.PRESS]: 'Press Releases',
  [TYPES.PROJECTS]: 'Conservation Projects',
  [TYPES.DOCUMENTS]: 'Documents',
  [TYPES.MEDIA]: 'Media',
};

const FILTERS = {
  [TYPES.BLOG]: "articleType:'Blog Post' OR articleType:'Bush Stories'",
  [TYPES.WILDLIFE]: "articleType:'Wildlife Report'",
  [TYPES.PRESS]: "articleType:'Press Release'",
  [TYPES.MEDIA]: 'post_type:videos',
};

const HITS_PER_PAGE = {
  [TYPES.DOCUMENTS]: 10,
};

const IMAGE_STYLES = {
  height: ['100%'],
  width: ['100%'],
  objectFit: 'cover',
};

const ARTICLE_MAP = {
  headline: ['headline'],
  renderImage: (value) =>
    value.image ? () => <Image src={value.image} sx={IMAGE_STYLES} /> : null,
  to: ({ date, permalink, archive = false }) => {
    if (archive && permalink) {
      return permalink;
    }

    const dateObj = new moment(date);
    return generateBlogSlug(
      permalink,
      dateObj.format('YYYY'),
      dateObj.format('MM'),
    );
  },
  meta: ({ date, category: categories }) => {
    const dateObj = new moment(date);
    return [
      { text: `${dateObj.format('MMMM DD, YYYY')}`, color: 'textDark' },
      ...(categories
        ? categories.map((category) => ({
            text: category,
            color: 'brandBrown',
          }))
        : []),
    ];
  },
};

const TYPE_MAPS = {
  [TYPES.PAGE]: {
    headline: ['headline'],
    description: ['description'],
    renderImage: (value) =>
      value.image?.url
        ? () => <Image src={value.image.url} sx={IMAGE_STYLES} />
        : null,
    altText: ['image', 'altText'],
    to: (value) => `/${value.slug}`,
  },
  [TYPES.LODGE]: {
    headline: ['headline'],
    description: ['description'],
    renderImage: (value) =>
      value.image ? () => <Image src={value.image} sx={IMAGE_STYLES} /> : null,
    to: (value) => `/lodge/${value.slug}`,
    meta: (value) =>
      value.region
        ? [
            { text: value.region.name, color: 'textDark' },
            { text: value.region.country, color: 'brandBrown' },
          ]
        : [],
  },
  [TYPES.PROMOTION]: {
    headline: ['headline'],
    renderImage: (value) =>
      value.image ? () => <Image src={value.image} sx={IMAGE_STYLES} /> : null,
    to: (value) => `/promotion/${value.slug}`,
    meta: (value) =>
      value.region
        ? [{ text: value.region[0].country, color: 'brandBrown' }]
        : [],
  },
  [TYPES.BLOG]: ARTICLE_MAP,
  [TYPES.WILDLIFE]: ARTICLE_MAP,
  [TYPES.PRESS]: ARTICLE_MAP,
  [TYPES.PROJECTS]: {
    headline: ['name'],
    renderImage: ({ image }) =>
      image
        ? () => <Image src={image.url} sx={IMAGE_STYLES} alt={image.altText} />
        : null,
    to: ({ slug }) => `/conservation/projects/${slug}`,
    meta: ({ area, regions }) => [
      ...(area ? [{ text: area, color: 'textDark' }] : []),
      ...(regions
        ? regions.map((r) => ({ text: r, color: 'brandBrown' }))
        : []),
    ],
  },
  [TYPES.DOCUMENTS]: {
    headline: (value) => (
      <Flex alignItems="center">
        <Icon name="pdf" size={18} />
        <Box ml={[2]}>{value.title}</Box>
      </Flex>
    ),
    isExternal: () => true,
    linkProps: (value) => ({
      href: value.url,
    }),
    wrapperProps: () => ({
      pt: [0],
      pb: [0],
    }),
  },
};

const Pagination = connectPagination(
  ({ currentRefinement, refine, nbPages }) => {
    return nbPages > 0 ? (
      <Flex justifyContent="flex-end" py={[1]}>
        {currentRefinement !== 1 && (
          <Box marginRight={[2]}>
            <Button
              variant="text"
              icon="chevron-left"
              iconPosition="left"
              onClick={() => refine(currentRefinement - 1)}
            >
              Less
            </Button>
          </Box>
        )}
        {currentRefinement !== nbPages && (
          <Box>
            <Button
              variant="text"
              icon="chevron-right"
              iconPosition="right"
              onClick={() => refine(currentRefinement + 1)}
            >
              More
            </Button>
          </Box>
        )}
      </Flex>
    ) : null;
  },
);

const ConnectedSearchBox = connectSearchBox(
  ({ refine, onChange, onClear, searchInputRef, searchTerm }) => (
    <Input
      ref={searchInputRef}
      placeholder="Search..."
      value={searchTerm}
      onChange={(e) => onChange(e, refine)}
      variant="outline"
      size="large"
      color="textWhite"
      icon={{
        name: searchTerm ? 'close' : 'search',
        size: 24,
        fontSize: ['16px', '24px'],
        color: 'textWhite',
        onClick: (e) => onClear(refine),
        sx: {
          cursor: searchTerm ? 'pointer' : 'default',
          color: theme.colors.textWhite,
        },
      }}
    />
  ),
);

const ConnectedStateResults = connectStateResults(
  ({ searchResults, searchTerm }) => {
    return searchTerm && searchResults ? (
      <Box>
        <Text color="textDark" size="small">
          {searchResults.nbHits} results found for "{searchTerm}"
        </Text>
      </Box>
    ) : null;
  },
);

const HitCard = (props) => {
  if (props.type === TYPES.MEDIA) {
    return <VideoCard {...props} />;
  }

  const {
    to,
    isExternal = false,
    linkProps = {},
    wrapperProps = {},
    ...cardProps
  } = mapperWithFunction(props, TYPE_MAPS[props.type]);
  const LinkWrapper = isExternal ? 'a' : Link;

  return (
    <LinkWrapper
      to={to}
      {...(isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      {...linkProps}
    >
      <Box
        pb={[0, 0, 0, 3]}
        pt={[3, 3, 3, 3]}
        sx={{ borderBottom: '2px solid #EBEBEB' }}
        {...wrapperProps}
      >
        <Card
          variant="strip"
          imagePosition="right"
          size="large"
          bg={props.bg}
          imageSx={{
            height: ['150px', '150px', '150px', '200px'],
            width: ['100%', '100%', '250px', '300px'],
            padding: [0, 0, 0, 2],
          }}
          {...cardProps}
        />
      </Box>
    </LinkWrapper>
  );
};

const VideoCard = (props) => {
  const { video } = props;
  const [meta, setMeta] = useState(null);
  const cardProps = {
    headline: props.post_title,
    renderImage:
      meta && meta.thumbnailUrl
        ? () => (
            <Box sx={{ position: 'relative', height: '100%' }}>
              <VideoWrapper fullUrl={video} thumbnail={meta.thumbnailUrl} />
            </Box>
          )
        : null,
    meta: [{ text: 'Video', color: 'brandBrown' }],
    description: meta ? meta.description : null,
  };

  React.useEffect(() => {
    const fetchMeta = async () => {
      const meta = await getVimeoMeta(getIdFromVimeoURL(video));
      setMeta(meta);
    };
    if (!meta) {
      fetchMeta();
    }
  }, [meta, video]);

  return (
    <Box
      pb={[0, 0, 0, 3]}
      pt={[3, 3, 3, 3]}
      sx={{ borderBottom: '2px solid #EBEBEB' }}
    >
      <Card
        variant="strip"
        imagePosition="right"
        size="large"
        bg={props.bg}
        imageSx={{
          height: ['150px', '150px', '150px', '200px'],
          width: ['100%', '100%', '250px', '300px'],
          padding: [0, 0, 0, 2],
        }}
        {...cardProps}
      />
    </Box>
  );
};

const ConnectedHits = connectHits(({ hits, type, bg, searchTerm }) => {
  return hits.length > 0 ? (
    <Box py={[6]} px={[4, 4, 6]} bg={bg}>
      <Heading size="h3" color="textDark" fontWeight="bold">
        {TYPE_HEADING[type]}
      </Heading>
      <ConnectedStateResults searchTerm={searchTerm} />
      {hits.map((hit) => (
        <HitCard key={hit.objectID} type={type} bg={bg} {...hit} />
      ))}
      <Pagination />
    </Box>
  ) : null;
});

const FilterItem = ({ active, onClick, children, isLast }) => (
  <Flex
    alignItems="center"
    sx={{
      cursor: 'pointer',
    }}
    onClick={onClick}
    mb={[2, 2, 2, 0]}
  >
    <Heading
      size="h2"
      color="textWhite"
      sx={{
        opacity: active ? 'full' : 'feint',
        transition: `opacity ${theme.speed.default} ease`,
      }}
    >
      {children}
    </Heading>
    {!isLast && (
      <Box mx={[3]}>
        <Icon name="dot" size={6} sx={{ color: theme.colors.textWhite }} />
      </Box>
    )}
  </Flex>
);

const TypeFilter = ({ typeFilter, setTypeFilter }) => {
  const allTypes = Object.values(TYPES);

  return (
    <Container>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        mb={[6]}
      >
        {typeFilter && (
          <FilterItem
            active={false}
            onClick={() => setTypeFilter(null)}
            isLast={false}
          >
            All Results
          </FilterItem>
        )}
        {allTypes.map((type, idx) => {
          return (
            <FilterItem
              active={!typeFilter || typeFilter === type}
              onClick={() => setTypeFilter(type)}
              isLast={idx === allTypes.length - 1}
            >
              {TYPE_HEADING[type]}
            </FilterItem>
          );
        })}
      </Flex>
    </Container>
  );
};

const CustomIndex = ({
  index,
  type,
  bg,
  searchTerm,
  filters,
  hitsPerPage = 5,
}) => (
  <Index indexName={index} indexId={type}>
    <Configure hitsPerPage={hitsPerPage} query={searchTerm} filters={filters} />
    <ConnectedHits type={type} bg={bg} searchTerm={searchTerm} />
  </Index>
);

const SearchWrapper = ({ isOpen }) => {
  const searchInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [typeFilter, setTypeFilter] = useState(null);
  useLockScroll(isOpen);

  useEffect(() => {
    if (isOpen) {
      if (searchInputRef && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }

    if (!isOpen) {
      setSearchTerm('');
      setTypeFilter(null);
    }
  }, [isOpen]);

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_API_KEY,
  );

  const filterString = FILTERS[typeFilter];

  return (
    <Box
      bg="brandBrown"
      pt={[10, 10, 10, 25]}
      pb={[0, 0, 10, 25]}
      px={[0, 0, 0, 10]}
      sx={css`
        min-height: 100%;
        width: 100%;
      `}
    >
      <InstantSearch
        searchClient={searchClient}
        indexName={typeFilter ? INDICES[typeFilter] : 'pages'}
      >
        <Box>
          <Box mb={[5, 5, 5, 10]} px={[4, 4, 8, 8]}>
            <ConnectedSearchBox
              searchTerm={searchTerm}
              searchInputRef={searchInputRef}
              onChange={(e, refine) => {
                refine(e.target.value);
                setSearchTerm(e.target.value);
              }}
              onClear={
                searchTerm
                  ? (refine) => {
                      refine('');
                      setSearchTerm('');
                    }
                  : () => {}
              }
            />
          </Box>
          <Box textAlign="center" mb={[2]}>
            <Text size="body" color="textWhite">
              Refine your search
            </Text>
          </Box>
          <TypeFilter typeFilter={typeFilter} setTypeFilter={setTypeFilter} />
          <Box pb={[0, 0, 6]} px={[0, 0, 4, 4]}>
            {typeFilter ? (
              <CustomIndex
                key={`index-single-${typeFilter}`}
                index={INDICES[typeFilter]}
                type={typeFilter}
                bg="bgLight"
                searchTerm={searchTerm}
                filters={filterString}
                hitsPerPage={HITS_PER_PAGE[typeFilter]}
              />
            ) : (
              Object.values(TYPES).map((type, idx) => {
                const index = INDICES[type];
                const bg = idx % 2 === 0 ? 'bgLight' : 'bgLighter';
                return (
                  <CustomIndex
                    key={`index-${type}`}
                    index={index}
                    type={type}
                    bg={bg}
                    searchTerm={searchTerm}
                    filters={FILTERS[type]}
                    hitsPerPage={HITS_PER_PAGE[type]}
                  />
                );
              })
            )}
          </Box>
        </Box>
      </InstantSearch>
    </Box>
  );
};

export default SearchWrapper;
