import React, { useContext } from "react"
import { graphql } from "gatsby"
import { theme, Banner } from "@singita/components"
import { mapperWithFunction } from "../common/utils"
import RichTextRenderer from "../components/RichTextRenderer"
import Link from "../components/Link"

import AnimateInView from "../components/AnimateInView"
import { useSetNavbarStylesOnPresence } from "gatsby-singita-theme/src/hooks"
import { LodgeContext } from "gatsby-singita-theme/src/context/LodgeContext"

const BANNER_MAP = {
  showLogo: ["showLogo"],
  showPattern: ["showPattern"],
  headline: ["content", "headline"],
  content: ["content", "content"],
  cite: ["content", "subtitle"],
  image: ["content", "image", "fluid", "src"],
  links: ["content", "links"],
  sx: ["sx"],
}

const BannerWrapper = props => {
  const { changeInView = true, patternProps } = props
  const { content, sx, ...bannerProps } = mapperWithFunction(props, BANNER_MAP)
  const {
    lodgeData: { lodgeColor },
  } = useContext(LodgeContext)
  const bannerRef = useSetNavbarStylesOnPresence(
    0,
    "0px 0px -100% 0px",
    {
      logoColor: theme.colors.textWhite,
      secondaryButtonColor: theme.colors.textWhite,
    },
    changeInView
  )

  return (
    <AnimateInView threshold={0.4}>
      <Banner
        ref={bannerRef}
        {...bannerProps}
        patternProps={{
          ...patternProps,
          color: theme.colors.baseWhite,
        }}
        sx={{
          backgroundColor: lodgeColor ?? theme.colors.brandBrown,
          ...sx,
        }}
        renderCta={() =>
          bannerProps.links &&
          bannerProps.links.map(link => (
            <Link
              {...link}
              colorScheme={"white"}
              size={"default"}
              icon="chevron-right"
              iconPosition="right"
              sx={{ mx: [0, 0, 0, 2], mb: [1, 1, 1, 0] }}
            />
          ))
        }
        citeSx={{ color: "white !important" }}
      >
        {content && (
          // TODO: Perhaps the Banner Color Scheme should be set in the CMS UI? for the link and text colors and the background color?
          <RichTextRenderer color={theme.colors.textWhite} content={content} />
        )}
      </Banner>
    </AnimateInView>
  )
}

export default BannerWrapper

export const BannerFields = graphql`
  fragment BannerFields on ContentfulLayoutBanner {
    contentful_id
    showLogo
    showPattern
    content {
      headline
      subtitle
      content {
        raw
      }
      links {
        ...LinkFields
        ... on ContentfulPages {
          label: name
          slug
        }
      }
      image {
        fluid(quality: 100, maxWidth: 240) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`
