import React from "react"
import { LODGE_QUERY } from "../graphql/queries"
import { client } from "../../../apollo/availability-client"
import { useQuery } from "@apollo/client"
import { Select } from "@singita/components"

export const Lodges = ({
  label,
  placeholder,
  name,
  error,
  form,
  setForm,
  disabled,
}) => {
  const { loading, data } = useQuery(LODGE_QUERY, {
    client,
  })

  if (!loading && data) {
    const { findLodges = [] } = data
    const lodgeOptions = findLodges.map(lodge => {
      return {
        label: lodge.name,
        value: lodge.name,
        key: lodge.name,
      }
    })

    const value = form[name]
      ? form[name].reduce((result, item) => {
          const getOption = lodgeOptions.find(o => o.value === item)
          if (getOption) {
            result.push({ ...getOption, key: getOption.value })
          }
          return result
        }, [])
      : []

    return (
      <Select
        label={label}
        name={name}
        options={lodgeOptions}
        onChange={option => {
          if (option && option.length) {
            const optionArray = option.map(o => o.value)
            setForm({ ...form, [name]: optionArray })
          } else {
            setForm({ ...form, [name]: [] })
          }
        }}
        placeholder={placeholder || "Select an option"}
        bg="white"
        noOptionsMessage="No further options"
        value={value}
        error={error}
        isMulti={true}
        disabled={disabled}
      />
    )
  }

  return null
}
