import React, { useReducer, useState, useCallback, useMemo } from "react"

import {
  theme,
  Box,
  Flex,
  FilterItem,
  Heading,
  Modal,
  Gallery,
  SimpleCarousel,
  Loader,
} from "@singita/components"
import GalleryThumbnailNav from "./GalleryThumbnailNav"
import ListGallery from "./ListGallery"
import { CONTENT_TYPES } from "../common/constants"
import MediaWrapper from "../modules/MediaWrapper"
import { formatImages } from "../common/format"
import { useLockScroll } from "gatsby-singita-theme/src/hooks"

const filters = {
  all: "All",
  photos: "Photos",
  videos: "Videos",
}

const mediaType = {
  image: "image",
  video: "video",
}

const GalleryFilter = ({ activeFilter, filterGalleries }) => {
  return (
    <Flex>
      {Object.values(filters).map(filter => {
        return (
          <FilterItem
            key={filter}
            active={activeFilter === filter}
            onClick={() => filterGalleries(filter)}
          >
            {filter}
          </FilterItem>
        )
      })}
    </Flex>
  )
}

const galleryReducer = (state, action) => {
  switch (action.filter) {
    case filters.all:
      return {
        filter: filters.all,
        filteredGalleries: [...action.allGalleries],
      }
    case filters.photos:
      return {
        filter: filters.photos,
        filteredGalleries: action.allGalleries.map(gallery => {
          return {
            ...gallery,
            media:
              gallery?.media?.filter(item => item.type === mediaType.image) ||
              [],
          }
        }),
      }
    case filters.videos:
      return {
        filter: filters.videos,
        filteredGalleries: action.allGalleries.map(gallery => {
          return {
            ...gallery,
            media:
              gallery?.media?.filter(item => item.type === mediaType.video) ||
              [],
            /* Assets can only be images, so ignore any that exist */
            assets: [],
          }
        }),
      }
    default:
      return state
  }
}

const FullPageGallery = ({ title, galleries, isModal, filterOffset }) => {
  const [currentGallery, setCurrentGallery] = useState(0)
  const [modalProps, setModalProps] = useState({
    isOpen: false,
    gallery: null,
  })
  useLockScroll(modalProps.isOpen)

  // Filter out any items that aren't Content > Assets entries, they
  // don't belong here.
  const allGalleries = galleries.filter(
    gallery => gallery.__typename === CONTENT_TYPES.CONTENT_ASSETS
  )
  const [{ filter, filteredGalleries }, dispatch] = useReducer(galleryReducer, {
    filter: filters.all,
    filteredGalleries: galleries,
  })
  const filterGalleries = filter => dispatch({ filter, allGalleries })

  const onSectionNavigate = id => {
    const elem = document.getElementById(`gallery-${id}`)
    if (elem) {
      const y = elem.offsetTop - 150
      const scrollContainer = isModal
        ? document.getElementById("modal-scrollable-content")
        : window
      if (!Boolean(scrollContainer.scrollTo)) {
        scrollContainer.scrollTop = y
      } else {
        scrollContainer.scrollTo({ behavior: "smooth", top: y })
      }
    }
  }

  const numberValidSections = filteredGalleries.reduce((acc, gallery) => {
    if (
      (gallery.media && gallery.media.length > 0) ||
      (gallery.assets && gallery.assets.length > 0)
    ) {
      acc += 1
    }
    return acc
  }, 0)

  const setGallery = useCallback(id => setCurrentGallery(id), [
    setCurrentGallery,
  ])
  const openModal = (gallery, asset) => {
    setModalProps({ isOpen: true, gallery, asset })
  }
  const closeModal = () => setModalProps({ ...modalProps, isOpen: false })
  const modalGallery = useMemo(() => {
    return galleries.find(g => g.id === modalProps.gallery)
  }, [modalProps.gallery])

  return (
    <Box bg={theme.colors.brandBeige} pt={isModal ? [2, 6] : [12, 15]}>
      <GalleryModal
        isOpen={modalProps.isOpen}
        gallery={modalGallery}
        handleClose={closeModal}
        title={title}
        assetIndex={modalProps.asset}
      />
      <Flex
        flexDirection={["column", "column", "row"]}
        mb={[6]}
        justifyContent="space-between"
        alignItems={["flex-start", "flex-start", "flex-end"]}
      >
        <Box mb={[1, 1, 0]}>
          <Heading as="h1" size="h1" fontWeight="normal">
            {title}
          </Heading>
        </Box>
        <GalleryFilter
          activeFilter={filter}
          filterGalleries={filterGalleries}
        />
      </Flex>

      {numberValidSections > 0 ? (
        <>
          <GalleryThumbnailNav
            galleries={galleries}
            filteredGalleries={filteredGalleries}
            navigate={onSectionNavigate}
            currentGallery={currentGallery}
            filterOffset={filterOffset}
          />

          <ListGallery
            galleries={filteredGalleries}
            setGallery={setGallery}
            viewGallery={openModal}
          />
        </>
      ) : (
        <Box pb={[3]}>
          <Heading size="h4">No media available</Heading>
        </Box>
      )}
    </Box>
  )
}

const GalleryModal = ({ isOpen, title, handleClose, gallery, assetIndex }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [g] = React.useMemo(
    () =>
      gallery
        ? formatImages([gallery], { maxWidth: 800, quality: 90 }, false)
        : [null],
    [gallery]
  )
  const stopLoading = () => setIsLoading(false)
  const startLoading = () => setIsLoading(true)

  const initialIndex = useMemo(() =>
    g ? g.assets.findIndex(({ id }) => id === assetIndex) : 0
  )

  return (
    <Modal
      isOpen={isOpen}
      size="large"
      title={`${title}${g && g.headline ? ` - ${g.headline}` : ""}`}
      handleClose={handleClose}
      bg="bgLight"
    >
      <Box pt={[5]} sx={{ position: "relative" }}>
        {isLoading ? (
          <Box sx={{ position: "absolute", height: "100%", width: "100%" }}>
            <Loader isActive={isLoading} color="brandBrown" hasMinHeight />
          </Box>
        ) : null}
        {isOpen && g ? (
          <SimpleCarousel
            initialIndex={initialIndex}
            hasPagination
            items={g.assets}
          >
            {g.assets.map((asset, index) => (
              <MediaWrapper
                {...asset}
                role="presentation"
                style={{ width: "100%", height: "100%" }}
                imgStyle={{
                  objectFit: "cover",
                  userDrag: "none",
                  "-webkit-user-drag": "none",
                }}
                loading="eager"
                onLoad={asset.id === assetIndex ? stopLoading : null}
                onStartLoad={asset.id === assetIndex ? startLoading : null}
              />
            ))}
          </SimpleCarousel>
        ) : null}
      </Box>
    </Modal>
  )
}

export default FullPageGallery
