import React, { useContext } from "react"
import { VideoModal } from "@singita/components"
import { VideoContext } from "gatsby-singita-theme/src/context/VideoContext"

const VideoModalWrapper = () => {
  const { isOpen, videoUrl, setIsOpen } = useContext(VideoContext)

  return (
    <VideoModal
      isOpen={isOpen}
      videoUrl={videoUrl}
      toggleIsOpen={() => setIsOpen(!isOpen)}
    />
  )
}

export default VideoModalWrapper
