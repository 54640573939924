import { ApolloClient, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';

export const client = new ApolloClient({
  uri: process.env.GATSBY_SINGITA_API
    ? `${process.env.GATSBY_SINGITA_API}/graphql`
    : `http://localhost:4000/graphql`,
  fetch,
  cache: new InMemoryCache(),
});
