import { AvailabilityWizard } from "gatsby-singita-theme/src/components/Availability"
import Navbar from "gatsby-singita-theme/src/components/Navbar"
import FooterWrapper from "gatsby-singita-theme/src/modules/FooterWrapper"
import React from "react"
import { Box } from "@singita/components"
import ContentModal from "../../components/ContentModal"
import GlobalAlerts from "../../components/GlobalAlerts"
import NewsletterWrapper from "../../components/Newsletter"
import VideoModalWrapper from "../../modules/VideoModalWrapper"
import { Media } from "gatsby-singita-theme/src/Media"
import PopupPane from "../../components/PopupPane"
import NotificationPane from "../../components/NotificationPane"
import BannerNotifications from "../../components/BannerNotifications"
import GeneralModal from "../../components/GeneralModal"
import { ModalProvider } from "gatsby-singita-theme/src/context/ModalContext"
import { AlertsProvider } from "gatsby-singita-theme/src/context/AlertsContext"

const isIE11 =
  typeof window !== "undefined" &&
  !!window.MSInputMethodContext &&
  !!document.documentMode

const Main = ({ children, bg = "bgLighter", location, codes, pageContext, hideNewsletter }) => {
  const isForeignLocale =
    pageContext && pageContext.locale && pageContext.locale !== "en-US"

  return (
    <ModalProvider>
      <AlertsProvider>
        <Media greaterThanOrEqual="md">
          <Box sx={{ position: "relative", zIndex: 9999 }}>
            <PopupPane />
          </Box>
        </Media>
        <Media lessThan="md">
          <NotificationPane />
        </Media>
        <Navbar
          currentPath={location ? location.pathname : null}
          showSearchButton={!isForeignLocale}
          showPlanYourTripProp={!isForeignLocale}
        />
        <AvailabilityWizard isModal={true} codes={codes} />
        <VideoModalWrapper />
        <Box as={isIE11 ? "section" : "main"} role="main" bg={bg}>
          {children}
        </Box>
        <BannerNotifications />
        {!hideNewsletter && (
          <NewsletterWrapper />
        )}
        <FooterWrapper
          menuHeadlineSx={{ fontWeight: "bold" }}
          showMenu={!isForeignLocale}
        />
        <GlobalAlerts />
        <ContentModal />
        <GeneralModal />
      </AlertsProvider>
    </ModalProvider>
  )
}

export default Main
