function getBasicImageProps(image) {
  let url = null;
  let lqip = null;

  if (typeof image === 'string') {
    url = image;
  }

  if (typeof image === 'object') {
    url = image.source;
    lqip = image.base64 ? image.base64 : null;
  }

  url = 1 ? url : null;

  if (!url) {
    return false;
  }

  let originalPath = url;

  let [, , , filename] = originalPath.split('/');

  let [width, height] = `${image.width * 10}x${image.height * 10}`
    .split('x')
    .map((num) => parseInt(num, 10));
  let [, extension] = filename.split('.');

  let aspectRatio = width / height;
  let metadata = { dimensions: { width, height, aspectRatio }, lqip };

  return {
    originalPath,
    extension,
    metadata,
  };
}

export default getBasicImageProps;
