import React, { useContext } from "react"
import {
  NotificationPane as Notifications,
  useCycle,
} from "@singita/components"
import { AlertsContext } from "gatsby-singita-theme/src/context/AlertsContext"
import AlertWrapper from "../modules/AlertWrapper"
import { useLockScroll } from "gatsby-singita-theme/src/hooks"
import { ALERT_STATE_KEY_PREFIX, isBrowser } from "../common/constants"

const NotificationPane = props => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const { alerts } = useContext(AlertsContext)

  const filteredAlerts = alerts.filter(alert => {
    const STATE_KEY = ALERT_STATE_KEY_PREFIX.concat(alert.id)

    let storageValue = null
    if (isBrowser) {
      try {
        storageValue = JSON.parse(window.localStorage.getItem(STATE_KEY))
      } catch (e) {
        console.error(`Failed to get key ${STATE_KEY} from local storage`, e)
      }
    }

    if (storageValue === null) {
      return true
    }

    return storageValue
  })

  useLockScroll(isOpen && filteredAlerts.length > 0)

  return (
    <Notifications isOpen={isOpen} toggleOpen={toggleOpen}>
      {filteredAlerts.map(alert => (
        <AlertWrapper key={alert.id} {...alert} />
      ))}
    </Notifications>
  )
}

export default NotificationPane
