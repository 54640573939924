import queryString from 'query-string';
import { getUpdatedField } from '../utils/helpers';
import { ACTIONS, STEPS } from './constants';
import moment from 'moment';
import { isEqual } from 'lodash';
import Lodges from '../components/Lodges';

const DATE_FORMAT = 'YYYY-MM-DD';

export const initialValue = {
  currentStep: STEPS.REGIONS,
  regionsList: [],
  form: {
    additionalLodges: [],
    regions: [],
    lodges: [],
    startDate: moment.utc().add(10, 'days'),
    endDate: moment.utc().add(15, 'days'),
    guests: {
      adults: 2,
      children: 0,
      toddler: 0,
      baby: 0,
    },
  },
  errors: {},
  summary: {
    regions: [],
    lodges: [],
  },
};

export const availabilityReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.STEP: {
      const { step } = action.payload;

      if (state.currentStep === STEPS.DATES) {
        if (!state.form.startDate || !state.form.endDate) {
          const errors = {
            startDate: !state.form.startDate,
            endDate: !state.form.endDate,
          };
          return { ...state, errors: { ...state.errors, ...errors } };
        }
      }

      if (step === STEPS.CHECK_AVAILABILITY) {
        const {
          form: {
            lodges,
            regions,
            guests: { adults, children, toddler, baby },
            startDate,
            endDate,
          },
          summary,
        } = state;

        const values = queryString.stringify(
          {
            lodges,
            regions,
            adults,
            children,
            toddler,
            baby,
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT),
          },
          { arrayFormat: 'bracket' },
        );

        //NOTE:Removed because of event firing when clicking the button instead of submitting the form
        // window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push({
        //   event: 'formSubmission',
        //   category: 'Form',
        //   action: 'Success',
        //   label: action.payload.dataLabel,
        //   lodgeId: summary.lodges,
        // });

        window.location.href = `https://availability.singita.com/search?${values}`;
        return state;
      }

      return { ...state, currentStep: step, errors: {} };
    }
    case ACTIONS.ADD_REGION: {
      const { region } = action.payload;

      if (state.form.regions.includes(region.code)) {
        return state;
      }

      return {
        ...state,
        form: { ...state.form, regions: [...state.form.regions, region.code] },
        summary: {
          ...state.summary,
          regions: [...state.summary.regions, region.name],
        },
      };
    }
    case ACTIONS.SET_FORM: {
      const { regions, lodges } = action.payload;

      let _startDate;
      let _endDate;
      if (state.form.lodges && isEqual(state.form.lodges, ['TZG-SM'])) {
        if (
          state.form.startDate &&
          state.form.startDate.isBefore(moment('2024-05-16'))
        ) {
          _startDate = moment('2024-05-16');
          _endDate = _startDate.clone().add(7, 'days');

          return {
            ...state,
            form: {
              ...state.form,
              regions: regions,
              lodges: lodges,
              startDate: _startDate,
              endDate: _endDate,
            },
          };
        }
      }

      return {
        ...state,
        form: {
          ...state.form,
          regions: regions,
          lodges: lodges,
        },
      };
    }
    case ACTIONS.UPDATE_REGIONS:
      const { region } = action.payload;
      return getUpdatedField('regions', region, state);
    case ACTIONS.UPDATE_LODGES: {
      const { lodge } = action.payload;
      const newState = getUpdatedField('lodges', lodge, state);

      let _startDate;
      let _endDate;
      if (newState.form.lodges && isEqual(newState.form.lodges, ['TZG-SM'])) {
        if (
          newState.form.startDate &&
          newState.form.startDate.isBefore(moment('2024-05-16'))
        ) {
          _startDate = moment('2024-05-16');
          _endDate = _startDate.clone().add(7, 'days');

          return {
            ...newState,
            form: {
              ...newState.form,
              startDate: _startDate,
              endDate: _endDate,
            },
          };
        }
      }

      return { ...newState };
    }

    case ACTIONS.UPDATE_DATES:
      const {
        dates: { startDate, endDate },
      } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          startDate: startDate,
          endDate: endDate,
        },
        errors: {},
      };
    case ACTIONS.UPDATE_GUESTS:
      const { step, key } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          guests: {
            ...state.form.guests,
            [key]: state.form.guests[key] + step,
          },
        },
        errors: {},
      };
    case ACTIONS.RESET:
      return action.payload;
    default:
      return state;
  }
};
