import React, { useState } from "react"
import { graphql } from "gatsby"
import { Filter, FilterItem } from "@singita/components"
import RichTextRenderer from "../components/RichTextRenderer"
import { Box } from "@singita/components"

const TabsWrapper = ({ children, colorScheme }) => {
  const [active, setActive] = useState(0)
  const { content } = children[active]

  return (
    <Box>
      <Box mb={[2]}>
        {children.length > 1 && (
          <Filter isVertical={false}>
            {children.map(({ headline }, idx) => (
              <FilterItem
                isVertical={false}
                active={active === idx}
                onClick={() => setActive(idx)}
                activeColor={colorScheme}
              >
                {headline}
              </FilterItem>
            ))}
          </Filter>
        )}
      </Box>
      <RichTextRenderer content={content} />
    </Box>
  )
}

export default TabsWrapper

export const TabsFields = graphql`
  fragment TabsFields on ContentfulLayoutTabs {
    contentful_id
    children: contentfulchildren {
      ... on ContentfulContent {
        headline
        content {
          raw
          references {
            __typename
            ... on ContentfulEmbed {
              contentful_id
              ...EmbedFields
            }
          }
        }
      }
    }
  }
`
